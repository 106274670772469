import React from "react";

import { Link } from "react-router-dom";

import { format } from "date-fns";
import LineProgress from "../LineProgress";
import { Emoji, EmojiStyle } from "emoji-picker-react";

import "./project-card.scss";
import { useDispatch, useSelector } from "react-redux";
import { setActiveProject } from "../../../store/reducers/projectSlice";
import AnalyticsHelper from "../../../application/common/AnalyticsHelper";

const ProjectCard = (props) => {
  const { project } = props;
  const tasks = useSelector((state) => state.project.tasks);

  const dispatch = useDispatch();

  const calculateProgress = (project) => {
    return tasks?.filter(
      (item) => item.status === "completed" && item.project_id === project.id
    ).length;
  };

  return (
    <Link
      to={"/tags/" + project.id}
      className="project-card"
      id={`project_card_${props.index}`}
      onClick={() => {
        AnalyticsHelper.trackEvent("Project Clicked", {
          projectId: project.id,
          title: project.title,
        });
        // AnalyticsHelper.smartLookTrackEvent('Project Clicked', {
        //     projectId: project.id,
        //     title: project.title
        // })
      }}
      onMouseOver={(e) => props.handleSessionFocus(e, "hover")}
    >
      <div className="proj-content px-3">
        <Link
          to={"/tags/" + project.id}
          onClick={() => dispatch(setActiveProject(project))}
          className="name"
        >
          <div className="d-flex align-items-center">
            <div className="me-2">
              <Emoji
                unified={project.emoji || "1f31f"}
                emojiStyle={EmojiStyle.APPLE}
                size={20}
              />
            </div>
            {project.title ? project.title : "A Project"}
          </div>
        </Link>

        {project.status === "archived" && project.archivedAt && (
          <div className="archived-at">
            Archived: {format(new Date(project.archivedAt), "MMM dd, yyyy")}
          </div>
        ) 
        // : (
        //   <div className="progress-container">
        //     <LineProgress
        //       current={calculateProgress(project)}
        //       total={
        //         tasks.filter((task) => task.project_id === project.id)?.length
        //       }
        //       color={"orange"}
        //       redesign={true}
        //       showStatus={false}
        //     />
        //   </div>
        // )
        }
      </div>
    </Link>
  );
};

export default ProjectCard;
