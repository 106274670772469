import { useSelector } from "react-redux";
import NotifierHelper from "../../../application/common/NotifierHelper";
import supabase from "../../../infrastructure/Supabase";
import { SUPABASE_TABLE_NAME } from "../../../infrastructure/Supabase/constants";
import { ReactComponent as Plus } from "../../../assets/images/icons-mlp/plus.svg";
import SingleStatus from "./singleStatus";
import { SortPosition } from "../../../application/common/helper";

export default function GroupContainer({
  groupId,
  groupName,
  statuses,
  updateStatus,
  updateReduxWorkflow,
}) {
  const workSpaceInfo = useSelector((state) => state.user.workSpaceInfo);

  const handleUpdateStatusState = (newData, isUpdateRedux = false) => {
    updateStatus((prev) => {
      const newState = {
        ...prev,
        [groupName.toLowerCase().replaceAll(" ", "")]: newData,
      };
      if (isUpdateRedux) {
        updateReduxWorkflow(newState);
      }
      return newState;
    });
  };

  const updateStatusInSupabase = async (status) => {
    try {
      const { data, error } = await supabase
        .from(SUPABASE_TABLE_NAME.WORKFLOW_GROUP_STATUS)
        .update({
          name: status.name,
          position: status.position,
        })
        .eq("id", status.id)
        .select()
        .single();

      if (error) {
        NotifierHelper.notify("info", error.message);
        return;
      }

      const updatedStatus = statuses.map((stat) => {
        if (stat.id === status.id) {
          return { ...stat, ...data, isEditing: false, isLoading: false };
        }
        return stat;
      });
      handleUpdateStatusState(updatedStatus, true);
    } catch {
      NotifierHelper.notify("info", "Something went wrong");
    }
  };

  const deleteStatusFromSubapase = async (status) => {
    try {
      const { error } = await supabase
        .from(SUPABASE_TABLE_NAME.WORKFLOW_GROUP_STATUS)
        .delete()
        .eq("id", status.id);

      if (error) {
        NotifierHelper.notify("info", error.message);
        return;
      }

      const updatedStatus = statuses.filter((stat) => stat.id !== status.id);
      handleUpdateStatusState(updatedStatus, true);
    } catch {
      NotifierHelper.notify("info", "Something went wrong");
    }
  };

  const createStatusInSupabase = async (newStatus) => {
    try {
      const { data, error } = await supabase
        .from(SUPABASE_TABLE_NAME.WORKFLOW_GROUP_STATUS)
        .insert({
          name: newStatus.name,
          position: newStatus.position,
          workflow_group_id: groupId,
          workspace_id: workSpaceInfo.id,
        })
        .select()
        .single();

      if (error) {
        NotifierHelper.notify("info", error.message);
        deleteStatus(newStatus.name);
        return;
      }

      const updatedStatus = statuses.map((stat) => {
        if (stat.name === newStatus.name) {
          return { ...stat, ...data, isEditing: false, isLoading: false };
        }
        return stat;
      });
      handleUpdateStatusState(updatedStatus, true);
    } catch {
      NotifierHelper.notify("info", "Something went wrong");
      deleteStatus(newStatus.name);
    }
  };

  const handleSaveStatus = (newStatus) => {
    const updatedStatus = statuses.map((stat) => {
      if (stat?.id === newStatus?.id || stat.name === newStatus.name) {
        return { ...newStatus, isEditing: false, isLoading: true };
      }
      return stat;
    });

    handleUpdateStatusState(updatedStatus);

    if (newStatus?.id) {
      // udpate statuses in supabase
      updateStatusInSupabase(newStatus);
    } else {
      // Create statuses in supabse
      createStatusInSupabase(newStatus);
    }
  };

  const changeStatusName = (newStatus) => {
    const updatedStatus = statuses.map((stat) => {
      if (stat?.id === newStatus?.id || stat.name === newStatus.name) {
        return newStatus;
      }
      return stat;
    });

    handleUpdateStatusState(updatedStatus);
  };

  const updateEditingStatus = (status) => {
    let updatedStatus = statuses.map((stat) => {
      if (stat?.id === status?.id || stat.name === status.name) {
        return { ...status, isEditing: !status.isEditing };
      }
      return stat;
    })

    if(status.name === "" && status?.tempId) {
      updatedStatus = updatedStatus.filter(stat => stat.tempId !== status.tempId);
    }

    handleUpdateStatusState(updatedStatus);
  };

  const deleteStatus = (status) => {
    if (status?.id) {
      const updatedStatus = statuses.map((stat) => {
        if (stat?.id === status?.id || stat.name === status.name) {
          return { ...status, isLoading: true };
        }
        return stat;
      });
      handleUpdateStatusState(updatedStatus);
      deleteStatusFromSubapase(status);
    } else {
      const updatedStatus = statuses.filter(
        (stat) => stat.name !== status.name
      );

      handleUpdateStatusState(updatedStatus);
    }
  };

  const handleChangePosition = (status, currentPosition) => {
    // Sort position
    const updatedStatues = SortPosition(
      statuses,
      currentPosition,
      status.position
    ).map((sat, i) => ({ ...sat, position: i }));
    handleUpdateStatusState(updatedStatues);

    // Update status in supabase
    updatedStatues.forEach((stat) => {
      updateStatusInSupabase(stat);
    });
  };

  return (
    <div className="single-group" key={groupName}>
      <div className="group-name">
        <p className="group-title">{groupName}</p>
        <div
          className="shortcut-tag"
          onClick={() => {
            if (statuses.length < 5) {
              handleUpdateStatusState([
                ...statuses,
                {
                  tempId: new Date().getTime(),
                  name: "",
                  position: statuses.length,
                  isEditing: true,
                  isLoading: false,
                },
              ]);
            } else {
              NotifierHelper.notify("info", "Only 5 Status is Allowed");
            }
          }}
        >
          <Plus />
        </div>
      </div>
      <div className="statuses-container">
        {statuses
          .slice()
          .sort((a, b) => a.position - b.position)
          .map((status, i) => (
            <SingleStatus
              status={status}
              groupName={groupName}
              changeStatusName={changeStatusName}
              handleSaveStatus={handleSaveStatus}
              deleteStatus={deleteStatus}
              updateEditingStatus={updateEditingStatus}
              changePosition={handleChangePosition}
              showUpButton={i !== 0}
              showDownButton={statuses.length - 1 !== i}
            />
          ))}
      </div>
    </div>
  );
}
