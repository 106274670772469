import { useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";

import task_icon from "../../../../assets/images/icons-mlp/check-circle.svg";
import project_icon from "../../../../assets/images/icons-mlp/file-text.svg";

export default function useMentionData() {
  const [mentionData, setMentionData] = useState([]);

  const workSpaceMember = useSelector(({ members }) => members.workspace);
  const { tasks, projects } = useSelector(({ project }) => project);

  const makeMentionArray = () => {
    const mentionArray = [];

    if (workSpaceMember && workSpaceMember.length) {
      const filteredArray = workSpaceMember.map((member) => ({
        label: member.username,
        value: member.user_id,
        avatar: member.avatar,
      }));

      mentionArray.push(...filteredArray);
    }

    if (tasks) {
      const filteredArray = tasks.filter(task => task.title).map((task) => ({
        label: task.title,
        value: task.id,
        avatar: task_icon,
      }));

      mentionArray.push(...filteredArray);
    }

    if (projects) {
      const filteredArray = projects.filter(project => project.title).map((project) => ({
        label: project.title,
        value: project.id,
        avatar: project_icon,
      }));

      mentionArray.push(...filteredArray);
    }

    setMentionData(mentionArray);
  };

  React.useEffect(() => {
    makeMentionArray();
  }, []);

  return { mentionData };
}
