import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import SideNav from "./SideNavbar";

// non-lazy
import ProjectSession from "../Projects/ProjectSession";
import Sessions from "../Sessions";
import Invites from "../Invites";
import TaskView from "../../infrastructure/Common/TaskView";
import SessionFocus from "../../infrastructure/Common/SessionFocus";
import Projects from "./../Projects";
import Settings from "../Settings";

import asset_loader from "./../../assets/images/icons/loader.svg";

import "./home.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAddProject,
  handleAddTask,
  handleDeleteProject,
  handleDeleteTask,
  handleUpdateProject,
  handleUpdateTask,
  setProjects,
  setTasks,
} from "../../store/reducers/projectSlice";
import supabase from "../../infrastructure/Supabase";
import { isEqual, omit } from "lodash";
import { SUPABASE_TABLE_NAME } from "../../infrastructure/Supabase/constants";
import { setWorkSpaceData } from "../../store/reducers/userSlice";
import {
  setInvites,
  setWorkspaceMembers,
} from "../../store/reducers/membersSlice";
import NotifierHelper from "../../application/common/NotifierHelper";
import Updates from "../Updates";
import CommentListeners from "../../infrastructure/Listeners/CommentListeners";

// const Projects = React.lazy(() => import('./../Projects'));

const isWaiting = () => {
  return (
    <div className="lazy-loader">
      <img src={asset_loader} alt="Loading" />
    </div>
  );
};

const Home = () => {
  // const webCtx = useContext(WebContext);
  const navigate = useNavigate();
  const route = useLocation();
  const tasks = useSelector((state) => state.project.tasks);
  const userInfo = useSelector((state) => state.user.userInfo);
  const workSpaceInfo = useSelector((state) => state.user.workSpaceInfo);
  const projects = useSelector((state) => state.project.projects);
  const workspaceMembers = useSelector((state) => state.members.workspace);
  const invites = useSelector((state) => state.members.invited);
  const dispatch = useDispatch();

  if (Object.keys(workSpaceInfo).length) {
    // Event listener for TASKS
    supabase
      .channel("task-all-channel")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: SUPABASE_TABLE_NAME.TASK,
          filter: `workspace_id=eq.${workSpaceInfo.id}`,
        },
        (payload) => {
          if (payload.eventType === "UPDATE") {
            // Update task
            const oldTask = tasks.find((task) => task.id === payload.old.id);
            // If delete is true then remove task
            if(payload.new.isDelete) {
              dispatch(handleDeleteTask(payload.new));
              return;
            }
            // Check both object by removing updated_at cause it leads to infinite loop error
            if (
              !isEqual(
                omit(oldTask, ["updated_at"]),
                omit(payload.new, ["updated_at"])
              )
            ) {
              dispatch(handleUpdateTask(payload.new));
            }
          }

          if (payload.eventType === "DELETE") {
            // Delete task
            dispatch(handleDeleteTask(payload.old));
          }

          if (payload.eventType === "INSERT") {
            // Insert task
            dispatch(handleAddTask(payload.new));
          }
        }
      )
      .subscribe();

    // Event listener for PROJECT
    supabase
      .channel("project-all-channel")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: SUPABASE_TABLE_NAME.PROJECT,
          filter: `workspace_id=eq.${workSpaceInfo.id}`,
        },
        (payload) => {
          if (payload.eventType === "UPDATE") {
            // Update project
            const oldProject = projects.find(
              (project) => project.id === payload.old.id
            );
            // Check both object by removing updated_at cause it leads to infinite loop error
            if (
              !isEqual(
                omit(oldProject, ["updated_at"]),
                omit(payload.new, ["updated_at"])
              )
            ) {
              // Update Project
              dispatch(handleUpdateProject(payload.new));
            }
          }

          if (payload.eventType === "DELETE") {
            // Delete task
            dispatch(handleDeleteProject(payload.old));
          }

          if (payload.eventType === "INSERT") {
            // Insert task
            dispatch(handleAddProject(payload.new));
          }
        }
      )
      .subscribe();

    // Listen on personal profile update
    supabase
      .channel("personal-profile")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: SUPABASE_TABLE_NAME.PROFILES,
          filter: `user_id=eq.${userInfo.id}`,
        },
        (payload) => {
          if(payload.new?.workspace_id === null) {
            dispatch(setWorkSpaceData({}));
            dispatch(setProjects(null));
            dispatch(setTasks(null));
            navigate('/no-workspace')
          }
        }
      )
      .subscribe();

    // Listen on workspace members
    supabase
      .channel("profile-all-channel")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: SUPABASE_TABLE_NAME.PROFILES,
          filter: `workspace_id=eq.${workSpaceInfo.id}`,
        },
        (payload) => {
          if (payload.eventType === "UPDATE") {
            // Update profile
            const updatedLists = workspaceMembers.map((member) => {
              if (member.id === payload.new.id) return payload.new;
              return member;
            });
            dispatch(setWorkspaceMembers(updatedLists));
          }

          if (payload.eventType === "DELETE") {
            // Check if deleted user has logged in
            const deletedUser = workspaceMembers.find(
              (member) => member.id === payload.old.id
            );
            if (userInfo.id === deletedUser.user_id) {
              NotifierHelper.notify(
                "info",
                "You're no longer part of this workspace"
              );
              dispatch(setWorkSpaceData({}));
              dispatch(setProjects(null));
              dispatch(setTasks(null));
              navigate("/login");
            }

            // Remove assignee from task if any
            dispatch(
              setTasks(
                tasks.map((task) => {
                  if (task.assignee === deletedUser.user_id)
                    return { ...task, assignee: null };
                  return task;
                })
              )
            );

            // Delete profile
            dispatch(
              setWorkspaceMembers(
                workspaceMembers.filter(
                  (member) => member.id !== payload.old.id
                )
              )
            );
          }

          if (payload.eventType === "INSERT") {
            // Insert profile
            if (
              workspaceMembers.findIndex(
                (member) => member.id === payload.new.id
              ) === -1
            )
              dispatch(setWorkspaceMembers([...workspaceMembers, payload.new]));
          }
        }
      )
      .subscribe();

    // Listen on workspace update
    supabase
      .channel("workspace-update-channel")
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: SUPABASE_TABLE_NAME.WORKSPACE,
          filter: `id=eq.${workSpaceInfo.id}`,
        },
        (payload) => {
          if (!isEqual(workSpaceInfo, payload.new))
            dispatch(setWorkSpaceData(payload.new));
        }
      )
      .subscribe();

    if (userInfo.type === "owner") {
      // Listen on invites members
      supabase
        .channel("invites-all-channel")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: SUPABASE_TABLE_NAME.INVITES,
            filter: `workspace_id=eq.${workSpaceInfo.id}`,
          },
          (payload) => {
            if (payload.eventType === "UPDATE") {
              // Update invites
              const updatedLists = invites
                .map((invite) => {
                  if (invite.id === payload.new.id) return payload.new;
                  return invite;
                })
                .filter((invites) => !invites.is_joined);
              
              if(payload.new.is_joined) dispatch(setWorkspaceMembers([...workspaceMembers, payload.new]));
              dispatch(setInvites(updatedLists));
            }

            if (payload.eventType === "DELETE") {
              // Delete invites
              dispatch(
                setInvites(
                  invites.filter((invite) => invite.id !== payload.old.id)
                )
              );
            }

            if (payload.eventType === "INSERT" && !payload.new.is_joined) {
              // Insert invites
              if (
                invites.findIndex((invite) => invite.id === payload.new.id) ===
                -1
              )
                dispatch(setInvites([...invites, payload.new]));
            }
          }
        )
        .subscribe();
    }
  }

  useEffect(() => {
    route.pathname === "/" && navigate("/home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wrapper main-content">
      <SideNav />
      <div className="main-panel d-flex flex-column">
        <div className="content">
          <React.Suspense fallback={isWaiting()}>
            <Routes>
              <Route path="home" element={<Sessions />} />
              <Route path="canvases/:sessionId" element={<TaskView />} />
              <Route path="tags" element={<Projects />} />
              <Route path="updates" element={<Updates />} />
              <Route
                path="tags/:projectId"
                element={<ProjectSession key={window.location.pathname} />}
              />
              <Route
                path="tags/:projectId/sessions/:sessionId"
                element={<SessionFocus />}
              />
              <Route
                path="tags/:projectId/canvases/:sessionId"
                element={<TaskView />}
              />
              <Route path="invites" element={<Invites />} />
              <Route path="settings" element={<Settings />} />
            </Routes>
            <CommentListeners />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
};

export default Home;
