import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils";
import {
  $createParagraphNode,
  $createRangeSelection,
  $getSelection,
  $insertNodes,
  $isNodeSelection,
  $isRootOrShadowRoot,
  $setSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  createCommand,
  DRAGOVER_COMMAND,
  DRAGSTART_COMMAND,
  DROP_COMMAND,
} from "lexical";
import { useEffect, useRef, useState } from "react";
import * as React from "react";

import { $createImageNode, $isImageNode, ImageNode } from "../nodes/ImageNode";
import { SUPABASE_BUCKET_NAME } from "../../../Supabase/constants";
import supabase from "../../../Supabase";
import NotifierHelper from "../../../../application/common/NotifierHelper";
import { nanoid } from "nanoid";

import asset_loader from "../../../../assets/images/icons/loader.svg";

const getDOMSelection = (targetWindow) =>
  (targetWindow || window).getSelection();

export const INSERT_IMAGE_COMMAND = createCommand("INSERT_IMAGE_COMMAND");

export function InsertImageUriDialogBody({ onClick, onClose }) {
  const [src, setSrc] = useState("");
  const [altText, setAltText] = useState("");

  const isDisabled = src === "";

  return (
    <div className="d-flex flex-column gap-3">
      <input
        label="Image URL"
        placeholder="i.e. https://source.unsplash.com/random"
        onChange={(e) => setSrc(e.target.value)}
        value={src}
        data-test-id="image-modal-url-input"
        className="form-control mikyal-field"
      />
      <input
        label="Alt Text"
        placeholder="Random unsplash image"
        onChange={(e) => setAltText(e.target.value)}
        value={altText}
        data-test-id="image-modal-alt-text-input"
        className="form-control mikyal-field"
      />
      <div className="d-flex flex-row justify-content-end gap-2">
        <button
          className="mikyal-btn secondary-button"
          data-test-id="image-modal-file-upload-btn"
          onClick={() => onClose()}
        >
          Cancel
        </button>
        <button
          className="mikyal-btn black-button"
          data-test-id="image-modal-confirm-btn"
          disabled={isDisabled}
          onClick={() => onClick({ altText, src })}
        >
          Confirm
        </button>
      </div>
    </div>
  );
}

export function InsertImageUploadedDialogBody({ onClick, onClose }) {
  const [altText, setAltText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const uploadImageToStorage = async () => {
    setIsLoading(true);
    // Upload file to storage
    const { data, error } = await supabase.storage
      .from(SUPABASE_BUCKET_NAME.TASK_IMAGES)
      .upload(
        `${nanoid(7)}-${selectedFile.name.replaceAll(" ", "_")}`,
        selectedFile,
        {
          cacheControl: "3600",
          upsert: true,
        }
      );

    if (error) {
      onClose();
      NotifierHelper.notify("info", error.message);
      return;
    }

    // Get downloadURL from storage for same file
    const { data: downloadURL } = supabase.storage
      .from(SUPABASE_BUCKET_NAME.TASK_IMAGES)
      .getPublicUrl(data.path);

    setIsLoading(false);
    onClick({ altText, src: downloadURL?.publicUrl });
  };

  return (
    <div className="d-flex flex-column gap-3">
      <input
        label="Image Upload"
        onChange={(e) => setSelectedFile(e.target.files[0])}
        type="file"
        className="d-block"
        accept="image/*"
        data-test-id="image-modal-file-upload"
      />
      <input
        label="Alt Text"
        placeholder="Descriptive alternative text"
        onChange={(e) => setAltText(e.target.value)}
        value={altText}
        className="form-control mikyal-field"
        data-test-id="image-modal-alt-text-input"
      />
      <div className="d-flex flex-row justify-content-end gap-2">
        <button
          className="mikyal-btn secondary-button"
          data-test-id="image-modal-file-upload-btn"
          disabled={isLoading}
          onClick={() => onClose()}
        >
          Cancel
        </button>
        <button
          className="mikyal-btn black-button"
          data-test-id="image-modal-file-upload-btn"
          disabled={selectedFile === null}
          onClick={() => uploadImageToStorage()}
        >
          {isLoading ? (
            <div className="LataEditor__button-loader d-inline-flex">
              <img src={asset_loader} alt="Loading" />
            </div>
          ) : (
            "Confirm"
          )}
        </button>
      </div>
    </div>
  );
}

export function InsertImageDialog({ activeEditor, onClose }) {
  const [mode, setMode] = useState(null);
  const hasModifier = useRef(false);

  useEffect(() => {
    hasModifier.current = false;
    const handler = (e) => {
      hasModifier.current = e.altKey;
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [activeEditor]);

  const onClick = (payload) => {
    activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
    onClose();
  };

  return (
    <>
      {!mode && (
        <div className="d-flex flex-column gap-2 position-relative">
          <button
            className="mikyal-btn black-button"
            data-test-id="image-modal-option-url"
            onClick={() => setMode("url")}
          >
            URL
          </button>
          <button
            className={`mikyal-btn black-button`}
            data-test-id="image-modal-option-file"
            onClick={() => setMode("file")}
          >
            File
          </button>
          <div className="d-flex flex-row justify-content-end gap-2">
            <button
              className="mikyal-btn secondary-button"
              data-test-id="image-modal-file-upload-btn"
              onClick={() => onClose()}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {mode === "url" && (
        <InsertImageUriDialogBody onClick={onClick} onClose={onClose} />
      )}
      {mode === "file" && (
        <InsertImageUploadedDialogBody onClick={onClick} onClose={onClose} />
      )}
    </>
  );
}

export default function ImagesPlugin({ captionsEnabled }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error("ImagesPlugin: ImageNode not registered on editor");
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_IMAGE_COMMAND,
        (payload) => {
          const imageNode = $createImageNode(payload);
          $insertNodes([imageNode]);
          if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
            $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        DRAGSTART_COMMAND,
        (event) => {
          return onDragStart(event);
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(
        DRAGOVER_COMMAND,
        (event) => {
          return onDragover(event);
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        DROP_COMMAND,
        (event) => {
          return onDrop(event, editor);
        },
        COMMAND_PRIORITY_HIGH
      )
    );
  }, [captionsEnabled, editor]);

  return null;
}

const TRANSPARENT_IMAGE =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
const img = document.createElement("img");
img.src = TRANSPARENT_IMAGE;

function onDragStart(event) {
  const node = getImageNodeInSelection();
  if (!node) {
    return false;
  }
  const dataTransfer = event.dataTransfer;
  if (!dataTransfer) {
    return false;
  }
  dataTransfer.setData("text/plain", "_");
  dataTransfer.setDragImage(img, 0, 0);
  dataTransfer.setData(
    "application/x-lexical-drag",
    JSON.stringify({
      data: {
        altText: node.__altText,
        caption: node.__caption,
        height: node.__height,
        key: node.getKey(),
        maxWidth: node.__maxWidth,
        showCaption: node.__showCaption,
        src: node.__src,
        width: node.__width,
      },
      type: "image",
    })
  );

  return true;
}

function onDragover(event) {
  const node = getImageNodeInSelection();
  if (!node) {
    return false;
  }
  if (!canDropImage(event)) {
    event.preventDefault();
  }
  return true;
}

function onDrop(event, editor) {
  const node = getImageNodeInSelection();
  if (!node) {
    return false;
  }
  const data = getDragImageData(event);
  if (!data) {
    return false;
  }
  event.preventDefault();
  if (canDropImage(event)) {
    const range = getDragSelection(event);
    node.remove();
    const rangeSelection = $createRangeSelection();
    if (range !== null && range !== undefined) {
      rangeSelection.applyDOMRange(range);
    }
    $setSelection(rangeSelection);
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, data);
  }
  return true;
}

function getImageNodeInSelection() {
  const selection = $getSelection();
  if (!$isNodeSelection(selection)) {
    return null;
  }
  const nodes = selection.getNodes();
  const node = nodes[0];
  return $isImageNode(node) ? node : null;
}

function getDragImageData(event) {
  const dragData = event.dataTransfer?.getData("application/x-lexical-drag");
  if (!dragData) {
    return null;
  }
  const { type, data } = JSON.parse(dragData);
  if (type !== "image") {
    return null;
  }

  return data;
}

function canDropImage(event) {
  const target = event.target;
  return !!(
    target &&
    !target.closest("code, span.LataEditor__image") &&
    target.parentElement &&
    target.parentElement.closest("div.ContentEditable__root")
  );
}

function getDragSelection(event) {
  let range;
  const target = event.target;
  const targetWindow =
    target == null
      ? null
      : target.nodeType === 9
      ? target.defaultView
      : target.ownerDocument.defaultView;
  const domSelection = getDOMSelection(targetWindow);
  if (document.caretRangeFromPoint) {
    range = document.caretRangeFromPoint(event.clientX, event.clientY);
  } else if (event.rangeParent && domSelection !== null) {
    domSelection.collapse(event.rangeParent, event.rangeOffset || 0);
    range = domSelection.getRangeAt(0);
  } else {
    throw Error(`Cannot get the selection when dragging`);
  }

  return range;
}
