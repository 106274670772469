import './EditorTheme.scss';

const theme = {
  code: 'LataEditor__code',
  quote: 'LataEditor__quote',
  link: 'LataEditor__link',
  image: 'LataEditor__image',
  video: 'LataEditor__video',
  file: 'LataEditor__file',
  paragraph: 'LataEditor__paragraph',
  codeHighlight: {
    atrule: 'LataEditor__tokenAttr',
    attr: 'LataEditor__tokenAttr',
    boolean: 'LataEditor__tokenProperty',
    builtin: 'LataEditor__tokenSelector',
    cdata: 'LataEditor__tokenComment',
    char: 'LataEditor__tokenSelector',
    class: 'LataEditor__tokenFunction',
    'class-name': 'LataEditor__tokenFunction',
    comment: 'LataEditor__tokenComment',
    constant: 'LataEditor__tokenProperty',
    deleted: 'LataEditor__tokenProperty',
    doctype: 'LataEditor__tokenComment',
    entity: 'LataEditor__tokenOperator',
    function: 'LataEditor__tokenFunction',
    important: 'LataEditor__tokenVariable',
    inserted: 'LataEditor__tokenSelector',
    keyword: 'LataEditor__tokenAttr',
    namespace: 'LataEditor__tokenVariable',
    number: 'LataEditor__tokenProperty',
    operator: 'LataEditor__tokenOperator',
    prolog: 'LataEditor__tokenComment',
    property: 'LataEditor__tokenProperty',
    punctuation: 'LataEditor__tokenPunctuation',
    regex: 'LataEditor__tokenVariable',
    selector: 'LataEditor__tokenSelector',
    string: 'LataEditor__tokenSelector',
    symbol: 'LataEditor__tokenProperty',
    tag: 'LataEditor__tokenProperty',
    url: 'LataEditor__tokenOperator',
    variable: 'LataEditor__tokenVariable',
  },
  list: {
    listitem: "LataEditor__listItem",
    listitemChecked: "LataEditor__listItemChecked",
    listitemUnchecked: "LataEditor__listItemUnchecked",
    nested: {
      listitem: "LataEditor__nestedListItem",
    },
    olDepth: [
      "LataEditor__ol1",
      "LataEditor__ol2",
      "LataEditor__ol3",
      "LataEditor__ol4",
      "LataEditor__ol5",
    ],
    ul: "LataEditor__ul",
  },
  text: {
    bold: 'LataEditor__textBold',
    code: 'LataEditor__textCode',
    italic: 'LataEditor__textItalic',
    strikethrough: 'LataEditor__textStrikethrough',
    subscript: 'LataEditor__textSubscript',
    superscript: 'LataEditor__textSuperscript',
    underline: 'LataEditor__textUnderline',
    underlineStrikethrough: 'LataEditor__textUnderlineStrikethrough',
  },
};

export default theme;
