class Globals {

    httpOptions = {}; // for HTTP Headers

    // baseUrl = 'http://localhost:5200';
    // baseUrl = 'https://mikyal-be.azharmughal.com';
    baseUrl =  'https://api-v1.lata.so';
    // baseUrl = 'https://mlp-be.azharmughal.com';
    // baseUrl = 'https://dev-be.mikyal.com';
    // baseUrl = 'https://be-app.dev2.mikyal.com';
    // baseUrl = 'https://be-app.mikyal.com';
    // baseUrl = process.env.MIKYAL_API_BASE_URL;
    // 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

    // -----------Global-----------
    dropdownData = `${this.baseUrl}/api/v1/dd-data`;

    // -----------Auth-----------
    register = `${this.baseUrl}/api/v1/auth/register`;
    login = `${this.baseUrl}/api/v1/auth/login`;
    verifyEmail = `${this.baseUrl}/api/v1/auth/verify-email`;
    forgotPassword = `${this.baseUrl}/api/v1/auth/forgot-password`;
    resetPassword = `${this.baseUrl}/api/v1/auth/reset-password`;
    updatePassword = `${this.baseUrl}/api/v1/auth/update-password`;
    updateInfo = `${this.baseUrl}/api/v1/auth/update-info`;
    me = `${this.baseUrl}/api/v1/auth/me`;
    userDetail = `${this.baseUrl}/api/v1/users/user-workspace`;

    // -----------Dashboard-----------
    dashboard = `${this.baseUrl}/api/dashboard`;

    // -----------Projects-----------
    projects = `${this.baseUrl}/api/v1/projects`;
    newProject = `${this.baseUrl}/api/v1/tags/new`;
    saveProject = `${this.baseUrl}/api/v1/tags/save`;
    archiveProject = `${this.baseUrl}/api/v1/tags/archive`;


    // -----------Sessions-----------
    sessions = `${this.baseUrl}/api/v1/sessions`;
    newSession = `${this.baseUrl}/api/v1/sessions/new`;
    saveSession = `${this.baseUrl}/api/v1/sessions/save`;
    completeSession = `${this.baseUrl}/api/v1/sessions/complete`;
    // archiveProject = `${this.baseUrl}/api/v1/tags/archive`;


    // -----------Clients-----------
    clients = `${this.baseUrl}/api/v1/clients`;
    newClient = `${this.baseUrl}/api/v1/clients/new`;
    updateClient = `${this.baseUrl}/api/v1/clients/update`;

    // -----------Invoices-----------
    invoices = `${this.baseUrl}/api/v1/invoices`;
    newInvoice = `${this.baseUrl}/api/v1/invoices/new`;
    saveInvoice = `${this.baseUrl}/api/v1/invoices/save`;
    clientInvoice = `${this.baseUrl}/api/v1/invoices/client`;
    sendInvoice = `${this.baseUrl}/api/v1/invoices/send`;

    // -----------Invites-----------
    invites = `${this.baseUrl}/api/v1/invites`;
    newInvite = `${this.baseUrl}/api/v1/invites/new`;
    updateInvite = `${this.baseUrl}/api/v1/invites/update`;

    // -----------Invites-----------
    workspaces = `${this.baseUrl}/api/v1/workspaces`;
    saveWorkspace = `${this.baseUrl}/api/v1/workspaces/save`;

}

export default new Globals();