import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

export const dropDownSlice = createSlice({
  name: "dropdown",
  initialState: {
    dropDownData: {},
    workflowStatus: [],
  },
  reducers: {
    setDropDown: (state, action) => {
      state.dropDownData = action.payload;
    },
    setWorkFlowStatus: (state, action) => {
      state.workflowStatus = action.payload;
    },
    updateWorkFlowStatus: (state, action) => {
      state.workflowStatus = action.payload;
    },
    handleRemoveMember: (state, action) => {
      const memberEmail = action.payload;
      const team = cloneDeep(state.dropDownData?.team);

      if (team.length) {
        state.dropDownData.team = team.filter(
          (member) => member.email !== memberEmail
        );
      }
    },
    handleUpdateDDProjectData: (state, action) => {
      const projData = action.payload;
      const projects = cloneDeep(state.dropDownData?.projects);

      if (projects?.length) {
        if (projects?.findIndex((a) => a.value === projData.id) > -1) {
          projects[projects?.findIndex((a) => a.value === projData.id)].label =
            projData.title;
        } else {
          projects?.push({ label: projData.title, value: projData.id });
        }
      }

      state.dropDownData.projects = projects;
    },
    handleDDAddNewProject: (state, action) => {
      const projData = action.payload;
      const projects = cloneDeep(state.dropDownData?.projects);

      if (projects?.length) {
        projects?.push({ label: projData.title, value: projData.id });
        state.dropDownData.projects = projects;
      } else {
        state.dropDownData.projects = [
          { label: projData.title, value: projData.id },
        ];
      }
    },
  },
});

export const {
  setDropDown,
  handleRemoveMember,
  handleUpdateDDProjectData,
  handleDDAddNewProject,
  setWorkFlowStatus,
  updateWorkFlowStatus,
} = dropDownSlice.actions;

export default dropDownSlice.reducer;
