import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { reduce } from "lodash";

import secondsToTimestamp from "../../../../application/utils/secondsToTimestamp";

import asset_command from './../../../../assets/images/icons/command.svg';


import "./task-progress.scss";

const SessionTask = ({
  tIndex,
  onTaskComplete,
  onTimerStop,
  onTimerStart,
  disableTimeTracking,
  handleChange,
  handleKeyPress,
  taskControl,
  handleTaskControl,
  trigger,
  ...task
}) => {
  const timerRef = useRef(null)
  const [startTime, setStartTime] = useState(null)
  const [timeLapsed, setTimeLapsed] = useState(0) // in seconds
  // const [initialDate, setInitialDate] = useState(new Date());

  const duration = useMemo(() => {
    let durationInSeconds = reduce(task.timeIntervals, (total, interval) => total + parseInt(interval.duration), 0);
    if (timeLapsed > 0) {
      durationInSeconds += timeLapsed
    }
    return secondsToTimestamp(durationInSeconds)
  }, [task.timeIntervals, timeLapsed]);

  const handleTimer = () => {
    if (!disableTimeTracking && !task.isCompleted) {
      if (!startTime) {
        onTimerStart(task, tIndex)
        return setStartTime(new Date())
      }
      stopTimer(task);
    }
  }

  const stopTimer = (task) => {
    console.log('STOP TIMER');
    onTimerStop(task, startTime, timeLapsed)
    clearInterval(timerRef.current)
    timerRef.current = null
    setStartTime(null)
    setTimeLapsed(0)
  }

  const startTimer = useCallback(() => {
    timerRef.current = setInterval((newTimeLapsed) => {
      setTimeLapsed(newTimeLapsed)
    }, 1000, timeLapsed + 1)
  }, [timeLapsed])

  useEffect(() => {
    if (!!startTime) {
      startTimer()
    }
    return () => {
      timerRef.current && clearInterval(timerRef.current)
    }
  }, [startTime, startTimer]);

  useEffect(() => {
    if (trigger.item === tIndex) {
      handleTimer();
    }
  }, [trigger]);

  return (
    <div className={"single-task" + (startTime ? ' in-progress ' : '') + (tIndex === taskControl ? ' active-task ' : '') + (task.isCompleted ? ' untrackable-task' : '')} id={'task_control_' + tIndex} key={task.id} tabIndex={tIndex} onKeyDown={handleTaskControl} onClick={handleTaskControl} onMouseOver={e => handleTaskControl(e, 'hover')}>
      <div className="">
        <div className="header">
          <div className="checkbox line-on-input-on-check d-flex align-items-center w-100">
            <input
              type="checkbox"
              className={"styled-checkbox mr-2 " + (startTime ? 'timer-disabled' : '')}
              id={'task_' + tIndex}
              name="isCompleted"
              defaultChecked={task.isCompleted}
              onChange={(e) => handleChange(e, 'task-checkbox', null, tIndex)}
              disabled={startTime}
            />

            <label htmlFor={'task_' + tIndex} className="task-label fw-bold">&nbsp;</label>
            <input
              id={'t_input_' + (tIndex)}
              type="text"
              className="mikyal-borderless-field px-0 ms-input checkbox-input list-input"
              name="title"
              placeholder="Write a task..."
              value={task.title}
              onChange={(e) => handleChange(e, 'task', null, tIndex)}
              // onKeyPress={(e) => handleKeyPress(e, 'task', msIndx, tIndex)}
              onKeyDown={(e) => handleKeyPress(e, 'task', null, tIndex)}
              autoFocus={task?.focus}
            />

            <div className="ms-timer d-flex align-items-center no-wrap">
              <div className={"timer " + (startTime ? 'tracking-timer' : '')}>
                {duration}
              </div>

              <div className={'toggle-timer px-1 d-flex align-items-center' + (task.isCompleted ? ' disabled d-none' : '')} onClick={handleTimer}>
                <div>{startTime ? 'Stop Timer' : 'Start Timer'}</div>
                <div className="shortcut-tag">
                  <img src={asset_command} className="command" alt="command" />
                  H
                </div>
              </div>
              {task.isCompleted ?
                <div className="hover-block"><span className="radius"></span></div>
                : null}
            </div>
          </div>

        </div>

      </div>

    </div>
  );
};

export default SessionTask;
