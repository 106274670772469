/**
 * @param {Array} arr The Array to apend in
 * @param {number} index The index to append in
 * @param {Array} newItem The Array to append
 */

const insertArrayAtSpecificIndex = (arr, index, newItem) => [
    ...arr.slice(0, index),
    ...newItem,
    ...arr.slice(index)
];
export default insertArrayAtSpecificIndex;