import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { GlobalHotKeys } from "react-hotkeys";

import InviteModal from "./InviteModal";
import InvitesApi from "../../infrastructure/ApiServices/InvitesApi";
import ConfirmDelete from "../../infrastructure/Common/ConfirmDelete";
import NotifierHelper from "../../application/common/NotifierHelper";

import asset_plus from "./../../assets/images/icons/plus.svg";
import asset_user_placeholder from "./../../assets/images/dummyDP.jpeg";
import asset_arrow_back from "./../../assets/images/icons/arrow-left-tail.svg";

import "./invites.scss";
import { useSelector } from "react-redux";
import Loader from "../../infrastructure/Common/MasterLoader";
import supabase from "../../infrastructure/Supabase";
import { SUPABASE_TABLE_NAME } from "../../infrastructure/Supabase/constants";

const Invites = (props) => {
  const userInfo = useSelector((state) => state.user.userInfo);

  const navigate = useNavigate();
  const [isWaiting, setIsWaiting] = useState(true);
  const [modal, setModal] = useState("");
  const [modalData, setModalData] = useState([]);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [invites, setInvites] = useState([]);

  const getInvitesList = async () => {
    // Get the member to invited to use lata app
    const { data: inviteToApp } = await supabase
      .from(SUPABASE_TABLE_NAME.INVITES)
      .select(`*`)
      .eq("invited_to_app", true)
      .eq("invited_by", userInfo.id);

    setInvites(inviteToApp);
    setIsWaiting(false);
  };

  useEffect(() => {
    if (invites.length === 0) getInvitesList();
  }, []);

  const keyMap = {
    goBack: "esc",
  };

  const keyHandlers = {
    goBack: (e) => {
      if (modal) {
        setModal("");
      } else {
        navigate("/home");
      }
      e.preventDefault();
      return false;
    },
  };

  const handleDelete = async (confirm, data) => {
    setDeleteLoader(true);
    const { error } = await supabase
      .from(SUPABASE_TABLE_NAME.INVITES)
      .delete()
      .eq("id", data.id);

    if (error) {
      NotifierHelper.notify("delete", error.message);
      setDeleteLoader(false);
      return;
    }

    let temp = [...invites];
    temp.splice(data.index, 1);
    setInvites(temp)
    NotifierHelper.notify('delete', "User deleted");
    setDeleteLoader(false);
    setModal("");
  };

  const handleNewInvite = (newInvite) => {
    setInvites((invites) => [...invites, newInvite]);
  };

  const handleModal = (name, item = {}, index = null) => {
    setModal(name);
    setModalData({ ...item, index });
  };

  return (
    <div className="user-invites container-fluid mikyal-sm-container mt-2">
      {isWaiting && <Loader />}

      <div className="position-relative">
        <NavLink to="/home" className="mlp-btn rounded icon return-back mr-4">
          <img src={asset_arrow_back} alt="back" />
        </NavLink>

        <div className="row">
          <div className="col-12 ">
            <div className="content-header d-flex align-items-center justify-content-between">
              <div className="meta-container">
                <h1
                  className="fs-4 fw-semi mt-1"
                  style={{ letterSpacing: "0.5px" }}
                >
                  Invites
                </h1>
              </div>
              <div className="action-container d-flex align-items-center">
                <div className="action-item">
                  <button
                    className="mikyal-btn green-button d-flex align-items-center"
                    onClick={() => handleModal("new")}
                  >
                    <span className="icon mr-1" style={{ width: "26px" }}>
                      <img src={asset_plus} alt="search" />
                    </span>
                    <span
                      style={{
                        fontSize: "var(--fs-sm)",
                        marginRight: "4px",
                        lineHeight: "120%",
                      }}
                    >
                      Add User
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="content-clients">
          <div className="row">
            <div className="col-12">
              <table className="mikyal-table-bordered redesign-table w-100">
                <tbody>
                  {invites.length
                    ? invites.map((item, index) => (
                        <tr className="tb-row" key={index}>
                          <td
                            className={
                              "max-fit-content custom-lh " +
                              (index > 0 ? "pt-4" : "")
                            }
                          >
                            <div className="d-flex align-items-center">
                              <div className="client-dp me-3">
                                <img
                                  src={asset_user_placeholder}
                                  alt="User Profile"
                                />
                              </div>
                              <div className="client-data lh-base">
                                <div className="primary-text inv-title fs-5 fw-bolder">
                                  {item.email}
                                </div>

                                <div className="client-email fw-semi d-flex">
                                  <div
                                    className={item.is_joined ? "green" : ""}
                                  >
                                    {item.is_joined
                                      ? "Active user"
                                      : "Awaiting response"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>

                          <td className="position-relative" align="right">
                            {!item.isAccepted && (
                              <div
                                className="pointer"
                                onClick={() =>
                                  handleModal("delete", item, index)
                                }
                              >
                                Remove
                              </div>
                            )}
                          </td>
                        </tr>
                      ))
                    : !isWaiting && (
                        <tr>
                          {" "}
                          <td colspan="42" className="not-found pt-5">
                            Data not found
                          </td>
                        </tr>
                      )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
      <GlobalHotKeys
        keyMap={keyMap}
        handlers={keyHandlers}
        allowChanges={true}
      ></GlobalHotKeys>

      {modal && (
        <React.Fragment>
          <InviteModal
            setModal={setModal}
            modal={modal === "new"}
            onSaveSuccess={handleNewInvite}
          />
          <ConfirmDelete
            setModal={setModal}
            modal={modal === "delete"}
            data={modalData}
            onDeleteSuccess={handleDelete}
            modalLoader={deleteLoader}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default Invites;
