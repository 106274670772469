import { toast } from "sonner";
import { ReactComponent as InfoIcon } from "./../../assets/images/icons-mlp/alert-circle.svg";
import { ReactComponent as UsersIcon } from "./../../assets/images/icons-mlp/users.svg";
import { ReactComponent as DeleteIcon } from "./../../assets/images/icons-mlp/trash.svg";
import { ReactComponent as CloseIcon } from "./../../assets/images/icons-mlp/x-dismiss.svg";
import { ReactComponent as LogIn } from "./../../assets/images/icons-mlp/log-in.svg";
import { ReactComponent as LogOut } from "./../../assets/images/icons-mlp/log-out.svg";
import { ReactComponent as Repeat } from "./../../assets/images/icons-mlp/repeat.svg";
import { ReactComponent as Bell } from "./../../assets/images/icons-mlp/bell.svg";
import { ReactComponent as Command } from "./../../assets/images/icons-mlp/cmd.svg";
class NotifierHelper {
  // toasterConfig = {
  //     position: "bottom-right",
  //     autoClose: 3000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "colored"
  // }

  notify(type, message, isUndo, undoFunc) {
    toast.custom((t) => (
      <div className="toast-wrapper">
        <div className="message-container">
          {type === "info" && <InfoIcon />}
          {type === "invite" && <UsersIcon />}
          {type === "delete" && <DeleteIcon />}
          {type === "login" && <LogIn />}
          {type === "logout" && <LogOut />}
          {type === "move" && <Repeat />}
          {type === "update" && <Bell />}
          <span>{message}</span>
        </div>
        <div className="btn-container">
           {isUndo && (
            <button className="undo-btn" onClick={() => {
                undoFunc()
                toast.dismiss(t)
              }}>
              Undo
              <div>
                <Command /> <span>Z</span>
              </div>
            </button>
          )}
          <button onClick={() => toast.dismiss(t)}>
            <CloseIcon />
          </button>
        </div>
      </div>
    ));
    // switch (type) {
    //     case 'success':
    //         toast.success(message);
    //         break;
    //     case 'info':
    //         toast(message);
    //         break;
    //     case 'error':
    //         toast.error(message);
    //         break;
    //     case 'warning':
    //         toast(message);
    //         break;
    //     default:
    //         break;
    // }
  }
}

export default new NotifierHelper();
