import asset_loader from "./../../../assets/images/icons/loader.svg";

export default function Modal({
  isOpen,
  onClose,
  cancelText = "Cancel",
  submitText,
  isLoading,
  onCancel,
  onSubmit,
  children,
  submitButtonVariant = "green-button",
  showButton = true,
  size,
  className = "",
}) {
  const onCancelClick = () => {
    onCancel?.();
    onClose();
  };

  return (
    <div
      className={`mikyal-modal modal fade ${isOpen ? "show" : "hide"} ${className}`}
      style={{ display: isOpen ? "block" : "none" }}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className={`modal-dialog modal-dialog-centered ${size}`}>
        <div
          className="modal-content d-flex flex-column p-4"
          style={{ gap: 8 }}
        >
          {children}

          {/* Buttons */}
          {/* pt-3  */}
          {showButton && (
            <div className="row mt-3">
              <div className="d-flex align-items-center justify-content-end">
                {isLoading && (
                  <div className="section-loader d-inline-flex me-3">
                    <img src={asset_loader} alt="Loading" />
                  </div>
                )}

                <button
                  className="mikyal-btn secondary-button me-3"
                  onClick={() => onCancelClick()}
                  disabled={isLoading}
                >
                  {cancelText}
                </button>
                {submitText && (
                  <button
                    className={`mikyal-btn position-relative ${submitButtonVariant}`}
                    disabled={isLoading}
                    onClick={onSubmit}
                  >
                    {submitText}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
