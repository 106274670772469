import "./workflow.scss";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GroupContainer from "./groupContainer";
import { updateWorkFlowStatus } from "../../../store/reducers/dropDownSlice";

export default function WorkFlow() {
  const workflowStatutes = useSelector(
    (state) => state.dropdown.workflowStatus
  );
  const dispatch = useDispatch();

  // Get status from group name and adding isEditing flag.
  const getStatus = (groupName) => {
    return workflowStatutes
      ?.find((group) => group.name.toLowerCase() === groupName)
      ?.status?.map((stat) => ({
        ...stat,
        isEditing: false,
        isLoading: false,
      }));
  };

  const [backlogStatus, setBacklogStatus] = useState({
    backlog: getStatus("backlog") || [],
    todo: getStatus("to do") || [],
    inprogress: getStatus("in progress") || [],
    completed: getStatus("completed") || [],
  });

  const updateReduxWorkflow = (updatedWorkflows) => {
    const updatedWorkflow = workflowStatutes.map((workflow) => {
      return {
        ...workflow,
        status: updatedWorkflows[workflow.name.toLowerCase().replaceAll(" ", "")]
      }
    });
    dispatch(updateWorkFlowStatus(updatedWorkflow))
  };

  return (
    <div className="workflow-wrapper">
      {workflowStatutes.slice().sort((a, b) => a.position - b.position).map((group) => (
        <GroupContainer
          key={group.id}
          groupId={group.id}
          groupName={group.name}
          statuses={backlogStatus[group.name.toLowerCase().replaceAll(" ", "")]}
          updateStatus={setBacklogStatus}
          updateReduxWorkflow={updateReduxWorkflow}
        />
      ))}
    </div>
  );
}
