import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import NotifierHelper from "../../../application/common/NotifierHelper";
import InvitesApi from "../../../infrastructure/ApiServices/InvitesApi";

import asset_trash from "./../../../assets/images/icons/trash-red.svg";
// import asset_link from './../../assets/images/icons/link.svg';
import asset_check from "./../../../assets/images/icons/circle-check.svg";
import asset_loader from "./../../../assets/images/icons/loader.svg";

import "./invite-modal.scss";
import { useSelector } from "react-redux";
import supabase from "../../../infrastructure/Supabase";
import { SUPABASE_TABLE_NAME } from "../../../infrastructure/Supabase/constants";

const InviteModal = (props) => {
  const userInfo = useSelector((state) => state.user.userInfo);

  const { data, modal, setModal, onSaveSuccess } = props;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // const { register, handleSubmit, formState: { isValid } } = useForm({ mode: "onChange" });

  const initialInvite = { email: "" };
  const [invite, setInvite] = useState(initialInvite);
  // const [backEndErrors, setBackEndErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // const handleChange = e => {
  //     console.log('e.target', e.target);
  //     const { name, value } = e.target
  //     setClient({
  //         ...client,
  //         [name]: value
  //     });
  // };

  const handleNewInvite = async (data) => {
    setIsLoading(true);
    const { data: profiles, error: profileError } = await supabase
      .from(SUPABASE_TABLE_NAME.INVITES)
      .select("*")
      .eq("email", data.email);

    if (profileError) {
      NotifierHelper.notify("info", profileError.message);
      setModal("");
      setIsLoading(false);
      return;
    }

    if (profiles.find((profile) => profile.invited_to_app === true)) {
      setIsLoading(false);
      NotifierHelper.notify("info", "This user is already invited");
      return;
    }

    const userId = profiles.length ? profiles[0].user_id : null;

    const { data: invite, error } = await supabase
      .from(SUPABASE_TABLE_NAME.INVITES)
      .insert({
        email: data.email,
        user_id: userId,
        invited_to_app: true,
        invited_by: userInfo.id,
        role: "owner",
      })
      .select()
      .single();

    setModal("");
    setIsLoading(false);

    if (error) {
      NotifierHelper.notify("info", error.message);
      return;
    }

    NotifierHelper.notify("invite", "User is invited");
    onSaveSuccess(invite);
  };

  useEffect(() => {
    if (data?.type === "update" || data?.type === "preset") {
      setInvite(data);
    } else {
      setInvite(initialInvite);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // reset form with user data
    reset(invite);
  }, [invite]);

  return (
    <div
      className={"mikyal-modal modal fade" + (modal ? "show" : "hide")}
      style={{ display: modal ? "block" : "none" }}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered client-modal">
        <div className="modal-content p-5 pb-3">
          <div className="client-wrapper">
            <div className="col-12">
              <div className="meta-container">
                <h1 className="title font-weight-bold fs-h3">
                  {data?.type === "update" ? "Update Invite" : "Invite User"}
                </h1>
              </div>
              <div className="client-content">
                <form onSubmit={handleSubmit(handleNewInvite)}>
                  <div className=" row mt-4">
                    <div className="col-md-12 mb-3">
                      <div className="mikyal-field-header">
                        <label className="form-field-label">
                          {" "}
                          Email Address{" "}
                        </label>
                        <div>
                          {errors.email && (
                            <small className="text-danger">
                              Field is required
                            </small>
                          )}
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control mikyal-field"
                        placeholder="user@email.com"
                        name="email"
                        disabled={data?.type === "update"}
                        {...register("email", { required: true })}
                      />
                    </div>
                  </div>

                  <div className="row pt-3">
                    <div className="col-12 d-flex justify-content-center">
                      <button
                        className="mikyal-btn red-button me-3"
                        onClick={() => setModal("")}
                      >
                        <span className="icon">
                          <img src={asset_trash} alt="" />
                        </span>
                        Discard
                      </button>
                      <button
                        className="mikyal-btn green-button"
                        disabled={isLoading}
                      >
                        <span className="icon">
                          <img src={asset_check} alt="" />
                        </span>
                        {data?.type === "update" ? "Update" : "Add"}
                      </button>
                    </div>
                    <div className="request-loader-wrapper">
                      {isLoading && (
                        <div className="request-loader text-center">
                          <img
                            src={asset_loader}
                            alt="loader"
                            width="25"
                            height="25"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteModal;
