import { GiphyFetch } from "@giphy/js-fetch-api";
import { Grid } from "@giphy/react-components";
import Modal from ".";

const giphyFetch = new GiphyFetch(process.env.REACT_APP_GIPHY_API);

export default function GIFModal({ onSelect, onClose, isOpen }) {
  const fetchGifs = (offset) => giphyFetch.trending({ offset, limit: 10 });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      cancelText="Cancel"
      onCancel={onClose}
      className="vh-100"
    >
      <div className="overflow-auto" style={{
        // Removing margin, padding and menu button height to fit in screen
        height: `calc(100vh - 6.5rem - 40px - 1rem)`
      }}>
        <Grid
          onGifClick={(gif, e) => {
            e.preventDefault();
            onSelect(gif.images.original.webp, gif.title);
          }}
          fetchGifs={fetchGifs}
          width={500}
          columns={3}
          gutter={6}
        />
      </div>
    </Modal>
  );
}
