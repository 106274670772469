import { useSelector } from "react-redux";
import supabase from "../../Supabase";
import { SUPABASE_TABLE_NAME } from "../../Supabase/constants";
import { useEffect, useRef } from "react";

export default function TaskCommentListener({
  updateComment,
  taskId,
  comments,
}) {
  const workSpaceInfo = useSelector((state) => state.user.workSpaceInfo);
  const tasks = useSelector((state) => state.project.tasks);
  const members = useSelector((state) => state.members.workspace);
  const commentListenerRef = useRef();
  const replyListenerRef = useRef();

  useEffect(() => {
    return () => {
      supabase.removeChannel(commentListenerRef.current);
      supabase.removeChannel(replyListenerRef.current);
    };
  }, []);

  if (Object.keys(workSpaceInfo).length) {
    // Listen on comments
    commentListenerRef.current = supabase
      .channel("comment-for-task")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: SUPABASE_TABLE_NAME.COMMENTS,
          filter: `task_id=eq.${taskId}`,
        },
        (payload) => {
          if (payload.eventType === "INSERT") {
            const newComment = payload.new;
            // Check if comment already there.
            if (
              comments &&
              comments.find((comment) => comment.id === newComment.id)
            ) {
              return;
            }
            const task = tasks.find((t) => t.id === newComment.task_id);
            const commentBy = members.find(
              (member) => member.user_id === newComment.created_by
            );
            const commentObj = {
              ...newComment,
              user: {
                id: commentBy.user_id,
                name: commentBy.username,
                avatar: commentBy.avatar,
              },
              task: {
                id: task.id,
                name: task.title,
                status: task.status,
                project_id: task.project_id,
              },
              createdAt: newComment.created_at,
              replies: [],
              reply_count: 0,
            };
            updateComment((prev) =>
              prev ? [commentObj, ...prev] : [commentObj]
            );
          }

          if (payload.eventType === "DELETE") {
            updateComment((prev) =>
              prev.filter((comment) => comment.id !== payload.old)
            );
          }
        }
      )
      .subscribe();

    // Listen on replies
    replyListenerRef.current = supabase
      .channel("reply-for-task")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: SUPABASE_TABLE_NAME.REPLIES,
          filter: `workspace_id=eq.${workSpaceInfo.id}`,
        },
        async (payload) => {
          if (payload.eventType === "INSERT") {
            const newReply = payload.new;
            const comment = comments.find(
              (comment) => comment.id === newReply.comment_id
            );
            // Check if comment not present in array
            if (!comment) {
              return;
            }

            const commentBy = members.find(
              (member) => member.user_id === newReply.created_by
            );
            const replyObj = {
              ...newReply,
              user: {
                id: commentBy.user_id,
                name: commentBy.username,
                avatar: commentBy.avatar,
              },
              createdAt: newReply.created_at,
            };
            const newComments = comments.map((comment) => {
              if (comment.id === replyObj.comment_id) {
                return {
                  ...comment,
                  reply_count: (comment?.reply_count || 0) + 1,
                  replies:
                    comment?.replies && comment?.replies?.length > 0
                      ? [replyObj, ...comment.replies]
                      : [replyObj],
                };
              }
              return comment;
            });
            updateComment(newComments);
          }
        }
      )
      .subscribe();
  }

  return null;
}
