import axios from "axios";
import Globals from "../../application/common/Globals";
import NotifierHelper from "../../application/common/NotifierHelper";
import supabase from "../Supabase";
import { SUPABASE_TABLE_NAME } from "../Supabase/constants";

const SendEmailToUser = async (email, workspaceName, url) => {
  await axios("https://api-v1.lata.so/api/v1/invites/send-email", {
    method: "POST",
    data: {
      email: email,
      workspace: workspaceName,
      invite_url: url,
    },
    headers: {
      Cookie:
        "__cf_bm=_sBurPnzoIAayO2paAeXrV2LcBUXVkfZRH.U0x0NOtg-1682435779-0-AYwliLGFYT5X5dIIS6VRijHFdJIbvzRKkvrdtogBSsW3q6MHLYiZqeYVPmPWlPSlQKlaIwiaWTHKZNKYRIIe1io=",
    },
  });
};

export const createInvite = async (
  email,
  role = "member",
  workSpaceInfo,
  invitedBy
) => {
  let emailLink;
  // First check if user already exists in our application
  const { data: existingUser } = await supabase
    .from(SUPABASE_TABLE_NAME.PROFILES)
    .select()
    .eq("email", email)
    .single();

  const { data: invitesData } = await supabase
    .from(SUPABASE_TABLE_NAME.INVITES)
    .select()
    .eq("email", email);

  if (
    (existingUser && existingUser?.workspace_id !== null) ||
    invitesData.length
  ) {
    NotifierHelper.notify(
      "info",
      "This user already invited/part of other workspace please contact support team for more details."
    );
    return;
  }

  // Add invited member to invite table
  const { data: invitedUser, error } = await supabase
    .from(SUPABASE_TABLE_NAME.INVITES)
    .insert({
      user_id: existingUser ? existingUser.user_id : null,
      workspace_id: workSpaceInfo?.id,
      email: email,
      role,
      invited_by: invitedBy,
    })
    .select()
    .single();

  if (error) {
    NotifierHelper.notify("info", error.message);
    return { data: null, error };
  }

  // user exists
  if (role === "member") {
    emailLink = `${window.location.origin}/join-workspace?inviteId=${invitedUser.id}`;
  } else {
    emailLink = `${window.location.origin}/register?inviteId=${invitedUser.id}`;
  }
  
  SendEmailToUser(email, workSpaceInfo.title, emailLink);

  NotifierHelper.notify("invite", `Invite sent to ${email}`);

  return { data: invitedUser, error: null };
};
class InvitesService {
  addEditInvite(type, data) {
    return axios.post(
      type !== "update"
        ? Globals.newInvite
        : Globals.updateInvite + `/${data.id}`,
      data,
      Globals.httpOptions
    );
  }

  getInvites(params = null) {
    const queryString = "?" + new URLSearchParams(params).toString();
    return axios.get(Globals.invites + queryString, Globals.httpOptions);
  }

  // getInvite(inviteId) {
  //     return axios.get(Globals.invites + `/${inviteId}`, Globals.httpOptions);
  // }

  deleteInvite(dataId) {
    return axios.delete(Globals.invites + "/" + dataId, Globals.httpOptions);
  }
}

export default new InvitesService();
