import asset_logo from "./../../assets/images/icons/logo_badge.svg";

export default function WorkspaceLoadingScreen() {
  return (
    <div className="vh-100 d-flex flex-column justify-content-start align-items-center">
      <div className="auth-wrapper" style={{ width: "fit-content" }}>
        <div className="auth-content">
          <div className="top-logo mb-5 pb-3">
            <img className="mikyal-logo" src={asset_logo} alt="Mikyal-Logo" />
          </div>
          <div className="meta-container pb-1">
            <h1 className="title fw-thick fs-h3 text-start">
              Good to see you 👋
            </h1>
          </div>

          <div className="text-center dim-mlp">
            Loading Your Workspace...
          </div>
        </div>
      </div>
    </div>
  );
}
