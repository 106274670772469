import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils";
import {
  $createParagraphNode,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from "lexical";
import { useEffect, useState } from "react";
import * as React from "react";

import asset_loader from "../../../../assets/images/icons/loader.svg";
import supabase from "../../../Supabase";
import { SUPABASE_BUCKET_NAME } from "../../../Supabase/constants";
import NotifierHelper from "../../../../application/common/NotifierHelper";
import { customAlphabet } from "nanoid";
import { $createFileNode, FileNode } from "../nodes/FileNode";

export const INSERT_FILE_COMMAND = createCommand("INSERT_FILE_COMMAND");

export function InsertFileDialog({ activeEditor, onClose }) {
  const [altText, setAltText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const uploadFileToStorage = async () => {
    setIsLoading(true);
    const nanoid = customAlphabet("abcdefghijklmnopqrstuvwxyz1234567890", 10);
    // Upload file to storage
    const { data, error } = await supabase.storage
      .from(SUPABASE_BUCKET_NAME.TASK_IMAGES)
      .upload(
        `${nanoid(7)}-${selectedFile.name.replaceAll(" ", "_")}`,
        selectedFile,
        {
          cacheControl: "3600",
          upsert: true,
        }
      );

    if (error) {
      onClose();
      NotifierHelper.notify("info", error.message);
      return;
    }

    // Get downloadURL from storage for same file
    const { data: downloadURL } = supabase.storage
      .from(SUPABASE_BUCKET_NAME.TASK_IMAGES)
      .getPublicUrl(data.path);

    setIsLoading(false);
    activeEditor.dispatchCommand(INSERT_FILE_COMMAND, {
      altText,
      src: downloadURL?.publicUrl,
    });
    onClose();
  };

  return (
    <div className="d-flex flex-column gap-3">
      <input
        label="Image Upload"
        onChange={(e) => setSelectedFile(e.target.files[0])}
        type="file"
        className="d-block"
        accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
        data-test-id="video-modal-file-upload"
      />
      <input
        label="Alt Text"
        placeholder="Descriptive alternative text"
        onChange={(e) => setAltText(e.target.value)}
        value={altText}
        className="form-control mikyal-field"
        data-test-id="video-modal-alt-text-input"
      />
      <div className="d-flex flex-row justify-content-end gap-2">
        <button
          className="mikyal-btn secondary-button"
          data-test-id="video-modal-file-upload-btn"
          disabled={isLoading}
          onClick={() => onClose()}
        >
          Cancel
        </button>
        <button
          className="mikyal-btn black-button"
          data-test-id="video-modal-file-upload-btn"
          disabled={selectedFile === null}
          onClick={() => uploadFileToStorage()}
        >
          {isLoading ? (
            <div className="LataEditor__button-loader d-inline-flex">
              <img src={asset_loader} alt="Loading" />
            </div>
          ) : (
            "Confirm"
          )}
        </button>
      </div>
    </div>
  );
}

export default function FilePlugin() {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (!editor.hasNodes([FileNode])) {
      throw new Error("FilePlugin: FileNode not registered on editor");
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_FILE_COMMAND,
        (payload) => {
          const fileNode = $createFileNode(payload);
          $insertNodes([fileNode]);
          if ($isRootOrShadowRoot(fileNode.getParentOrThrow())) {
            $wrapNodeInElement(fileNode, $createParagraphNode).selectEnd();
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);
  return null;
}
