import { createSlice } from "@reduxjs/toolkit";

export const members = createSlice({
  name: "members",
  initialState: {
    workspace: [],
    invited: [],
    invitedToApp: [],
  },
  reducers: {
    setInvites: (state, action) => {
      state.invited = action.payload;
    },
    setWorkspaceMembers: (state, action) => {
      state.workspace = action.payload;
    },
    setInvitedToApp: (state, action) => {
      state.invitedToApp = action.payload;
    },
    resetMemberState: (state) => {
      state.workspace = [];
      state.invited = []
      state.invitedToApp = []
    }
  },
});

export const { setInvites, setWorkspaceMembers, setInvitedToApp, resetMemberState } =
  members.actions;

export default members.reducer;
