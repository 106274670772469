import { createSlice } from "@reduxjs/toolkit";

export const commentSlice = createSlice({
  name: "session",
  initialState: {
    comments: [],
    activeCommentId: "",
    limit: 10,
    offset: 0,
  },
  reducers: {
    setComments: (state, action) => {
      const newComments = Array.isArray(action.payload) ? action.payload : [];
      state.comments = [...state.comments, ...newComments];
      state.offset = state.offset + newComments.length;
    },
    addNewComment: (state, action) => {
      state.comments = [action?.payload, ...state?.comments];
    },
    addNewReplyToComment: (state, action) => {
      const newReply = action.payload;
      const comments = state.comments.map((notification) => {
        if (notification.id === newReply.comment_id) {
          return {
            ...notification,
            reply_count: (notification?.reply_count || 0) + 1,
            replies: notification?.replies && notification?.replies?.length > 0
              ? [newReply, ...notification.replies]
              : [newReply],
              // seen_user_ids: [newReply.created_by]
          };
        }
        return notification;
      });

      state.comments = comments;
    },
    deleteComment: (state, action) => {
      const comments = state.comments.filter(
        (noti) => noti.id !== action.payload
      );
      state.comments = comments;
    },
    updateComment: (state, action) => {
      const newComment = action.payload;
      const updateComments = state.comments.map(comment => {
        if(comment.id === newComment.id) {
          return newComment
        }
        return comment;
      })

      state.comments = updateComments;
    },
    addRepliesToComment: (state, action) => {
      const commentId = action.payload.commentId;
      const newReplies = action.payload.replies;
      const comments = state.comments.map(comment => {
        if(comment.id === commentId) {
          return {...comment, replies: newReplies }
        }
        return comment
      })

      state.comments = comments;
    },
    setActiveCommentId: (state, action) => {
      state.activeCommentId = action.payload;
    },
    resetCommentState: (state) => {
      state.comments = [];
      state.activeCommentId = "";
      state.limit = 10;
      state.offset = 0;
    },
  },
});

export const {
  setComments,
  setActiveCommentId,
  resetCommentState,
  addNewComment,
  addNewReplyToComment,
  deleteComment,
  updateComment,
  addRepliesToComment
} = commentSlice.actions;

export default commentSlice.reducer;
