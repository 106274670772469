import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { cloneDeep, omit, orderBy, toInteger } from "lodash";
import { useMenuState } from "@szhsin/react-menu";
import Select, { components } from "react-select";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// import { isToday, isYesterday, format as formatDate } from "date-fns";

import { GlobalHotKeys } from "react-hotkeys";

import NotifierHelper from "../../../application/common/NotifierHelper";

import { ReactComponent as LeftArrow } from "./../../../assets/images/icons/arrow-left-tail.svg";
import { ReactComponent as AssetDot } from "./../../../assets/images/icons/three-dots.svg";
import avatar_placeholder from "./../../../assets/images/icons/logo.svg";
import { ReactComponent as ArrowUp } from "./../../../assets/images/icons-mlp/arrow-up.svg";
import { ReactComponent as ArrowDown } from "./../../../assets/images/icons-mlp/arrow-down.svg";
import { ReactComponent as AssetDismiss } from "./../../../assets/images/icons-mlp/x-dismiss.svg";
import { ReactComponent as Trash } from "./../../../assets/images/icons-mlp/trash.svg";
import { ReactComponent as CalendarDue } from "./../../../assets/images/icons-mlp/calender-due.svg";
import { ReactComponent as Assignee } from "./../../../assets/images/icons-mlp/assignee.svg";
import { ReactComponent as Dash } from "./../../../assets/images/icons-mlp/dash.svg";

import "./task-view.scss";
import DeleteConfirmation from "../DeleteConfirmation";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAddTask,
  setInputValue,
  handleDeleteTask as removeTaskFromRedux,
  handleUpdateTask,
} from "../../../store/reducers/projectSlice";
import { Emoji, EmojiStyle } from "emoji-picker-react";
import AnalyticsHelper from "../../../application/common/AnalyticsHelper";
import Loader from "../MasterLoader";
import supabase from "../../Supabase";
import { SUPABASE_RPC, SUPABASE_TABLE_NAME } from "../../Supabase/constants";
import CreateWorkspaceModal from "../Modal/CreateWorkspaceModal";
import Editor from "../Editor";
// import { StatusIcon } from "../../../application/common/helper";
import { format } from "date-fns";
import UpdatePill from "./UpdatePill";
import TaskComments from "./TaskComments";
import TaskCommentListener from "./TaskCommentListener";
import { Mention, MentionsInput } from "react-mentions";

const onEscape = function (action) {
  window &&
    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        action();
      }
    });
};

const CustomOption = ({ children, selectProps, ...props }) => {
  const dispatch = useDispatch();
  return (
    <components.Option
      {...props}
      className={`${
        props.isSelected
          ? "d-flex align-items-center justify-content-between flex-row"
          : ""
      }`}
    >
      {children}
      {props.isSelected && (
        <AssetDismiss
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(
              handleUpdateTask({ ...selectProps.milestone, assignee: null })
            );
          }}
        />
      )}
    </components.Option>
  );
};

const CustomValue = ({ children, data, ...props }) => {
  return (
    <components.SingleValue {...props} className={"mx-0"}>
      <img
        src={data.avatar || avatar_placeholder}
        className="user-dp"
        alt="User Profile"
      />
    </components.SingleValue>
  );
};

const CustomPlaceholder = ({ ...props }) => {
  return <components.Placeholder {...props} className="mx-0" />;
};

const ProjectPlaceHolder = ({ children, ...props }) => {
  return (
    <components.Placeholder
      {...props}
      className="project_placeholder text-truncate mx-0 d-flex flex-row gap-2 align-items-center"
    >
      {/* <Plus /> */}
      {children}
    </components.Placeholder>
  );
};

const TaskView = () => {
  let { sessionId, projectId } = useParams();

  const userInfo = useSelector((state) => state.user.userInfo);
  const workSpaceInfo = useSelector((state) => state.user.workSpaceInfo);
  const projects = useSelector((state) => state.project.projects);
  const tasks = useSelector((state) => state.project.tasks);
  const workspaceMembers = useSelector((state) => state.members.workspace);
  const inputValue = useSelector((state) => state.project.inputValue);
  const workflowStatutes = useSelector(
    (state) => state.dropdown.workflowStatus
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { state } = useLocation();

  const [currentProject, setCurrentProject] = useState({});
  const milestone = tasks?.find((task) => task.id === sessionId) ?? {};
  const [taskControl, setTaskControl] = useState(-1);
  const [trigger, setTrigger] = useState({ item: -1, count: 0 });
  const [clearDelay, setClearDelay] = useState();
  const [openDueDate, setOpenDueDate] = useState(false);
  const [modal, setModal] = useState("");
  const [modalData, setModalData] = useState([]);
  const [workspaceModalOpen, setWorkspaceModalOpen] = useState(false);
  const [fromURL, setFromURL] = useState("");
  const [comments, setComments] = useState(null);

  const fetchCommentsForTask = async () => {
    try {
      const { data } = await supabase.rpc(SUPABASE_RPC.GET_COMMENTS_BY_TASK, {
        taskid: sessionId,
        workspaceid: workSpaceInfo.id,
      });

      if (data) {
        setComments(orderBy(data, "createdAt", "desc"));
      }
    } catch (e) {
      setComments([]);
    }
  };

  useEffect(() => {
    if (comments === null && sessionId !== "news") fetchCommentsForTask();
  }, [comments, sessionId]);

  useEffect(() => {
    if (state?.from) setFromURL(state?.from);
  }, [state]);

  // const deafultTaskStatus = { label: "to do", value: "To Do" };
  const NO_PROJECT = { label: "No Project Assigned", value: "no-project" };

  const statusRef = useRef(null);
  const assigneeRef = useRef(null);
  const milestoneRef = useRef(milestone);
  const descriptionRef = useRef(null);
  const projectRef = useRef(null);
  milestoneRef.current = milestone;

  const [menuState, toggleMenu] = useMenuState({ transition: true });
  // const anchorProps = useClick(menuState.state, toggleMenu);

  onEscape(() => {
    statusRef.current && statusRef.current.blur();
    assigneeRef.current && assigneeRef.current.blur();
  });

  const handleBack = () => {
    if (fromURL) navigate(fromURL);
    else if (projectId) navigate(`/tags/${projectId}`);
    else navigate(-1);
  };

  const keyMap = {
    // controlDown: "down",
    // controlUp: "up",
    tabControl: "tab",
    // timerShortcut: 't',
    combination: "cmd+h",
    checkUncheck: "c",
    statusHandler: "s",
    assigneeHandler: "a",
    setDueDate: "d",
    // completeSession: "shift+e",
    goBack: "esc",
    // gotoAssigned: "g+a",
    // newTask: "enter",
    enterNotes: "/",
    previousTask: "k",
    nextTask: "j",
  };

  const keyHandlers = {
    controlUp: (e) => {
      handleTaskControl(e, "up");
      e.preventDefault();
      return false;
    },

    controlDown: (e) => {
      handleTaskControl(e, "down");
      e.preventDefault();
      return false;
    },

    tabControl: (e) => {
      handleTaskControl(e, "down");
      e.preventDefault();
      return false;
    },

    setDueDate: (e) => {
      setOpenDueDate(true);
      e.preventDefault();
      return false;
    },

    statusHandler: (e) => {
      toggleMenu(true);
      // statusRef.current.focus();
      e.preventDefault();
      return false;
    },

    assigneeHandler: (e) => {
      assigneeRef.current.focus();
      e.preventDefault();
      return false;
    },

    checkUncheck: (e) => {
      handleCheckUncheck(e);
      e.preventDefault();
      return false;
    },

    goBack: (e) => {
      e.preventDefault();
      if (menuState.state && menuState.state !== "closed") return;
      handleBack();
      return false;
    },

    newTask: (e) => {
      handleTask("add");
      e.preventDefault();
      return false;
    },

    enterNotes: (e) => {
      document.querySelector("#lata_editor").focus();
      e.preventDefault();
      return false;
    },

    previousTask: (e) => {
      movePreviousOrNext("previous");
      e.preventDefault();
      return false;
    },

    nextTask: (e) => {
      movePreviousOrNext("next");
      e.preventDefault();
      return false;
    },
  };

  useEffect(() => {
    if (projects || projectId) {
      const project = projects?.find((e) => e?.id === projectId);
      setCurrentProject(project);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (projectId && projects?.length > 0) {
      const project = projects?.find((e) => e?.id === projectId);
      setCurrentProject({ label: project?.title, value: project?.id });
    }
  }, [projectId, projects]);

  useEffect(() => {
    if (sessionId === "new") {
      setTimeout(() => {
        newSession();
      });
    } else {
      if (milestone && milestone?.project_id) {
        const project = projects?.find((e) => e?.id === milestone.project_id);
        setCurrentProject(project);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    // debugger;
    if (Object.keys(milestone)?.length !== 0) {
      syncSessions(milestone, currentProject, false);
      clearTimeout(clearDelay);
      setClearDelay(
        setTimeout(() => {
          onSaveSession({ ...milestone });
        }, 1000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestone]);

  useEffect(
    () => () => {
      syncSessions(milestone, currentProject, true);
    },
    []
  );

  const newSession = async () => {
    if (Object.keys(workSpaceInfo).length === 0) {
      setTimeout(() => navigate("/no-workspace"));
      return;
    }

    const data = {
      project_id: projectId || null,
      title: inputValue,
      created_by: userInfo.id,
      workspace_id: workSpaceInfo.id,
      status: "to do",
      status_id: workflowStatutes
        ?.find((workflow) => workflow.name === "To Do")
        ?.status?.find((stat) => stat.name === "To Do").id,
    };

    const { data: taskData, error } = await supabase
      .from(SUPABASE_TABLE_NAME.TASK)
      .insert(data)
      .select()
      .limit(1)
      .single();

    if (error) {
      if (projectId) {
        navigate(`/tags/${projectId}`);
      } else {
        navigate(`/home`);
      }
      NotifierHelper.notify("info", error.message);
      return;
    }

    if (inputValue !== "") {
      dispatch(setInputValue(""));
    }

    const clonedTask = cloneDeep(taskData);
    const temp = { ...milestoneRef.current };
    const updatedTask = {
      ...clonedTask,
      ...temp,
    };

    AnalyticsHelper.trackEvent("Task Created", {
      taskId: clonedTask.id,
      title: clonedTask.title,
    });
    // AnalyticsHelper.smartLookTrackEvent("Task Created", {
    //   taskId: clonedTask.id,
    //   title: clonedTask.title,
    // });

    dispatch(handleAddTask(updatedTask));
    if (window.location.pathname.includes("/new")) {
      descriptionRef?.current?.focus();
      descriptionRef?.current?.setSelectionRange(
        descriptionRef?.current?.value?.length,
        descriptionRef?.current?.value?.length
      );
      if (projectId) {
        navigate(`/tags/${projectId}/canvases/${updatedTask.id}`);
      } else {
        navigate(`/canvases/${updatedTask.id}`);
      }
    }
  };

  const onSaveSession = async (newSession) => {
    if (!newSession.id) {
      return;
    }
    const session = omit(newSession, "index");
    const { error } = await supabase
      .from(SUPABASE_TABLE_NAME.TASK)
      .update(session)
      .eq("id", session.id);

    if (error) {
      NotifierHelper.notify("info", error.message);
    }
  };

  const syncSessions = (milestone, selectedProject, isUnmounted) => {
    const milestoneDetail = Object.keys(milestone).length
      ? milestone
      : milestoneRef?.current;
    // Update the milestone with current selected project
    let updatedMilestone = {
      ...milestoneDetail,
      ...(selectedProject &&
        Object.keys(selectedProject).length && {
          projectDetail: {
            title: selectedProject?.label,
          },
        }),
    };

    if (
      !updatedMilestone?.title &&
      !updatedMilestone?.tasks?.length &&
      isUnmounted
    ) {
      dispatch(removeTaskFromRedux(milestoneRef?.current ?? updatedMilestone));
    }
  };

  const movePreviousOrNext = (direction) => {
    let workLists = tasks;

    if (projectId && !fromURL.includes("home")) {
      // In this case traverse through task in same project
      const taskFromProject = tasks.filter(
        (task) => task.project_id === projectId
      );
      workLists = taskFromProject;
    }

    const currentTaskIndex = workLists.findIndex(
      (tasks) => tasks.id === milestone.id
    );

    // If current task index is 0 and previous is pressed then we reached to first place of array
    if (direction === "previous") {
      const previousTask =
        currentTaskIndex === 0
          ? workLists[workLists.length - 1]
          : workLists[currentTaskIndex - 1];
      const url = `${projectId ? "/tags/" + projectId : ""}/canvases/${
        previousTask.id
      }`;
      navigate(url, { replace: true });
    }

    if (direction === "next") {
      const nextTask =
        workLists.length === currentTaskIndex + 1
          ? workLists[0]
          : workLists[currentTaskIndex + 1];
      const url = `${projectId ? "/tags/" + projectId : ""}/canvases/${
        nextTask.id
      }`;
      navigate(url, { replace: true });
    }

    if (workLists.length > 1) setComments(null);
  };

  const handleTimerShortcut = (e, action) => {
    let activeTask = milestone.tasks[taskControl];
    activeTask.id = activeTask._id ? activeTask._id : activeTask.identityCode;
    setTrigger({ item: taskControl, count: trigger.count + 1 });
  };

  const handleCheckUncheck = (e) => {
    if (taskControl < 0) return;
    let temp = { ...milestone };
    temp.tasks[taskControl].isCompleted = !temp.tasks[taskControl].isCompleted;
    calcMilestoneProgress(temp);
    dispatch(handleUpdateTask(temp));
    const taskCheckbox = document.querySelector("#task_" + taskControl);
    taskCheckbox.checked = !taskCheckbox.checked;
  };

  const handleChange = (
    e,
    item = null,
    msIndx = null,
    tIndex = null,
    stIndex = null
  ) => {
    const { name, value, checked } = e.target;
    if (item === "task-checkbox") {
      let temp = { ...milestone };
      temp.tasks[tIndex].isCompleted = checked;
      calcMilestoneProgress(temp);
      dispatch(handleUpdateTask(temp));
    } else if (item === "task") {
      let temp = { ...milestone };
      temp.tasks[tIndex][name] = value;
      dispatch(handleUpdateTask(temp));
    } else {
      console.log("Line----519 index.js", value);
      console.log("Line----520 index.js", name);
      dispatch(handleUpdateTask({ ...milestone, [name]: value }));
    }
  };

  const focusOutAllSelect = () => {
    statusRef.current && statusRef.current.blur();
    assigneeRef.current && assigneeRef.current.blur();
    projectRef.current && projectRef.current.blur();
  };

  const handleSelect = (data, type) => {
    let updatedTask = cloneDeep(milestone);

    if (type === "project_id" && projects?.length) {
      if (data?.label === NO_PROJECT?.label) {
        setCurrentProject(data);
        navigate(`/canvases/${sessionId}`);
      } else {
        setCurrentProject(data);
        navigate(`/tags/${data?.value}/canvases/${sessionId}`);
      }

      NotifierHelper.notify("move", `Task moved to ${data?.label}`);
    }

    updatedTask = {
      ...updatedTask,
      [type]: data?.label === NO_PROJECT?.label ? null : data.value,
      ...(type === "status" && {
        status_id: data.statusId,
      }),
    };
    // if (data?.label === NO_PROJECT?.label) {
    //   delete updatedTask[type];
    // }
    dispatch(handleUpdateTask(updatedTask));
    focusOutAllSelect();
  };

  const handleKeyPress = (
    e,
    item = null,
    msIndx = null,
    tIndex = null,
    stIndex = null,
    taskData = null
  ) => {
    if (e.key === "h" && e.metaKey) {
      e.preventDefault();
      if (taskControl > -1) {
        handleTimerShortcut(e, "timer");
      } else {
        NotifierHelper.notify("info", "Focus on task first");
      }
      return false;
    }
    if (item === "milestone" && e.key === "Enter") {
      if (
        milestone?.tasks?.length &&
        !milestone.tasks[milestone?.tasks?.length - 1].title
      ) {
        document
          .querySelector("#t_input_" + (milestone?.tasks?.length - 1))
          .focus();
      } else {
        handleTask("add");
      }
    }

    if (item === "task" && e.key === "Enter") {
      handleTask("add");
      setTaskControl(milestone?.tasks?.length - 1);
    }

    if (e.key === "Backspace" && !e.target.value) {
      e.preventDefault();
      if (item === "milestone") {
      } else if (item === "task") {
        handleTask("remove", tIndex);
        if (!milestone?.tasks?.length) {
          document.querySelector("#ms_input_title").focus();
        }
      }
    }

    if (item === "task" && e.key === "Tab") {
      if (tIndex >= milestone?.tasks?.length - 1) {
        handleTaskControl(e, "down");
      }
      setTaskControl(tIndex);
      document.querySelector("#t_input_" + tIndex).blur();
      e.preventDefault();
      return false;
    }

    if (item === "milestone" && e.key === "ArrowDown") {
      document.querySelector("#ms_input_title").blur();
      setTaskControl(0);
      e.preventDefault();
      return false;
    }

    if (item === "milestone" && e.key === "Tab") {
      document.querySelector("#t_input_0").focus();
      e.preventDefault();
      return false;
    }

    if (item === "task" && e.key === "Escape") {
      if (tIndex >= milestone?.tasks?.length - 1) {
        handleTaskControl(e, "down");
      }
      setTaskControl(tIndex);
      document.querySelector("#t_input_" + tIndex).blur();
      e.preventDefault();
      return false;
    }

    if (item === "milestone" && e.key === "Escape") {
      document.querySelector("#ms_input_title").blur();
      setTaskControl(0);
      e.preventDefault();
      return false;
    }

    if (item === "notes" && e.key === "Escape") {
      document.querySelector("#notes").blur();
      // setTaskControl(0);
      e.preventDefault();
      return false;
    }

    if (item === "title-focus") {
      setTaskControl(-1);
    }
  };

  const handleTaskControl = (e, action) => {
    if (action === "down") {
      if (taskControl < milestone?.tasks?.length - 1) {
        setTaskControl(taskControl + 1);
      } else {
        setTaskControl(0);
      }
    }

    if (action === "up") {
      if (taskControl > 0) {
        setTaskControl(taskControl - 1);
      } else {
        setTaskControl(milestone?.tasks?.length - 1);
      }
    }

    if (action === "hover" && e.target.id.includes("task_control_")) {
      var index = e.target.id.lastIndexOf("_");
      var result = e.target.id.substr(index + 1);
      if (taskControl !== toInteger(result)) {
        const elements = document.querySelectorAll('[id^="t_input"]');
        elements.forEach((el) => {
          el.blur();
        });
      }
      setTaskControl(toInteger(result));
      document.querySelector("#ms_input_title").blur();
    }
  };

  const deactiveControl = () => {
    setTaskControl(-1);
  };

  const handleTask = (action, tIndex = null, tValue = null) => {
    let temp = { ...milestone };
    if (action === "add") {
      temp.tasks.push({
        title: tValue ? tValue : "",
        description: "",
        dueDate: null,
        identityCode: Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, ""),
        isCompleted: false,
        focus: true,
        subTasks: [],
        timeIntervals: [],
      });
      setTaskControl(temp.tasks.length - 1);
    }
    if (action === "remove") {
      temp.tasks.splice(tIndex, 1);
      if (temp.tasks.length) {
        const previousTask = "#t_input_" + (tIndex - 1);
        setTaskControl(temp.tasks.length - 1);
        document.querySelector(previousTask).focus();
      }
    }
    calcMilestoneProgress(temp);
    dispatch(handleUpdateTask(temp));
  };

  const calcMilestoneProgress = (milestone) => {
    if (milestone.status === "in-progress") return 100;
    if (!milestone?.tasks?.length) return 0;
    const completed = milestone.tasks.filter((item) => item.isCompleted).length;
    return completed;
  };

  const handleDateChange = (date, type, indx = null, tIndex = null) => {
    const isoFormatDate = date ? new Date(date).toISOString() : null;
    switch (type) {
      case "start":
        dispatch(handleUpdateTask({ ...milestone, startDate: isoFormatDate }));
        break;
      case "due":
        dispatch(handleUpdateTask({ ...milestone, dueDate: isoFormatDate }));
        break;
      default:
        break;
    }
  };

  // const getTaskStatus = () => {
  //   if (milestone?.status && workflowStatutes.length) {
  //     // If status id is present then display custom status name
  //     if (milestone?.status_id) {
  //       const workflow = workflowStatutes.find((workflow) =>
  //         workflow.status.find((stat) => stat.id === milestone?.status_id)
  //       );
  //       const findStatus = workflowStatutes
  //         .find((workflow) =>
  //           workflow.status.find((stat) => stat.id === milestone?.status_id)
  //         )
  //         .status.find((stat) => stat.id === milestone?.status_id);
  //       return { label: workflow.name.toLowerCase(), value: findStatus.name };
  //     }
  //     // Otherwise show the default group name
  //     const workflow = workflowStatutes.find(
  //       (workflow) => workflow.name.toLowerCase() === milestone.status
  //     );
  //     return { label: workflow.name.toLowerCase(), value: workflow.name };
  //   } else return deafultTaskStatus;
  // };

  const handleDeleteTask = async (confirm, data) => {
    setModal("");

    dispatch(removeTaskFromRedux(data));

    NotifierHelper.notify("delete", `Task - ${data.title} removed`);

    AnalyticsHelper.trackEvent("Task Deleted", {
      taskId: data.id,
      title: data.title,
    });
    // AnalyticsHelper.smartLookTrackEvent("Task Deleted", {
    //   taskId: data.id,
    //   title: data.title,
    // });
    if (window.location.pathname.includes("/tags/")) {
      var url = window.location.pathname;
      var itemId = url.split("/")[2];
      navigate(`/tags/${itemId}`);
    } else {
      navigate("/home");
    }

    const { error } = await supabase
      .from(SUPABASE_TABLE_NAME.TASK)
      .update({ isDelete: true })
      .eq("id", data.id);

    if (error) {
      NotifierHelper.notify("info", error.message);
      return;
    }
    // undoChangeInterval = setInterval(async () => {
    //   const {error}  =  await supabase.from(SUPABASE_TABLE_NAME.TASK).delete().eq('id', data.id)

    //   clearInterval(undoChangeInterval)

    //   if(error) {
    //     NotifierHelper.notify('info', error.message);
    //     return
    //   }
    // }, 5000)
  };

  const handleModal = (name, item = {}, index = null) => {
    setModal(name);
    if (name === "delete-task") {
      setModalData(milestone);
    }
  };

  function formatAssigneeLabel({ label, avatar }) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          flex: 1,
        }}
      >
        <div className="img-icon me-2">
          <img
            src={avatar || avatar_placeholder}
            className="user-dp"
            alt="User Profile"
          />
        </div>
        <div className="assignee-label select-label">{label}</div>
      </div>
    );
  }

  // function formatStatusLabel({ value, label }) {
  //   return (
  //     <div style={{ display: "flex", alignItems: "center" }}>
  //       <div
  //         className="img-icon me-2"
  //         style={{
  //           cursor: "pointer",
  //         }}
  //       >
  //         {StatusIcon(label)}
  //       </div>
  //       <div className="select-label">{value}</div>
  //     </div>
  //   );
  // }

  return (
    <div className="min-vh-100">
      {tasks === null && <Loader />}
      <div className="row">
        {sessionId !== "new" && (
          <TaskCommentListener
            comments={comments}
            taskId={sessionId}
            updateComment={setComments}
          />
        )}
        <div className="col-12">
          {taskControl > -1 ? (
            <div className="deactivate-control" onClick={deactiveControl}></div>
          ) : null}
          <div className="task-focus">
            <div className="task-content d-flex">
              <div className="task-main d-flex flex-row">
                <div
                  className="d-flex flex-column border-right gap-3 p-3"
                  style={{ width: "274px", borderRight: "2px solid #222222" }}
                >
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div
                      className="mlp-btn rounded icon return-back mr-4"
                      onClick={() => handleBack()}
                    >
                      <LeftArrow style={{ width: "11px" }} />
                    </div>
                    <div className="task-action d-flex">
                      <div
                        id="move-previous"
                        onClick={() => movePreviousOrNext("previous")}
                        className="move-up mr-4"
                      >
                        <ArrowUp style={{ stroke: "#696974" }} />
                      </div>
                      <div
                        id="move-next"
                        onClick={() => movePreviousOrNext("next")}
                        className="move-down mr-4"
                      >
                        <ArrowDown />
                      </div>

                      <ReactTooltip
                        anchorId="move-previous"
                        place="left"
                        className="tooltip-wrapper"
                      >
                        Previous Task <div className="shortcut-tag">K</div>
                      </ReactTooltip>

                      <ReactTooltip
                        anchorId="move-next"
                        place="right"
                        className="tooltip-wrapper"
                      >
                        Next Task <div className="shortcut-tag">J</div>
                      </ReactTooltip>
                    </div>
                  </div>
                  <MentionsInput
                    placeholder="Title"
                    rows={3}
                    className="pill-input px-0 ms-input canvas-title"
                    value={milestone.title || inputValue}
                    id="canvas-title"
                    onChange={(e) =>
                      handleChange(
                        { target: { value: e.target.value, name: "title" } },
                        "milestone"
                      )
                    }
                  >
                    <Mention data={null} />
                  </MentionsInput>
                  <MentionsInput
                    placeholder="Description"
                    id="canvas-note"
                    className="pill-input px-0 ms-input note-title"
                    value={milestone?.note || ""}
                    onChange={(e) =>
                      handleChange(
                        { target: { value: e.target.value, name: "note" } },
                        "milestone"
                      )
                    }
                  >
                    <Mention data={null} />
                  </MentionsInput>
                  <div className="session-actions">
                    <div
                      className="shortcut-tag-container"
                      style={{ maxWidth: "100px" }}
                    >
                      {/* Go back */}
                      {/* <img src={asset_paint_board} alt="paint board" /> */}
                      {projects && milestone.project_id && (
                        <Emoji
                          unified={
                            projects?.find(
                              (proj) => proj.id === milestone?.project_id
                            )?.emoji || "1f31f"
                          }
                          emojiStyle={EmojiStyle.APPLE}
                          size={12}
                        />
                      )}
                      <Select
                        ref={projectRef}
                        options={
                          projects
                            ? [
                                NO_PROJECT,
                                ...projects
                                  ?.filter(
                                    (project) => project.status === "active"
                                  )
                                  .map((project) => ({
                                    label: project.title,
                                    value: project.id,
                                  })),
                              ]
                            : []
                        }
                        className="mikyal-select concise text-sm"
                        classNamePrefix="mikyal-select"
                        placeholder="+ Add a tag"
                        isSearchable={false}
                        onChange={(e) => handleSelect(e, "project_id")}
                        value={
                          projects && milestone.project_id
                            ? projects
                                ?.filter(
                                  (e) =>
                                    e?.id === milestone.project_id &&
                                    e.status === "active"
                                )
                                .map((project) => ({
                                  label: project.title,
                                  value: project.id,
                                }))
                            : null
                        }
                        components={{ Placeholder: ProjectPlaceHolder }}
                      />
                    </div>
                    <div className="text-truncate">
                      {milestone.dueDate ? (
                        <span
                          onClick={(e) => setOpenDueDate(true)}
                          className="date-text"
                        >
                          {format(new Date(milestone.dueDate), "MMM do")}
                        </span>
                      ) : (
                        <CalendarDue
                          onClick={(e) => setOpenDueDate(true)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                    <Dash />
                    <div>
                      <Select
                        options={workspaceMembers.map((member) => ({
                          label: member.username,
                          value: member.user_id,
                          avatar: member.avatar,
                        }))}
                        className="mikyal-select assignee concise text-sm fit-content"
                        classNamePrefix="mikyal-select"
                        formatOptionLabel={formatAssigneeLabel}
                        placeholder={<Assignee />}
                        onChange={(e) => {
                          if (e.value === milestone.assignee) {
                            dispatch(
                              handleUpdateTask({ ...milestone, assignee: null })
                            );
                            focusOutAllSelect();
                            return;
                          }
                          handleSelect(e, "assignee");
                        }}
                        value={
                          workspaceMembers && milestone.assignee
                            ? workspaceMembers
                                ?.filter(
                                  (member) =>
                                    member.user_id === milestone.assignee
                                )
                                .map((member) => ({
                                  label: member.username,
                                  value: member.user_id,
                                  avatar: member.avatar,
                                }))
                            : null
                        }
                        ref={assigneeRef}
                        openMenuOnFocus={true}
                        isSearchable={false}
                        components={{
                          Option: CustomOption,
                          SingleValue: CustomValue,
                          Placeholder: CustomPlaceholder,
                        }}
                        milestone={milestone}
                      />
                    </div>
                  </div>
                  <UpdatePill task={milestone} />
                  <TaskComments comments={comments} />
                </div>

                <div className="canvas-menu">
                  <div className="dd-action dropdown ms-1" placement="left">
                    <div
                      className="action-dots dropdown-toggle px-2"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <AssetDot />
                    </div>

                    <div
                      className="dd-data dropdown-menu dropstart"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <div
                        className="dd-item"
                        onClick={() => handleModal("delete-task")}
                      >
                        <span className="me-2">
                          <Trash />
                        </span>
                        Delete
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="meta">
                  <div
                    className="img-icon me-2"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="mikyal-select status concise text-sm task-action"
                      ref={statusRef}
                      {...anchorProps}
                    >
                      {
                        <div className="img-icon">
                          {StatusIcon(getTaskStatus().label)}
                        </div>
                      }
                    </div>
                    <ControlledMenu
                      {...menuState}
                      anchorRef={statusRef}
                      onClose={() => toggleMenu(false)}
                      className="mikyal-dropdown status concise text-sm"
                    >
                      {workflowStatutes.map((workflow) => {
                        if (workflow?.status?.length) {
                          return workflow?.status
                            .slice()
                            .sort((a, b) => a.position - b.position)
                            .map((status) => (
                              <MenuItem
                                className={
                                  status.id === milestone?.status_id
                                    ? "selected"
                                    : ""
                                }
                                onClick={() =>
                                  handleSelect(
                                    {
                                      value: workflow.name.toLowerCase(),
                                      statusId: status.id,
                                    },
                                    "status"
                                  )
                                }
                              >
                                {formatStatusLabel({
                                  value: status.name,
                                  label: workflow.name.toLowerCase(),
                                })}
                              </MenuItem>
                            ));
                        }
                        return null;
                      })}
                    </ControlledMenu>
                  </div>
                </div> */}

                <div className="session-notes">
                  <Editor
                    onChange={(text) => {
                      handleChange({
                        target: { name: "description", value: text },
                      });
                    }}
                    value={milestone?.description}
                    key={milestone?.id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            variant="dialog"
            emptyLabel="+ Set Due Date"
            format="MMM dd, yyyy"
            className="task-action"
            InputProps={{
              className: !milestone.dueDate ? "text-dim" : "",
            }}
            value={null}
            onChange={(date) => handleDateChange(date, "due")}
            disableToolbar={true}
            cancelLabel=""
            okLabel="Done"
            open={openDueDate}
            onClose={() => setOpenDueDate(false)}
            onOpen={() => setOpenDueDate(true)}
            TextFieldComponent={() => null}
          />
        </MuiPickersUtilsProvider>

        {modal && (
          <React.Fragment>
            <DeleteConfirmation
              setModal={setModal}
              modal={modal === "delete-task"}
              data={modalData}
              onDeleteSuccess={handleDeleteTask}
              modalLoader={false}
              description="This can’t be undone later"
            />
          </React.Fragment>
        )}
        <GlobalHotKeys
          keyMap={keyMap}
          handlers={keyHandlers}
          allowChanges={true}
        ></GlobalHotKeys>
        <CreateWorkspaceModal
          isOpen={workspaceModalOpen}
          onClose={() => {
            setWorkspaceModalOpen(false);
            navigate("/home");
          }}
        />
      </div>
    </div>
  );
};

export default TaskView;
