import { ReactComponent as Bell } from "../../assets/images/icons-mlp/bell.svg";
import { ReactComponent as OrangeDot } from "../../assets/images/icons/orange-dot.svg";
import NotificationCard from "../../infrastructure/Common/Notifications/NotificationCard";
import "./index.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalHotKeys } from "react-hotkeys";
import {
  setActiveCommentId,
} from "../../store/reducers/commentSlice";
import { orderBy } from "lodash";
import supabase from "../../infrastructure/Supabase";
import { SUPABASE_TABLE_NAME } from "../../infrastructure/Supabase/constants";

const NotificationContainer = ({ heading, notifications }) => {
  return (
    <>
      {heading && (
        <p className="notification-heading d-flex flex-row align-items-center gap-1 mb-3">
          {heading.toLowerCase() === "new" && <OrangeDot />}
          {heading.toLowerCase() === "new" ? notifications.length : null}{" "}
          {heading}
        </p>
      )}
      <div className="d-flex flex-column gap-4">
        {notifications.map((notification) => (
          <NotificationCard key={notification.id} notification={notification} />
        ))}
      </div>
    </>
  );
};

export default function Updates() {
  const [selectedTab, setSelectedTab] = useState("all");
  const [newest, setNewest] = useState([]);
  const [earlier, setEarlier] = useState([]);
  const [forMe, setForMe] = useState([]);
  const comments = useSelector((state) => state.comment.comments);
  const activeCommentId = useSelector((state) => state.comment.activeCommentId);
  const userInfo = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();

  const keyMap = {
    keyUp: "up",
    keyDown: "down",
    unselect: "esc",
  };

  const keyHandlers = {
    unselect: () => {
      dispatch(setActiveCommentId(""));
    },
    keyUp: () => {
      handleArrowKey("up");
    },
    keyDown: () => {
      handleArrowKey("down");
    },
  };

  // If user clicks on any comment then it will seen
  const handleSeenCommentForUser = async (comment) => {
    const updatedComment = {
      ...comment,
      seen_user_ids: comment?.seen_user_ids?.length
        ? [...comment.seen_user_ids, userInfo.id]
        : [userInfo.id],
    };
    await supabase
      .from(SUPABASE_TABLE_NAME.COMMENTS)
      .update({
        seen_user_ids: updatedComment?.seen_user_ids,
      })
      .eq("id", updatedComment.id);

    // dispatch(updateComment(updatedComment))
  };

  // Handle seen comment
  useEffect(() => {
    if (activeCommentId) {
      const comment = comments.find(
        (comment) => comment.id === activeCommentId
      );
      // Only that comments are unseen which not created by current user and the userId is not present in seen_user_ids array.
      if (
        !comment.seen_user_ids.includes(userInfo.id) &&
        comment.created_by !== userInfo.id
      ) {
        handleSeenCommentForUser(comment);
      }
    }
  }, [activeCommentId]);

  // Handle Up and Down arrow key
  const handleArrowKey = (key) => {
    const sortedNotifications = orderBy(comments, "createdAt", "desc");

    if (sortedNotifications.length === 0) return;

    if (key === "up") {
      if (activeCommentId) {
        const activeIndex = sortedNotifications.findIndex(
          (noti) => noti.id === activeCommentId
        );
        dispatch(
          setActiveCommentId(
            sortedNotifications[
              activeIndex === 0
                ? sortedNotifications.length - 1
                : activeIndex - 1
            ]?.id
          )
        );
      } else {
        dispatch(
          setActiveCommentId(
            sortedNotifications[sortedNotifications.length - 1]?.id
          )
        );
      }
    } else {
      if (activeCommentId) {
        const activeIndex = sortedNotifications.findIndex(
          (noti) => noti.id === activeCommentId
        );
        dispatch(
          setActiveCommentId(
            sortedNotifications[
              activeIndex === sortedNotifications.length + 1
                ? 0
                : activeIndex + 1
            ]?.id
          )
        );
      } else {
        dispatch(setActiveCommentId(sortedNotifications[0]?.id));
      }
    }
  };

  useEffect(() => {
    if (comments.length && selectedTab !== "all") {
      setForMe(comments.filter((noti) => noti.body.includes(userInfo.id)));
    }
  }, [selectedTab, comments]);

  useEffect(() => {
    // Filter comment by seen and unseen
    if (comments.length) {
      // Maintain array of comment which is not read by user
      setNewest(
        orderBy(
          comments.filter(
            (noti) =>
              !noti.seen_user_ids.includes(userInfo.id) &&
              noti.created_by !== userInfo.id
          ),
          "createdAt",
          "desc"
        )
      );
      // Maintain comment which is read by user
      setEarlier(
        orderBy(
          comments.filter(
            (noti) =>
              noti.seen_user_ids.includes(userInfo.id) ||
              noti.created_by === userInfo.id
          ),
          "createdAt",
          "desc"
        )
      );
    }
  }, [comments]);

  return (
    <div className="update-container container-fluid mt-4 px-4 py-3">
      <div className="update-header d-flex flex-row align-items-center">
        <div className="d-flex flex-row align-items-center gap-2">
          <Bell />
          Updates
        </div>
        <div className="navigation d-flex flex-1 align-items-center justify-content-center">
          <span
            className={`${selectedTab === "all" ? "active" : ""}`}
            onClick={() => setSelectedTab("all")}
          >
            All
          </span>
          <span
            className={`${selectedTab === "forme" ? "active" : ""}`}
            onClick={() => setSelectedTab("forme")}
          >
            For me
          </span>
          {/* <span
            className={`${selectedTab === "snoozed" ? "active" : ""}`}
            onClick={() => setSelectedTab("snoozed")}
          >
            Snoozed
          </span> */}
        </div>
        {/* TODO: Uncomment when post update support from here */}
        {/* <div className="mlp-btn small no-wrap d-flex align-items-center gap-2">
          Post an update
          <span className="mlp-shortcut-tag">U</span>
        </div> */}
      </div>

      <div className="notification-container mt-5 mx-auto ">
        {selectedTab === "forme" && (
          <>
            <NotificationContainer notifications={forMe} />

            {forMe.length === 0 && (
              <div className="empty-state d-flex flex-column align-items-center gap-2">
                <span style={{ color: "grey" }} className="text-center">
                  Great job! You're all caught up.
                </span>
              </div>
            )}
          </>
        )}

        {selectedTab === "all" && (
          <>
            {newest.length > 0 ? (
              <>
                <NotificationContainer heading={"New"} notifications={newest} />
                <hr className="divider" />
              </>
            ) : null}
            {earlier.length > 0 ? (
              <NotificationContainer
                heading={"Earlier"}
                notifications={earlier}
              />
            ) : null}
            {
              // check if both newest and earlier are empty
              newest.length === 0 && earlier.length === 0 && (
                <div className="empty-state d-flex flex-column align-items-center gap-2">
                  <span className="text-center">
                    Great job! You're all caught up.
                  </span>
                </div>
              )
            }
          </>
        )}
      </div>

      <GlobalHotKeys
        keyMap={keyMap}
        handlers={keyHandlers}
        allowChanges={true}
      />
    </div>
  );
}
