import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { OverflowNode } from "@lexical/overflow";
import {MarkNode} from '@lexical/mark';
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { EmojiNode } from "../nodes/EmojiNode";
import { ImageNode } from "../nodes/ImageNode";
import { MentionNode } from "../nodes/MentionNodes";
import { PollNode } from "../nodes/PollNode";
import { VideoNode } from "../nodes/VideoNode";
import { FileNode } from "../nodes/FileNode";

const Nodes = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  CodeHighlightNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  AutoLinkNode,
  LinkNode,
  HorizontalRuleNode,
  EmojiNode,
  ImageNode,
  MentionNode,
  PollNode,
  OverflowNode,
  VideoNode,
  FileNode,
  MarkNode
];

export default Nodes;