import "./index.scss";
import { StatusIcon, getTimeAgo } from "../../../../application/common/helper";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveCommentId } from "../../../../store/reducers/commentSlice";
// import ReplyInput from "./replyInput";
// import Replies from "../../Replies";
import { Mention, MentionsInput } from "react-mentions";
import { useLocation, useNavigate } from "react-router-dom";

const defaultAvatar = 'https://i.ibb.co/JFR6LyW/user.png';

export default function NotificationCard({ notification }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const notificationRef = useRef();
  const activeNotificationId = useSelector(
    (state) => state.comment.activeCommentId
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setIsActive(activeNotificationId === notification.id);
  }, [activeNotificationId]);

  return (
    <div
      className={`notification-card ${isActive ? "active" : ""}`}
      onClick={() => {
        dispatch(setActiveCommentId(notification.id));
      }}
      tabIndex={notification.id}
      ref={notificationRef}
    >
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div className="user-and-time d-flex align-items-center gap-2">
          <div className="dp-user">
            <img
              src={notification.user?.avatar || defaultAvatar }
              alt="User Profile"
            />
          </div>
          <span className="user-name">{notification.user.name}</span>
          <span className="time">{getTimeAgo(notification.createdAt)}</span>
        </div>
        <div
          className="task-name"
          onClick={(e) => {
            e.preventDefault();
            navigate(
              `${
                notification.task.project_id
                  ? `/tags/${notification.task.project_id}`
                  : ""
              }/canvases/${notification.task.id}`,
              { state: location.pathname }
            );
          }}
        >
          {StatusIcon(notification.task.status)}
          <span>{notification.task.name}</span>
        </div>
      </div>
      <div className="notification">
        <MentionsInput
          className="pill-input"
          disabled
          value={notification.body}
        >
          <Mention
            displayTransform={(_, display) => `@${display}`}
            markup="@[__display__](user:__id__)"
            className="mention"
          />
        </MentionsInput>
      </div>
      {/* {isActive && (
        <>
          {notification?.replies && notification?.replies?.length > 0 && <Replies replies={notification?.replies} replyCount={notification?.reply_count} commentId={notification.id} workSpaceId={notification.workspace_id}/>}
          <ReplyInput notification={notification} />
        </>
      )} */}
    </div>
  );
}
