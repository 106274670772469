import React, { useContext } from 'react';

import { GlobalHotKeys } from "react-hotkeys";
import WebContext from '../../../application/contexts/WebContext';

import asset_loader from './../../../assets/images/icons/loader.svg';
import { ReactComponent as Trash } from "./../../../assets/images/icons-mlp/trash.svg";


const DeleteConfirmation = (props) => {
    const { reloadSpotLight } = useContext(WebContext)

    const { data, modal, setModal, onDeleteSuccess, modalLoader, item, description } = props;
    // const [isLoading, setIsLoading] = useState(false);

    const keyMap = {
        goBack: "esc",
    };

    const keyHandlers = {
        goBack: (e) => {
            setModal('');
            e.preventDefault();
            return false;
        },
    };


    const handleDelete = () => {
        onDeleteSuccess(true, data);
        reloadSpotLight(data);
    };

    return (
        <div className={"mikyal-modal modal fade" + (modal ? 'show' : 'hide')} style={{ display: modal ? 'block' : 'none' }} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content d-flex flex-row p-4" style={{gap: 8}}>
                    <Trash className='modal-icon'/>
                    <div className='d-flex flex-column'>
                    <h5 className="px-2 pb-2 text-center mb-0">Are you sure you want to delete this {item ? item : 'canvas'}?</h5>
                    {description && <span className='px-2 sub-heading'>{description}</span>}

                    <div className="row pt-3 mt-3">
                        <div className="d-flex align-items-center justify-content-end">
                            {modalLoader &&
                                <div className="section-loader d-inline-flex me-3">
                                    <img src={asset_loader} alt="Loading" />
                                </div>
                            }

                            <button className="mikyal-btn secondary-button me-3" onClick={() => setModal('')} disabled={modalLoader}>
                                Cancel
                            </button>
                            <button className="mikyal-btn red-button position-relative" onClick={handleDelete} disabled={modalLoader}>
                                <span className="me-2"><Trash /></span>
                                Delete {item ? item : 'canvas'}
                            </button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <GlobalHotKeys
                keyMap={keyMap}
                handlers={keyHandlers}
                allowChanges={true}
            >
            </GlobalHotKeys>
        </div>
    );
};

export default DeleteConfirmation;