import React from 'react';

import asset_inv_cancel from './../../../assets/images/icons/inv-cancel.svg';
import asset_loader from './../../../assets/images/icons/loader.svg';


const ConfirmDelete = (props) => {
    const { data, modal, setModal, onDeleteSuccess, modalLoader } = props;

    const handleDelete = () => {
        onDeleteSuccess(true, data);
    };

    return (
        <div className={"mikyal-modal modal fade" + (modal ? 'show' : 'hide')} style={{ display: modal ? 'block' : 'none' }} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content p-4">
                    {/* {modalLoader &&
                        <div className="overlay-loader h-100">
                            <img src={asset_loader} alt="Loading" />
                        </div>
                    } */}
                    <div className="row">
                        <div className="col-12 text-end">
                            <button type="button" className="close-modal-btn" onClick={() => setModal('')}>
                                <img src={asset_inv_cancel} alt="cancel" />
                            </button>
                        </div>
                    </div>

                    <h4 className="p-4 text-center">Are you sure you want to delete?</h4>

                    <div className="row pt-3 d-flex align-items-center flex-column">
                        <div className="col-12 text-end d-flex justify-content-center">
                            <button className="mikyal-btn red-button me-3" onClick={() => setModal('')} disabled={modalLoader}>
                                {/* <span className="icon">
                                        <img src={asset_trash} alt="" />
                                    </span> */}
                                Cancel
                            </button>
                            <button className="mikyal-btn green-button position-relative" onClick={handleDelete} disabled={modalLoader}>
                                {/* <span className="icon">
                                        <img src={asset_check} alt="" />
                                    </span> */}
                                Confirm Delete
                            </button>
                        </div>
                        {true &&
                                <div className="section-loader d-inline-flex mt-2 p-0">
                                    <img src={asset_loader} alt="Loading" />
                                </div>
                            }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDelete;