import { $applyNodeReplacement, DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";

const FileComponent = React.lazy(
  // @ts-ignore
  () => import("./component")
);

function convertVideoElement(domNode) {
  if (domNode) {
    const { alt: altText, src, width, height } = domNode;
    const node = $createFileNode({ altText, height, src, width });
    return { node };
  }
  return null;
}

export class FileNode extends DecoratorNode {
  static getType() {
    return "file";
  }

  static clone(node) {
    return new FileNode(node.__src, node.__altText, node.__key);
  }

  static importJSON(serializedNode) {
    const { altText, src } = serializedNode;
    const node = $createFileNode({
      altText,
      src,
    });
    // const nestedEditor = node.__caption;
    // const editorState = nestedEditor.parseEditorState(caption.editorState);
    // if (!editorState.isEmpty()) {
    //   nestedEditor.setEditorState(editorState);
    // }
    return node;
  }

  exportDOM() {
    const element = document.createElement("span");
    element.setAttribute("src", this.__src);
    element.setAttribute("alt", this.__altText);
    return { element };
  }

  static importDOM() {
    return {
      span: (node) => ({
        conversion: convertVideoElement,
        priority: 0,
      }),
    };
  }

  constructor(src, altText, key) {
    super(key);
    this.__src = src;
    this.__altText = altText;
  }

  exportJSON() {
    return {
      altText: this.getAltText(),
      src: this.getSrc(),
      type: "file",
      version: 1,
    };
  }

  setWidthAndHeight(width, height) {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  setShowCaption(showCaption) {
    const writable = this.getWritable();
    writable.__showCaption = showCaption;
  }

  // View

  createDOM(config) {
    const span = document.createElement("span");
    const theme = config.theme;
    const className = theme.file;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }

  updateDOM() {
    return false;
  }

  getSrc() {
    return this.__src;
  }

  getAltText() {
    return this.__altText;
  }

  decorate() {
    return (
      <Suspense fallback={null}>
        <FileComponent
          src={this.__src}
          altText={this.__altText}
          nodeKey={this.getKey()}
          resizable={true}
        />
      </Suspense>
    );
  }
}

export function $createFileNode({ altText, src, key }) {
  return $applyNodeReplacement(new FileNode(src, altText, key));
}

export function $isFileNode(node) {
  return node instanceof FileNode;
}
