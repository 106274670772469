import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils";
import {
  $createParagraphNode,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from "lexical";
import { useEffect, useState } from "react";
import * as React from "react";

import asset_loader from "../../../../assets/images/icons/loader.svg";
import supabase from "../../../Supabase";
import { SUPABASE_BUCKET_NAME } from "../../../Supabase/constants";
import NotifierHelper from "../../../../application/common/NotifierHelper";
import { nanoid } from "nanoid";
import { $createVideoNode, VideoNode } from "../nodes/VideoNode";

export const INSERT_VIDEO_COMMAND = createCommand("INSERT_VIDEO_COMMAND");

export function InsertVideoUriDialogBody({ onClick, onClose }) {
  const [src, setSrc] = useState("");
  const [altText, setAltText] = useState("");

  const isDisabled = src === "";

  return (
    <div className="d-flex flex-column gap-3">
      <input
        label="Image URL"
        placeholder="i.e. https://source.unsplash.com/random"
        onChange={(e) => setSrc(e.target.value)}
        value={src}
        data-test-id="video-modal-url-input"
        className="form-control mikyal-field"
      />
      <input
        label="Alt Text"
        placeholder="Random unsplash image"
        onChange={(e) => setAltText(e.target.value)}
        value={altText}
        data-test-id="video-modal-alt-text-input"
        className="form-control mikyal-field"
      />
      <div className="d-flex flex-row justify-content-end gap-2">
        <button
          className="mikyal-btn secondary-button"
          data-test-id="video-modal-file-upload-btn"
          onClick={() => onClose()}
        >
          Cancel
        </button>
        <button
          className="mikyal-btn black-button"
          data-test-id="video-modal-confirm-btn"
          disabled={isDisabled}
          onClick={() => onClick({ altText, src })}
        >
          Confirm
        </button>
      </div>
    </div>
  );
}

export function InsertVideoUploadedDialogBody({ onClick, onClose }) {
  const [altText, setAltText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const uploadImageToStorage = async () => {
    setIsLoading(true);
    // Upload file to storage
    const { data, error } = await supabase.storage
      .from(SUPABASE_BUCKET_NAME.TASK_IMAGES)
      .upload(
        `${nanoid(7)}-${selectedFile.name.replaceAll(" ", "_")}`,
        selectedFile,
        {
          cacheControl: "3600",
          upsert: true,
        }
      );

    if (error) {
      onClose();
      NotifierHelper.notify("info", error.message);
      return;
    }

    // Get downloadURL from storage for same file
    const { data: downloadURL } = supabase.storage
      .from(SUPABASE_BUCKET_NAME.TASK_IMAGES)
      .getPublicUrl(data.path);

    setIsLoading(false);
    onClick({ altText, src: downloadURL?.publicUrl });
  };

  return (
    <div className="d-flex flex-column gap-3">
      <input
        label="Image Upload"
        onChange={(e) => setSelectedFile(e.target.files[0])}
        type="file"
        className="d-block"
        accept="video/*"
        data-test-id="video-modal-file-upload"
      />
      <input
        label="Alt Text"
        placeholder="Descriptive alternative text"
        onChange={(e) => setAltText(e.target.value)}
        value={altText}
        className="form-control mikyal-field"
        data-test-id="video-modal-alt-text-input"
      />
      <div className="d-flex flex-row justify-content-end gap-2">
        <button
          className="mikyal-btn secondary-button"
          data-test-id="video-modal-file-upload-btn"
          disabled={isLoading}
          onClick={() => onClose()}
        >
          Cancel
        </button>
        <button
          className="mikyal-btn black-button"
          data-test-id="video-modal-file-upload-btn"
          disabled={selectedFile === null}
          onClick={() => uploadImageToStorage()}
        >
          {isLoading ? (
            <div className="LataEditor__button-loader d-inline-flex">
              <img src={asset_loader} alt="Loading" />
            </div>
          ) : (
            "Confirm"
          )}
        </button>
      </div>
    </div>
  );
}

export function InsertVideoDialog({ activeEditor, onClose }) {
  const [mode, setMode] = useState(null);
  const hasModifier = React.useRef(false);

  useEffect(() => {
    hasModifier.current = false;
    const handler = (e) => {
      hasModifier.current = e.altKey;
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [activeEditor]);

  const onClick = (payload) => {
    activeEditor.dispatchCommand(INSERT_VIDEO_COMMAND, payload);
    onClose();
  };

  return (
    <>
      {!mode && (
        <div className="d-flex flex-column gap-2 position-relative">
          <button
            className="mikyal-btn black-button"
            data-test-id="video-modal-option-url"
            onClick={() => setMode("url")}
          >
            URL
          </button>
          <button
            className={`mikyal-btn black-button`}
            data-test-id="video-modal-option-file"
            onClick={() => setMode("file")}
          >
            File
          </button>
          <div className="d-flex flex-row justify-content-end gap-2">
            <button
              className="mikyal-btn secondary-button"
              data-test-id="video-modal-file-upload-btn"
              onClick={() => onClose()}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {mode === "url" && (
        <InsertVideoUriDialogBody onClick={onClick} onClose={onClose} />
      )}
      {mode === "file" && (
        <InsertVideoUploadedDialogBody onClick={onClick} onClose={onClose} />
      )}
    </>
  );
}

export default function VideoPlugin() {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (!editor.hasNodes([VideoNode])) {
      throw new Error("VideoPlugin: VideoNode not registered on editor");
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_VIDEO_COMMAND,
        (payload) => {
          const videoNode = $createVideoNode(payload);
          $insertNodes([videoNode]);
          if ($isRootOrShadowRoot(videoNode.getParentOrThrow())) {
            $wrapNodeInElement(videoNode, $createParagraphNode).selectEnd();
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      // editor.registerCommand(
      //   DRAGSTART_COMMAND,
      //   (event) => {
      //     return onDragStart(event);
      //   },
      //   COMMAND_PRIORITY_HIGH
      // ),
      // editor.registerCommand(
      //   DRAGOVER_COMMAND,
      //   (event) => {
      //     return onDragover(event);
      //   },
      //   COMMAND_PRIORITY_LOW
      // ),
      // editor.registerCommand(
      //   DROP_COMMAND,
      //   (event) => {
      //     return onDrop(event, editor);
      //   },
      //   COMMAND_PRIORITY_HIGH
      // )
    );
  }, [editor]);
  return null;
}
