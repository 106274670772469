import React, { useContext, useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import { Toaster } from "sonner";

import WebContext from "./application/contexts/WebContext";
import NotifierHelper from "./application/common/NotifierHelper";

import Home from "./presentation/Home";

import asset_loader from "./assets/images/icons/loader.svg";
import WorkspaceSetup from "./presentation/Auth/WorkspaceSetup";
import WorkspaceInvites from "./presentation/Auth/WorkspaceInvites";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserData,
  setUserLoggedIn,
  setWorkSpaceData,
} from "./store/reducers/userSlice";
import {
  setProjects,
  setTasks,
  toggleProjectLoading,
} from "./store/reducers/projectSlice";
import supabase from "./infrastructure/Supabase";
import { SUPABASE_RPC, SUPABASE_TABLE_NAME } from "./infrastructure/Supabase/constants";
import Loader from "./infrastructure/Common/MasterLoader";
import { setInvites, setWorkspaceMembers } from "./store/reducers/membersSlice";
import JoinWorkspace from "./presentation/Auth/JoinWorkspace";
import NoWorkSpace from "./presentation/Auth/NoWorkspace";
import WorkspaceLoadingScreen from "./presentation/Auth/WorkspaceLoadingScreen";
import { setWorkFlowStatus } from "./store/reducers/dropDownSlice";
import { setActiveCommentId, setComments } from "./store/reducers/commentSlice";

const Auth = React.lazy(() => import("./presentation/Auth"));

function App() {
  const webCtx = useContext(WebContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    initializeTheme();

    axios.interceptors.response.use(
      function (successRes) {
        return successRes;
      },
      function (error) {
        if (
          !error.response.config.url.includes("login") &&
          (error.response.status === 401 ||
            error.response.statusText === "Unauthorized")
        ) {
          dispatch(setUserLoggedIn(false));
          localStorage.removeItem("auth_token");
          navigate("/login");
          NotifierHelper.notify("info", "Session timeout, Try to login again");
        }
        return Promise.reject(error);
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeTheme = () => {
    document.body.classList.add(webCtx.appTheme);
  };

  return (
    <div>
      <AppContent />
      {/* </KBarProvider> */}
    </div>
  );
}

function AppContent() {  const isWaiting = () => {
    return (
      <div className="lazy-loader">
        <img src={asset_loader} alt="Loading" />
      </div>
    );
  };

  return (
    <React.Suspense fallback={isWaiting()}>
      <Routes>
        <Route path="/login" element={<Auth />} />
        <Route path="/register" element={<Auth />} />
        <Route
          path="/account-verification/:verification_token"
          element={<Auth />}
        />
        <Route path="/forgot-password" element={<Auth />} />
        <Route path="/reset-password" element={<Auth />} />
        <Route path="/workspace-setup" element={<WorkspaceSetup />} />
        <Route path="/workspace-invite" element={<WorkspaceInvites />} />

        <Route path="/join-workspace" element={<JoinWorkspace />} />
        <Route path="/no-workspace" element={<NoWorkSpace />} />
        <Route
          path="/*"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
      </Routes>
      {/* <ToastContainer transition={Slide} /> */}
      <Toaster position="bottom-right" richColors duration={5000} />
    </React.Suspense>
  );
}

function RequireAuth({ children }) {
  const [isWaiting, setIsWaiting] = useState(true);

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const userInfo = useSelector((state) => state.user.userInfo);
  const workSpaceInfo = useSelector((state) => state.user.workSpaceInfo);
  const projects = useSelector((state) => state.project.projects);
  const tasks = useSelector((state) => state.project.tasks);
  const {limit: notiLimit, offset: notiOffset, activeCommentId} = useSelector((state) => state.comment);
  const dispatch = useDispatch();

  // let auth = useAuth();
  let location = useLocation();
  const navigate = useNavigate();

  const [workSpaceLoading, setWorkspaceLoading] = useState(true);

  useEffect(() => {
    const checkIsUserLogin = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();

      if (user) {
        dispatch(setUserData(user));
        dispatch(setUserLoggedIn(true));
        return;
      }

      NotifierHelper.notify("notify", "Please login to continue");
      setIsWaiting(false);
    };

    if (!isLoggedIn) checkIsUserLogin();
  }, []);

  useEffect(() => {
    const getProjectTaskForWorkspace = async () => {
      // Get projects from workspace
      if (projects === null) {
        const { data: projectData } = await supabase
          .from(SUPABASE_TABLE_NAME.PROJECT)
          .select("*")
          .eq("workspace_id", workSpaceInfo.id);

        dispatch(setProjects(projectData));
      }

      // Get unassign tasks
      if (tasks === null) {
        const { data: taskData } = await supabase
          .from(SUPABASE_TABLE_NAME.TASK)
          .select("*")
          .eq("workspace_id", workSpaceInfo.id)
          .eq('isDelete', false)
          .order("dueDate", { ascending: true });
        dispatch(setTasks(taskData));
        setWorkspaceLoading(false);
      }

      // Get invite member list
      const { data: invitesData } = await supabase
        .from(SUPABASE_TABLE_NAME.INVITES)
        .select(`*`)
        .eq("workspace_id", workSpaceInfo.id);

      // Filtering a member who are already joined
      dispatch(
        setInvites(
          invitesData.filter(
            (invite) => !invite.is_joined && !invite.invited_to_app
          )
        )
      );

      // Get member list of joined in workspace
      const { data: workSpaceMember } = await supabase
        .from(SUPABASE_TABLE_NAME.PROFILES)
        .select(`*`)
        .eq("workspace_id", workSpaceInfo.id);
      // .neq("user_id", userInfo.id);

      const { data: groups } = await supabase
        .from(SUPABASE_TABLE_NAME.WORKFLOW_GROUPS)
        .select(`*, status:workflow_group_status(*)`)
        .eq("workflow_group_status.workspace_id", workSpaceInfo.id);

      const {data: comments} = await supabase
      .rpc(SUPABASE_RPC.GET_COMMENTS, {
        userid: userInfo.id,
        workspaceid: workSpaceInfo.id,
        page: notiOffset,
        perpage: notiLimit
      });
      // .from(SUPABASE_TABLE_NAME.COMMENTS)
      // .select('*, replies(count), user:created_by(auth.users:username)')
      // .eq("created_by", userInfo.id)
      // .eq("workspace_id", workSpaceInfo.id)
      // .range(notiFrom, notiTo);

      dispatch(setWorkFlowStatus(groups));

      dispatch(setWorkspaceMembers(workSpaceMember));

      dispatch(setComments(comments))

      dispatch(toggleProjectLoading(false));
    };

    if (Object.keys(workSpaceInfo).length) getProjectTaskForWorkspace();
  }, [workSpaceInfo]);

  useEffect(() => {
    const getUserDetails = async () => {
      window.analytics.identify(userInfo.id, {
        name: userInfo.user_metadata.username,
        email: userInfo.email,
      });

      const { data: roleData } = await supabase
        .from(SUPABASE_TABLE_NAME.PROFILES)
        .select("*, workspace(*)")
        .eq("user_id", userInfo.id)
        .single();

      if (roleData) {
        // Add type in user info state
        dispatch(
          setUserData({
            ...userInfo,
            type: roleData.role,
            avatar: roleData.avatar,
            username: roleData.username,
          })
        );

        setIsWaiting(false);
        if (roleData.workspace) {
          dispatch(setWorkSpaceData(roleData.workspace));
        } else {
          setWorkspaceLoading(false);
          if (roleData.role === "owner") navigate("/workspace-setup");
        }
      }
    };

    if (isLoggedIn && Object.keys(userInfo).length) {
      getUserDetails();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if(!location.pathname.includes('updates') && activeCommentId) dispatch(setActiveCommentId(''))
  }, [location])

  if (!isLoggedIn && !isWaiting) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/register" state={{ from: location }} replace />;
  }

  if (isWaiting && !isLoggedIn) {
    return <Loader />;
  }

  if (workSpaceLoading) {
    return <WorkspaceLoadingScreen />;
  }

  return children;
}

export default App;
