import React, { useState, useEffect, useContext } from 'react';

import { CircularProgressbar } from 'react-circular-progressbar';

import WebContext from '../../../application/contexts/WebContext';

import './circle-progress.scss';
import 'react-circular-progressbar/dist/styles.css';


const CircleProgress = (props) => {
    const webCtx = useContext(WebContext);
    const [line, setLine] = useState([]);
    const [fill, setFill] = useState(0);
    const { current, total, color, radius, stroke = 10 } = props;
    
    useEffect(() => {
        const key = color + '_' + (webCtx.appTheme === 'light-theme' ? 'light' : 'dark');
        initializeBar(key);
        setTimeout(() => {
            setFill(Math.ceil((current * 100) / total));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current, total]);

    // dark color(stroke), light color(base)
    const colors = {
        purple_light: ['#D043FF', '#F8E2FF'],
        purple_dark: ['#D043FF', '#2c1e35'],
        orange_light: ['#F1A12C', '#FFF2E7'],
        orange_dark: ['#F1A12C', '#2f261d'],
        red_light: ['#FF6B69', '#ffe5e5'],
        red_dark: ['#FF6B69', '#3c2323'],
        green_light: ['#36C4A3', '#EDF9F6'],
        green_dark: ['#36C4A3', '#2f261d'],
        blue_light: ['#546AE4', '#e0e4fb'],
        blue_dark: ['#546AE4', '#23273a'],
    }

    const initializeBar = (key) => {
        if (colors.hasOwnProperty(key)) {
            setLine(colors[key]);
        } else {
            setLine(colors.blue_light);
        }
    }

    return (
        <React.Fragment>
            {/* <div className="current" style={{ fontSize: (radius ? radius : 25) / 2.5 + 'px' }}>{current}/{total}</div> */}
            <CircularProgressbar
                value={fill}
                strokeWidth={stroke}

                styles={{
                    path: { stroke: line[0] },
                    trail: { stroke: line[1] },
                }}
            />
        </React.Fragment>
    );
};

export default CircleProgress;