import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import SessionsApi from "../../infrastructure/ApiServices/SessionsApi";

export const fetchSessions = createAsyncThunk(
  "session/fetchSessionsData",
  async (workSpaceId) => {
    const query = {};
    if (workSpaceId) {
      query.wid = workSpaceId;
    }
    const response = await SessionsApi.getSessions(query);
    return response.data.result;
  }
);

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    sessions: [],
    activeSession: {},
  },
  reducers: {
    setSessions: (state, action) => {
      state.sessions = action.payload;
    },
    updateSession: (state, action) => {
      const newSessions = cloneDeep(state.sessions);
      const updatedSessionData = action.payload;
      const updatedSessionIndex = state.sessions.findIndex(
        (session) => session._id === updatedSessionData._id
      );
      if (updatedSessionIndex > -1) {
        newSessions[updatedSessionIndex] = updatedSessionData;
      }
      state.sessions = cloneDeep(newSessions);
    },
    setActiveSession: (state, action) => {
      state.activeSession = action.payload;
    },
    resetSessionState: (state) => {
      state.sessions = [];
      state.activeSession = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSessions.fulfilled, (state, action) => {
      state.sessions = action.payload;
    });
  },
});

export const {
  setSessions,
  setActiveSession,
  updateSession,
  resetSessionState,
} = sessionSlice.actions;

export default sessionSlice.reducer;
