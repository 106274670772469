import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthApi from "../../infrastructure/ApiServices/AuthApi";

export const getUserDetails = createAsyncThunk(
  "user/getUserDetails",
  async ({userId, workSpaceId}) => {
    const query = {
      userId
    };
    if (workSpaceId) {
      query.workspaceId = workSpaceId;
    }
    const response = await AuthApi.getUserDetail(query);
    const localData = JSON.parse(localStorage.getItem('login_user'))
    const newData = localData ? {
      ...localData,
      ...response.data.result.user,
      workspace: {
        ...localData.workspace,
        ...response.data.result.workspace
      }
    } : {
      ...response.data.result.user,
      workspace: response.data.result?.workspace
    }

    localStorage.setItem('login_user', JSON.stringify(newData));
    return newData
  }
)

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoggedIn: false,
    userInfo: {},
    workSpaceInfo: {}
  },
  reducers: {
    setUserData: (state, action) => {
      state.userInfo = action.payload;
    },
    setUserLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setWorkSpaceData: (state, action) => {
      state.workSpaceInfo = action.payload;
    },
    resetUserState: (state) => {
      state.isLoggedIn = false;
      state.userInfo = {}
      state.workSpaceInfo = {}
    }
  },
  extraReducers: builder => {
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.userInfo = action.payload
    })
  }
});

export const { setUserData, setUserLoggedIn, resetUserState, setWorkSpaceData } = userSlice.actions;

export default userSlice.reducer;
