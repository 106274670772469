import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { GlobalHotKeys } from "react-hotkeys";
import NotifierHelper from "../../application/common/NotifierHelper";
import InviteModal from "../Invites/InviteModal";

import asset_loader from "./../../assets/images/icons/loader.svg";
import asset_placeholder from "./../../assets/images/icons/logo.svg";
import asset_upload from "./../../assets/images/icons/upload.svg";

import "./settings.scss";
import { useDispatch, useSelector } from "react-redux";
import { setUserData, setWorkSpaceData } from "../../store/reducers/userSlice";
import { useKBar } from "kbar";
import DeleteConfirmation from "../../infrastructure/Common/DeleteConfirmation";
import {
  setInvites,
  setWorkspaceMembers,
} from "../../store/reducers/membersSlice";
import supabase from "../../infrastructure/Supabase";
import {
  SUPABASE_BUCKET_NAME,
  SUPABASE_TABLE_NAME,
} from "../../infrastructure/Supabase/constants";
import AnalyticsHelper from "../../application/common/AnalyticsHelper";
import WorkFlow from "./WorkFlow";

const Settings = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);
  const invites = useSelector((state) => state.members.invited);
  const workspaceMembers = useSelector((state) => state.members.workspace);
  const workSpaceInfo = useSelector((state) => state.user.workSpaceInfo);

  const navigate = useNavigate();
  const { query } = useKBar();

  const [isWaiting, setIsWaiting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [userName, setUserName] = useState(
    userInfo?.username ?? userInfo.user_metadata.username
  );
  const [userEmail, setUserEmail] = useState(userInfo.email);
  const [password, setPassword] = useState({});
  const [workspaceTitle, setWorkspaceTitle] = useState(
    workSpaceInfo?.title || ""
  );
  const [activeTab, setActiveTab] = useState("general");
  const [userAvatar, setUserAvatar] = useState({ file: asset_placeholder });
  const [workSpaceLogo, setWorkspaceLogo] = useState({
    file: asset_placeholder,
  });

  const [modal, setModal] = useState("");
  const [modalData, setModalData] = useState([]);
  const [profileTabs, setProfileTabs] = useState([
    { name: "general" },
    { name: "password" },
  ]);

  const keyMap = {
    goBack: "esc",
  };

  const keyHandlers = {
    goBack: (e) => {
      navigate("/home");
      e.preventDefault();
      return false;
    },
  };

  useEffect(() => {
    if (
      userInfo?.type === "owner" &&
      !profileTabs.find((tab) => tab.name === "workspace")
    ) {
      setProfileTabs([
        ...profileTabs,
        { name: "workspace" },
        { name: "members" },
        {name: "workflow"}
      ]);
    }
  }, [userInfo]);

  const handleChange = (e, item = null, itemIndex = null, tIndex = null) => {
    const { name, value } = e.target;
    if (item === "password") {
      setPassword({ ...password, [name]: value });
    }
  };

  const saveChanges = async (type) => {
    setIsSaving(true);
    if (type === "password") {
      setPassword({ ...password, updated: true });
      if (password.newPassword !== password.confirmPassword) {
        return;
      }
      const { error } = await supabase.auth.updateUser({
        password: password.newPassword,
      });

      setPassword({});
      setIsSaving(false);
      if (error) {
        NotifierHelper.notify("info", error.message);
      } else {
        NotifierHelper.notify("info", "Password updated successfully!");
      }
    } else {
      const { error } = await supabase
        .from(SUPABASE_TABLE_NAME.PROFILES)
        .update({ username: userName })
        .eq("user_id", userInfo.id)
        .eq("workspace_id", workSpaceInfo?.id);

      setIsSaving(false);
      if (error) {
        NotifierHelper.notify("info", error.message);
        setUserName(userInfo?.username ?? userInfo.user_metadata.username);
        return;
      }

      dispatch(setUserData({ ...userInfo, username: userName }));
    }
  };

  const handleDeleteImage = async (type) => {
    if (type === "logo" && workSpaceInfo.logo) {
      const imageName = workSpaceInfo.logo.split(
        `${SUPABASE_BUCKET_NAME.WORKSPACE_LOGO}/`
      )[1];
      await supabase.storage
        .from(SUPABASE_BUCKET_NAME.WORKSPACE_LOGO)
        .remove([imageName]);
      return;
    }

    const imageName = userInfo?.avatar.split(
      `${SUPABASE_BUCKET_NAME.WORKSPACE_LOGO}/`
    )[1];
    await supabase.storage
      .from(SUPABASE_BUCKET_NAME.USER_AVATAR)
      .remove([imageName]);
  };

  useEffect(() => {
    if (userInfo?.type === "owner") {
      // Track event in group event in segment
      AnalyticsHelper.segmentGroupEvent(workSpaceInfo.id, {
        name: workSpaceInfo.title,
        employees:
          invites.length +
          workspaceMembers.filter((member) => member.user_id !== userInfo.id).length,
        plan: "free",
        avatar: workSpaceInfo?.logo,
        createdAt: workSpaceInfo.created_at,
        email: userInfo?.email,
      });
    }
  }, [invites, workspaceMembers]);

  const fileUpload = async (event, type = null) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      event.target.files = null;
      const fileType = event.target.files[0].type;
      if (fileType.match(/image\/*/) == null) {
        NotifierHelper.notify("info", "File not supported");
        event.target.files = null;
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      handleDeleteImage(type);
      reader.onload = () => {
        if (type === "logo") {
          setWorkspaceLogo({ file: reader.result });
        } else {
          setUserAvatar({ file: reader.result });
        }
      };

      let imageData;
      let error;
      // Upload file to storage
      if (type === "logo") {
        const { data: workspaceData, error: workspaceError } =
          await supabase.storage
            .from(SUPABASE_BUCKET_NAME.WORKSPACE_LOGO)
            .upload(`${workSpaceInfo.id}-${file.name}`, file, {
              cacheControl: "3600",
              upsert: true,
            });

        if (workspaceError) error = workspaceError;
        imageData = workspaceData;
      } else {
        const { data: avatarData, error: avatarError } = await supabase.storage
          .from(SUPABASE_BUCKET_NAME.USER_AVATAR)
          .upload(`${userInfo.id}-${file.name}`, file, {
            cacheControl: "3600",
            upsert: true,
          });

        if (avatarError) error = avatarError;
        imageData = avatarData;
      }

      if (error) {
        event.target.files = null;
        NotifierHelper.notify("info", error.message);
        setUserAvatar({ file: asset_placeholder });
        setWorkspaceLogo({ file: asset_placeholder });
        return;
      }

      // Get downloadURL from storage for same file
      let downloadURL;
      if (type === "logo") {
        const { data } = supabase.storage
          .from(SUPABASE_BUCKET_NAME.WORKSPACE_LOGO)
          .getPublicUrl(imageData.path);
        downloadURL = data;
      } else {
        const { data } = supabase.storage
          .from(SUPABASE_BUCKET_NAME.USER_AVATAR)
          .getPublicUrl(imageData.path);
        downloadURL = data;
      }

      if (type === "logo") {
        NotifierHelper.notify("info", "Workspace logo updated!");
        dispatch(
          setWorkSpaceData({ ...workSpaceInfo, logo: downloadURL?.publicUrl })
        );
        setWorkspaceLogo({ file: asset_placeholder });
        await supabase
          .from(SUPABASE_TABLE_NAME.WORKSPACE)
          .update({ logo: downloadURL?.publicUrl })
          .eq("id", workSpaceInfo.id);
      } else {
        NotifierHelper.notify("info", "Your avatar updated");
        dispatch(setUserData({ ...userInfo, avatar: downloadURL?.publicUrl }));
        await supabase
          .from(SUPABASE_TABLE_NAME.PROFILES)
          .update({ avatar: downloadURL?.publicUrl })
          .eq("user_id", userInfo.id)
          .eq("workspace_id", workSpaceInfo?.id);
      }
    }
  };

  const handleModal = (name, item = {}) => {
    setModal(name);
    setModalData(item);
  };

  const handleDelete = async (confirm, data) => {
    setModal("");

    if (data.from === "invited") {
      // Remove user from invite DB\
      const { error } = await supabase
        .from(SUPABASE_TABLE_NAME.INVITES)
        .delete()
        .eq("id", data.id);

      if (error) {
        NotifierHelper.notify("info", error.message);
        return;
      }

      dispatch(setInvites(invites.filter((invite) => invite.id !== data.id)));
    }

    if (data.from === "existing") {
      // Remove user from profile DB
      const { error } = await supabase
        .from(SUPABASE_TABLE_NAME.PROFILES)
        .update({
          workspace_id: null,
        })
        .eq("user_id", data.user_id)
        .eq("workspace_id", data.workspace_id);
      // Remove from invites as well
      await supabase
        .from(SUPABASE_TABLE_NAME.INVITES)
        .delete()
        .eq("user_id", data.user_id)
        .eq("workspace_id", data.workspace_id);
      if (error) {
        NotifierHelper.notify("info", error.message);
        return;
      }

      dispatch(
        setWorkspaceMembers(
          workspaceMembers.filter((member) => member.user_id !== data.user_id)
        )
      );
    }

    NotifierHelper.notify("delete", "Member deleted successfully");
  };

  const handleNewInvite = (newInvite) => {
    dispatch([...invites, newInvite]);
  };

  const saveWorkspace = async () => {
    setIsWaiting(true);

    const { error } = await supabase
      .from(SUPABASE_TABLE_NAME.WORKSPACE)
      .update({ title: workspaceTitle })
      .eq("id", workSpaceInfo.id);

    if (error) {
      NotifierHelper.notify("info", error.message);
      setWorkspaceTitle(workSpaceInfo?.title || "");
      setIsWaiting(true);
      return;
    }

    dispatch(setWorkSpaceData({ ...workSpaceInfo, title: workspaceTitle }));
    setIsWaiting(false);
  };

  return (
    <div className="user-profile container-fluid mt-4 pt-2 px-3 position-relative">
      {/* <NavLink to='/home' className="return-back">
                <img src={asset_arrow_back} alt="back" />
            </NavLink> */}

      <div className="row">
        <div className="col-12 ">
          <div className="content-header d-flex align-items-start justify-content-between">
            <div className="meta-container">
              <h1 className="fs-4 fw-semi mt-1">Settings</h1>
            </div>
          </div>
        </div>
      </div>

      <section className="content-profile">
        {Object.keys(userInfo).length === 0 && (
          <div className="overlay-loader">
            <img src={asset_loader} alt="Loading" />
          </div>
        )}

        <div className="row">
          <div className="col-12">
            <div className=" mb-4">
              <div className="mikyal-tabs d-flex">
                {profileTabs.map((tab, index) => (
                  <div
                    className={
                      "tab-item " + (tab.name === activeTab ? "active" : "")
                    }
                    key={"tab-" + (index + 1)}
                    onClick={() => setActiveTab(tab.name)}
                  >
                    {tab.name}
                  </div>
                ))}
              </div>
              <div className="tabs-content">
                {activeTab === "general" && (
                  <div className="">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="profile-image d-flex align-items-end">
                          <div
                            className="dp-container"
                            style={{
                              backgroundImage: `url('${
                                userInfo?.avatar || userAvatar.file
                              }')`,
                              borderRadius: "50%",
                            }}
                          >
                            <div className="input-file-wrapper">
                              <label
                                htmlFor="dp_upload"
                                className="custom-file-upload"
                                style={{ borderRadius: "50%" }}
                              >
                                <img src={asset_upload} alt="Loading" />
                              </label>
                              <input
                                id="dp_upload"
                                type="file"
                                onChange={fileUpload}
                                required
                              />
                            </div>
                          </div>
                          <div className="ms-3 constraint">
                            PNG, JPEG. Max size of 1mb
                          </div>
                        </div>

                        <div className="form-group mb-4">
                          <div className="mikyal-field-header">
                            <label className="form-field-label">
                              {" "}
                              Display Name{" "}
                            </label>
                          </div>

                          <input
                            type="text"
                            className="form-control mikyal-field bg-transparent"
                            placeholder="Alexey"
                            name="username"
                            defaultValue={userName}
                            onChange={(e) => setUserName(e.target.value)}
                          />
                        </div>

                        <div className="form-group mb-4">
                          <div className="mikyal-field-header">
                            <label className="form-field-label">
                              {" "}
                              Email Address{" "}
                            </label>
                          </div>

                          <input
                            type="text"
                            className="form-control mikyal-field bg-transparent"
                            placeholder="AlexeyCooper@email.com"
                            name="email"
                            defaultValue={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                            disabled
                          />
                        </div>

                        <div className="d-flex align-items-center justify-content-end pt-3">
                          {isSaving && (
                            <div className="section-loader">
                              <img src={asset_loader} alt="Loading" />
                            </div>
                          )}
                          <div className="action-item">
                            <button
                              className="mikyal-btn secondary-button ms-3"
                              onClick={() => saveChanges("info")}
                              disabled={isSaving}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "password" && (
                  <div className="">
                    <div className="row">
                      <div className="col-md-5">
                        {/* <div className="form-group mb-4">
                          <div className="mikyal-field-header">
                            <label className="form-field-label">
                              {" "}
                              Old Password{" "}
                            </label>
                          </div>

                          <input
                            type="password"
                            className="form-control mikyal-field bg-transparent"
                            placeholder=""
                            name="currentPassword"
                            value={password.currentPassword}
                            onChange={(e) => handleChange(e, "password")}
                          />
                        </div> */}

                        <div className="form-group mb-4">
                          <div className="mikyal-field-header">
                            <label className="form-field-label">
                              {" "}
                              New Password{" "}
                            </label>
                          </div>

                          <input
                            type="password"
                            className="form-control mikyal-field bg-transparent"
                            placeholder=""
                            name="newPassword"
                            defaultValue={password.newPassword}
                            onChange={(e) => handleChange(e, "password")}
                          />
                        </div>

                        <div className="form-group mb-4">
                          <div className="mikyal-field-header">
                            <label className="form-field-label">
                              Enter the new password again
                            </label>
                            {password.updated &&
                              password.newPassword !==
                                password.confirmPassword && (
                                <small className="text-danger">
                                  Password Mismatch
                                </small>
                              )}
                          </div>

                          <input
                            type="password"
                            className="form-control mikyal-field bg-transparent"
                            placeholder=""
                            name="confirmPassword"
                            defaultValue={password.confirmPassword}
                            onChange={(e) => handleChange(e, "password")}
                          />
                        </div>

                        <div className="d-flex align-items-center justify-content-end pt-3">
                          {isSaving && (
                            <div className="section-loader">
                              <img src={asset_loader} alt="Loading" />
                            </div>
                          )}
                          <div className="action-item">
                            <button
                              className="mikyal-btn secondary-button ms-3"
                              onClick={() => saveChanges("password")}
                              disabled={isSaving}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "workspace" && (
                  <div className="">
                    <div className=" row mt-4">
                      <div className="col-md-5 marginbot mb-4">
                        <div className="form-group mb-4">
                          <div className="mikyal-field-header">
                            <label className="form-field-label">
                              {" "}
                              Workspace Logo{" "}
                            </label>
                          </div>
                          <div className="profile-image d-flex align-items-end">
                            <div
                              className="dp-container"
                              style={{
                                backgroundImage: `url('${
                                  workSpaceInfo?.logo ?? workSpaceLogo.file
                                }')`,
                              }}
                            >
                              <div className="input-file-wrapper">
                                <label
                                  htmlFor="logo_upload"
                                  className="custom-file-upload"
                                >
                                  <img src={asset_upload} alt="Loading" />
                                </label>
                                <input
                                  id="logo_upload"
                                  type="file"
                                  onChange={(e) => fileUpload(e, "logo")}
                                  required
                                />
                              </div>
                            </div>
                            <div className="ms-3 constraint">
                              PNG, JPEG. Max size of 1mb
                            </div>
                          </div>
                        </div>

                        <div className="form-group mb-4">
                          <div className="mikyal-field-header">
                            <label className="form-field-label">
                              {" "}
                              Workspace Name{" "}
                            </label>
                          </div>
                          <input
                            type="text"
                            className="form-control mikyal-field"
                            placeholder="ACME Inc."
                            name="title"
                            defaultValue={workspaceTitle}
                            onChange={(e) => setWorkspaceTitle(e.target.value)}
                          />
                        </div>

                        <div className="d-flex align-items-center justify-content-end pt-3">
                          {isSaving && (
                            <div className="section-loader">
                              <img src={asset_loader} alt="Loading" />
                            </div>
                          )}
                          <div className="action-item">
                            <button
                              className="mikyal-btn secondary-button ms-3"
                              onClick={saveWorkspace}
                              disabled={isSaving}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {userInfo?.type === "owner" && activeTab === "members" && (
                  <div className="">
                    <div className="row">
                      <div className="col-md-6">
                        <table className="mikyal-table-bordered redesign-table w-100">
                          <tbody>
                            {workspaceMembers.length > 0 &&
                              workspaceMembers
                                .filter(
                                  (member) => member.user_id !== userInfo.id
                                )
                                .map((member, index) => (
                                  <tr className="tb-row" key={index}>
                                    <td
                                      className={
                                        "max-fit-content custom-lh " +
                                        (index > 0 ? "pt-4" : "")
                                      }
                                    >
                                      <div className="d-flex align-items-center">
                                        <div className="member-dp me-3">
                                          <img
                                            src={member?.avatar ?? asset_placeholder}
                                            className={"user-dp"}
                                            alt="User Profile"
                                          />
                                        </div>
                                        <div className="client-data lh-base">
                                          <div className="primary-text inv-title d-flex align-items-center gap-3 fs-md">
                                            {member.username || member.email}
                                            <span
                                              className="mlp-shortcut-tag"
                                              style={{ width: "fit-content" }}
                                            >
                                              {member.role}
                                            </span>
                                          </div>

                                          <div className="client-email d-flex fs-md">
                                            <div>{member.email}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>

                                    <td
                                      className="position-relative"
                                      align="right"
                                    >
                                      <div
                                        className="pointer"
                                        onClick={() =>
                                          handleModal("delete", {
                                            ...member,
                                            from: "existing",
                                          })
                                        }
                                      >
                                        Remove
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>

                        {invites.length > 0 && (
                          <label className="form-field-label mt-4">
                            Invited Users
                          </label>
                        )}
                        <table className="mikyal-table-bordered redesign-table w-100">
                          <tbody>
                            {invites.length > 0 &&
                              invites.map((item, index) => (
                                <tr className="tb-row" key={index}>
                                  <td
                                    className={
                                      "max-fit-content custom-lh " +
                                      (index > 0 ? "pt-4" : "")
                                    }
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="member-dp me-3">
                                        <img
                                          src={asset_placeholder}
                                          className={"user-dp"}
                                          alt="User Profile"
                                        />
                                      </div>
                                      <div className="client-data lh-base">
                                        <div className="primary-text inv-title fs-md d-flex align-items-center gap-3">
                                          {item.user?.username || item.email}
                                          <span
                                            className="mlp-shortcut-tag"
                                            style={{ width: "fit-content" }}
                                          >
                                            {item.role}
                                          </span>
                                        </div>

                                        <div className="client-email d-flex fs-md">
                                          <div>
                                            {item.isAccepted
                                              ? item.email
                                              : "Awaiting response"}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>

                                  <td
                                    className="position-relative"
                                    align="right"
                                  >
                                    <div
                                      className="pointer"
                                      onClick={() =>
                                        handleModal("delete", {
                                          ...item,
                                          from: "invited",
                                        })
                                      }
                                    >
                                      Remove
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>

                        {!isWaiting &&
                          invites.length === 0 &&
                          workspaceMembers.length === 0 && (
                            <table>
                              <tbody className="mikyal-table-bordered redesign-table w-100">
                                <tr>
                                  {" "}
                                  <td colSpan="42" className="not-found pt-5">
                                    Invite your team to your workspace
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}

                        <div className="dim-mlp mt-3">
                          <span
                            className="pointer"
                            onClick={() => {
                              query.setCurrentRootAction("invites");
                              query.toggle();
                            }}
                          >
                            + Add team member
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "workflow" && <WorkFlow />}
              </div>
            </div>
          </div>
        </div>
      </section>
      <GlobalHotKeys
        keyMap={keyMap}
        handlers={keyHandlers}
        allowChanges={true}
      ></GlobalHotKeys>

      {modal && (
        <React.Fragment>
          <InviteModal
            setModal={setModal}
            modal={modal === "new"}
            onSaveSuccess={handleNewInvite}
          />
          <DeleteConfirmation
            setModal={setModal}
            modal={modal === "delete"}
            data={modalData}
            onDeleteSuccess={handleDelete}
            modalLoader={false}
            item={"user"}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default Settings;
