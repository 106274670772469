import "./index.scss";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import LexicalClickableLinkPlugin from "@lexical/react/LexicalClickableLinkPlugin";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { useMemo, useState } from "react";

import ComponentPickerPlugin from "./plugins/ComponentPickerPlugin";
import ImagesPlugin from "./plugins/ImagePlugin";
import MentionPlugin from "./plugins/MentionPlugin";
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin";
import Placeholder from "./ui/Placeholder";
import LexicalContentEditable from "./ui/ContentEditable";
import EditorTheme from "./themes/EditorTheme";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import LinkPlugin from "./plugins/LinkPlugin";
import FloatingLinkEditorPlugin from "./plugins/FloatingLinkEditorPlugin";
import LexicalAutoLinkPlugin from "./plugins/AutoLinkPlugin";
import DragDropPaste from "./plugins/DragDropPastePlugin";
import EmojiPickerPlugin from "./plugins/EmojiPickerPlugin";
import MarkdownShortcutPlugin from "./plugins/MarkdownShortcutPlugin";
import EmojisPlugin from "./plugins/EmojisPlugin";
import PollPlugin from "./plugins/PollPlugin";
import VideoPlugin from "./plugins/VideoPlugin";
import EditorNodes from "./themes/EditorNodes";
import FilePlugin from "./plugins/FilePlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";

// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
// function MyCustomAutoFocusPlugin() {
//   const [editor] = useLexicalComposerContext();

//   useEffect(() => {
//     // Focus the editor when the effect fires!
//     editor.focus();
//   }, [editor]);

//   return null;
// }

const makeLexicalObject = (value) => {
  return {
    root: {
      children: [
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: value,
              type: "text",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
      ],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1,
    },
  };
};

export default function Editor({ onChange, value }) {
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);

  // When the editor changes, you can get notified via the
  // LexicalOnChangePlugin!
  function onEditorChange(editorState) {
    editorState.read(() => {
      // Read the contents of the EditorState here.
      // const root = $getRoot();
      // const selection = $getSelection();

      if (onChange) onChange(JSON.stringify(editorState));
    });
  }

  // Catch any errors that occur during Lexical updates and log them
  // or throw them as needed. If you don't throw them, Lexical will
  // try to recover gracefully without losing user data.
  function onError(error) {
    console.error(error);
  }

  const getText = useMemo(() => {
    try {
      if (JSON.parse(value)) return value;
    } catch {
      return JSON.stringify(makeLexicalObject(value));
    }
  }, [value]);

  const initialConfig = useMemo(
    () => ({
      namespace: "LataEditor",
      editorState: getText,
      onError,
      nodes: EditorNodes,
      theme: EditorTheme,
      editable: true,
    }),
    [getText]
  );

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return (
      <LexicalComposer initialConfig={initialConfig}>
        {/* <SharedAutocompleteContext> */}
        <ComponentPickerPlugin />
        <ListPlugin />
        <EmojiPickerPlugin />
        <HistoryPlugin />
        <EmojisPlugin />
        <TabIndentationPlugin />
        <CheckListPlugin />
        <HorizontalRulePlugin />
        <DragDropPaste />
        <CodeHighlightPlugin />
        <MarkdownShortcutPlugin />
        <LinkPlugin />
        <LexicalAutoLinkPlugin />
        <LexicalClickableLinkPlugin />
        <ImagesPlugin captionsEnabled={false} />
        <VideoPlugin />
        <MentionPlugin />
        <FilePlugin />

        {/* <HashtagPlugin /> */}
        <RichTextPlugin
          contentEditable={
            <div className="lata-editor" ref={onRef}>
              <LexicalContentEditable placeholder="Write your notes, ideas, or attach anything here in this freeform space :)" />
            </div>
          }
          placeholder={
            <Placeholder>
              Write your notes, ideas, or attach anything here in this freeform
              space :)
            </Placeholder>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <OnChangePlugin onChange={onEditorChange} />
        <PollPlugin />
        {/* <HistoryPlugin /> */}
        {floatingAnchorElem && (
          <>
            {/* Block Drag n Drop Support */}
            {/* <DraggableBlockPlugin anchorElem={floatingAnchorElem} /> */}
            <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
          </>
        )}
        <FloatingTextFormatToolbarPlugin />
        {/* <MyCustomAutoFocusPlugin /> */}
        {/* <TreeViewPlugin /> */}
        {/* </SharedAutocompleteContext> */}
      </LexicalComposer>
  );
}
