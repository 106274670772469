class AnalyticsHelper {
  // Segment event tracking
  trackEvent(eventTitle, eventData) {
    window.analytics.track(eventTitle, eventData);
  }

  // Group event
  segmentGroupEvent(workSpaceId, data) {
    window.analytics.group(workSpaceId, data);
  }

  // Smartlook track event
  // smartLookTrackEvent(eventTitle, eventData) {
  //   window.smartlook('track', eventTitle, {
  //     type: eventTitle,
  //     ...eventData
  //   });
  // }
}

export default new AnalyticsHelper();
