import { ReactComponent as BacklogRing } from "../../../assets/images/icons-mlp/status_backlog.svg";
import { ReactComponent as CompleteRing } from "../../../assets/images/icons-mlp/status-completed.svg";
import { ReactComponent as InProgressRing } from "../../../assets/images/icons-mlp/status-inprogress.svg";
import { ReactComponent as ToDoRing } from "../../../assets/images/icons-mlp/status_ring.svg";
import { ReactComponent as CheckMark } from "../../../assets/images/icons-mlp/check.svg";
import { ReactComponent as Pencil } from "../../../assets/images/icons-mlp/pencil.svg";
import { ReactComponent as ArrowUp } from "../../../assets/images/icons-mlp/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../../assets/images/icons-mlp/arrow-down.svg";
import { ReactComponent as Close } from "../../../assets/images/icons-mlp/x.svg";
import asset_loader from "../../../assets/images/icons/loader.svg";

const StatusIcon = (status) => {
  return (
    <>
      {status === "completed" ? (
        <CompleteRing />
      ) : status === "in progress" ? (
        <InProgressRing />
      ) : status === "to do" ? (
        <ToDoRing />
      ) : (
        <BacklogRing />
      )}
    </>
  );
};

export default function SingleStatus({
  status,
  groupName,
  changeStatusName,
  handleSaveStatus,
  deleteStatus,
  updateEditingStatus,
  changePosition,
  showUpButton,
  showDownButton
}) {
  return (
    <div className="single-status">
      {StatusIcon(groupName.toLowerCase())}
      {status.isEditing ? (
        <>
          <input
            value={status.name}
            onChange={(e) =>
              changeStatusName({ ...status, name: e.target.value })
            }
            autoFocus
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                handleSaveStatus(status);
              }
            }}
          />
          <div className="icon-container">
            {status.isLoading ? (
              <img src={asset_loader} alt="loading" />
            ) : (
              <>
                <CheckMark onClick={() => handleSaveStatus(status)} />
                <Close onClick={() => updateEditingStatus(status)} />
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <p className="status-name">{status.name}</p>
          <div className="icon-container">
            {status.isLoading ? (
              <img src={asset_loader} alt="loading" />
            ) : (
              <>
                {showUpButton && <ArrowUp
                  onClick={() =>
                    changePosition({ ...status, position: status.position - 1 }, status.position)
                  }
                />}
                {showDownButton && <ArrowDown
                  onClick={() =>
                    changePosition({ ...status, position: status.position + 1 }, status.position)
                  }
                />}
                {groupName !== status.name && (
                  <>
                    <Pencil onClick={() => updateEditingStatus(status)} />
                    <Close onClick={() => deleteStatus(status)} />
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
