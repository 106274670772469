import { ReactComponent as CompleteRing } from "../../assets/images/icons-mlp/status-completed.svg";
import { ReactComponent as InProgressRing } from "../../assets/images/icons-mlp/status-inprogress.svg";
import { ReactComponent as ToDoRing } from "../../assets/images/icons-mlp/status_ring.svg";
import { ReactComponent as BacklogRing } from "../../assets/images/icons-mlp/status_backlog.svg";
import { format } from "date-fns";

export function SortPosition(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

export const StatusIcon = (status) => {
  return (
    <>
      {status === "completed" ? (
        <CompleteRing />
      ) : status === "in progress" ? (
        <InProgressRing />
      ) : status === "to do" ? (
        <ToDoRing />
      ) : (
        <BacklogRing />
      )}
    </>
  );
};

export const getTimeAgo = (date) => {
  const now = new Date();
  const commentDate = new Date(date);
  const timeDiffInSeconds = Math.floor((now - commentDate) / 1000); // Convert to seconds

  if(timeDiffInSeconds < 10) {
    return `Just now`;
  } else if (timeDiffInSeconds < 60) {
    return `${timeDiffInSeconds} second${
      timeDiffInSeconds === 1 ? "" : "s"
    } ago`;
  } else if (timeDiffInSeconds < 3600) {
    const minutes = Math.floor(timeDiffInSeconds / 60);
    return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
  } else if (timeDiffInSeconds < 86400) {
    const hours = Math.floor(timeDiffInSeconds / 3600);
    return `${hours} hour${hours === 1 ? "" : "s"} ago`;
  } else if (timeDiffInSeconds < 172800) {
    return `Yesterday, ${format(new Date(date), "'at' p")}`;
  } else {
    return format(new Date(date), "EEEE, 'at' p")
  }
};
