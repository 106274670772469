import React, { useEffect, useContext, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  KBarSearch,
  useMatches,
  KBarResults,
} from "kbar";
import { uniqBy } from "lodash";
import { Emoji, EmojiStyle } from "emoji-picker-react";

import WebContext from "../../../application/contexts/WebContext";

import { createInvite } from "../../../infrastructure/ApiServices/InvitesApi";
import insertArrayAtSpecificIndex from "../../../application/utils/insertArrayAtSpecificIndex";

// import { ReactComponent as teamMembers } from "./../../../assets/images/icons-mlp/assignee.svg";
import asset_team_member from "./../../../assets/images/icons-mlp/users.svg";
import asset_task from "./../../../assets/images/icons-mlp/check-circle-light.svg";
import asset_project from "./../../../assets/images/icons-mlp/file-text-light.svg";
import asset_cmd from "./../../../assets/images/icons-mlp/cmd.svg";
import asset_flag from "./../../../assets/images/icons-mlp/flag.svg";
import asset_enter from "../../../assets/images/icons/action-enter.svg";
import asset_arrow_right from "../../../assets/images/icons-mlp/arrow-right.svg";

import "./spotlight.scss";
import { useDispatch, useSelector } from "react-redux";
import { setInputValue } from "../../../store/reducers/projectSlice";
import { setInvites } from "../../../store/reducers/membersSlice";
import NotifierHelper from "../../../application/common/NotifierHelper";

const listIcons = {
  cr_member: asset_team_member,
  cr_canvas: asset_task,
  cr_tag: asset_project,
  hp_cmd: asset_cmd,
  hp_feedback: asset_flag,
  arrow_right: asset_arrow_right,
};

const animatorStyle = {
  maxWidth: "600px",
  width: "100%",
  background: "#0e0e0e",
  color: "var(--secondary-text)",
  borderRadius: "8px",
};

const childLabelStyle = {
  padding: "4px",
  background: "var(--hover-level-2)",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  width: "fit-content",
  borderRadius: "4px",
  marginLeft: "16px",
  marginTop: "16px",
  fontSize: "14px",
};

const searchStyle = {
  padding: "16px 16px",
  fontSize: "14px",
  width: "100%",
  boxSizing: "border-box",
  outline: "none",
  border: "none",
  borderBottom: "1px solid var(--border-mlp)",
  background: "#0e0e0e",
  color: "var(--secondary-text)",
  fontWeight: "600",
};

const shortcutIndicator = {
  visibility: "hidden",
};

const Icon = ({ questionType }) => {
  return (
    <div className="qtype-tile-square">
      {questionType.icons.map((icon, idx) => {
        return (
          <img key={idx} src={listIcons[icon]} alt="item-icon" width="100%" />
        );
      })}
    </div>
  );
};

const CommandKey = ({ sc, idx }) => {
  return (
    <span
      className="dim-mlp d-flex align-items-center"
      style={{ fontSize: "14px" }}
      key={idx}
    >
      {idx > 0 && " then "}
      <kbd
        key={sc}
        style={{
          background: "var(--border-mlp)",
          color: "var(--dim-mlp)",
          borderRadius: "4px",
          fontSize: 14,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textTransform: "uppercase",
          width: "24px",
          height: "24px",
          marginLeft: "5px",
        }}
      >
        {sc}
      </kbd>
    </span>
  );
};

const RenderResults = ({actions}) => {
  const { setCurrentResults, setAllResults } =
    useContext(WebContext);

  const { results, rootActionId } = useMatches();
  // save results to use
  useEffect(() => {
    setCurrentResults(results);
    setAllResults(results);
  }, [results, setAllResults, setCurrentResults]);

  const RenderResultItem = React.forwardRef(
    ({ action, active, currentRootActionId }, ref) => {
      const ancestors = React.useMemo(() => {
        if (!currentRootActionId) return action.ancestors;
        const index = action.ancestors?.findIndex(
          (ancestor) => ancestor.id === currentRootActionId
        );
        // +1 removes the currentRootAction; e.g.
        // if we are on the "Set theme" parent action,
        // the UI should not display "Set theme… > Dark"
        // but rather just "Dark"
        return action.ancestors?.slice(index + 1);
      }, [action.ancestors, currentRootActionId]);

      return action?.name ? (
        <div
          ref={ref}
          style={{
            padding: "8px",
            background: active ? "var(--hover-level-2)" : "transparent",
            // borderLeft: `2px solid ${
            //   active ? "var(--foreground)" : "transparent"
            // }`,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
            borderRadius: "8px",
          }}
          className={active ? "active-result" : ""}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              fontSize: 14,
            }}
          >
            {action.icons ? (
              <Icon questionType={action} />
            ) : action?.emoji ? (
              <div className="menu-item-icon me-2">
                <Emoji
                  unified={action.emoji || "1f31f"}
                  emojiStyle={EmojiStyle.APPLE}
                  size={16}
                />
              </div>
            ) : null}

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                {ancestors?.length > 0 &&
                  ancestors.map((ancestor) => (
                    <React.Fragment key={ancestor.id}>
                      <span
                        style={{
                          opacity: 0.5,
                          marginRight: 8,
                        }}
                      >
                        {ancestor.name}
                      </span>
                      <span
                        style={{
                          marginRight: 8,
                        }}
                      >
                        &rsaquo;
                      </span>
                    </React.Fragment>
                  ))}
                <span>{action.name}</span>
              </div>
              {action.description && (
                <span style={{ fontSize: 12 }}>{action.description}</span>
              )}
            </div>
          </div>

          {action.shortcut_wo_effect?.length ? (
            <div
              aria-hidden
              style={{ display: "grid", gridAutoFlow: "column", gap: "4px" }}
            >
              {action.shortcut_wo_effect.map((sc, idx) =>
                !action?.parent ? (
                  <CommandKey sc={sc} idx={idx} key={idx} />
                ) : action?.parent && active ? (
                  <CommandKey sc={sc} key={idx} />
                ) : null
              )}
            </div>
          ) : null}
          {action?.isEnterKey && (
            <div
              className="qtype-tile-square item-shtct-indicator"
              style={shortcutIndicator}
            >
              <img src={asset_enter} alt="item-icon" width="100%" />
            </div>
          )}
        </div>
      ) : null;
    }
  );

  return (
    <div className="kBar-list-wrapper">
      <KBarResults
        items={results}
        onRender={({ item, active }) =>
          typeof item === "string" ? (
            <div className="dim-mlp px-2 py-2 pt-3">{item}</div>
          ) : (
            <div className={item?.name ? "" : "d-none"}>
              <RenderResultItem
                action={item}
                active={active}
                currentRootActionId={rootActionId}
              />
            </div>
          )
        }
      />
    </div>
  );
};

const Spotlight = ({ children }) => {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Context
  const {
    actions,
    currentResults,
    // shouldTriggerReload,
    setShouldTriggerReload,
    setActions,
    reloadSpotLight,
  } = useContext(WebContext);

  const projects = useSelector((state) => state.project.projects);
  const inputValue = useSelector((state) => state.project.inputValue);
  const userInfo = useSelector((state) => state.user.userInfo);
  const workSpaceInfo = useSelector((state) => state.user.workSpaceInfo);
  const invites = useSelector((state) => state.members.invited);
  const dispatch = useDispatch();

  // Local States
  const [sendInvite, setSendInvite] = useState(false);

  const defaultActions = useMemo(() => {
    const actions = [
      // Create a New Task
      {
        id: "canvas",
        name: "Create a new canvas",
        icons: ["cr_canvas"],
        shortcut: ["c"],
        shortcut_wo_effect: ["c"],
        keywords: "email",
        category: "Create",
        section: "Create",
      },
      // Create a New Task Childs
      {
        id: "createCanvas",
        parent: "canvas",
        name: "Create Canvas",
        perform: (e) => {
          var url = window.location.pathname;
          var itemId = url.split("/")[2];
          if (window.location.pathname.includes("/tags/")) {
            navigate(`/tags/${itemId}/canvases/new`, {
              state: {
                from: url,
              },
            });
          } else {
            navigate(`/canvases/new`, {
              state: {
                from: url,
              },
            });
          }
        },
        icons: ["cr_canvas"],
        // shortcut: ["c"],
        // shortcut_wo_effect: ["c"],
        isEnterKey: true,
      },
      {
        id: "createCanvasInTag",
        parent: "canvas",
        name: "Create canvas in a tag",
        icons: ["cr_canvas"],
        isEnterKey: true,
      },

      // Projects
      {
        id: "tag",
        name: "Create a new tag",
        icons: ["cr_tag"],
        shortcut: ["t"],
        shortcut_wo_effect: ["t"],
        keywords: "writing words",
        category: "Create",
        section: "Create",
      },
      {
        id: "CreateNewTag",
        parent: "tag",
        name: "Create Tag",
        icons: ["cr_tag"],
        perform: () => navigate("/tags/new"),
        isEnterKey: true,
      },
      // Navigation
      {
        id: "goToAssignedToMe",
        icons: ["arrow_right"],
        name: "Go to Canvases",
        shortcut: ["g", "c"],
        keywords: "go to canvases",
        section: "Navigate",
        perform: () => navigate("/home"),
        shortcut_wo_effect: ["g", "c"],
      },
      {
        id: "goToAllTags",
        icons: ["arrow_right"],
        name: "Go to Tags",
        shortcut: ["g", "t"],
        keywords: "go to tags",
        section: "Navigate",
        perform: () => navigate("/tags"),
        shortcut_wo_effect: ["g", "t"],
      },
      {
        id: "goToSettings",
        icons: ["arrow_right"],
        shortcut: ["g", "s"],
        name: "Go to Settings",
        keywords: "go to settings members workspace general password invites",
        section: "Navigate",
        perform: () => navigate("/settings"),
        shortcut_wo_effect: ["g", "s"],
      },
      {
        id: "goToUpdates",
        icons: ["arrow_right"],
        name: "Go to Updates",
        shortcut: ["g", "u"],
        keywords: "go to updates",
        section: "Navigate",
        perform: () => navigate("/updates"),
        shortcut_wo_effect: ["g", "u"],
      },
    ];

    if (userInfo.type === "owner") {
      // Invitation
      actions.push(
        ...[
          {
            id: "invites",
            icons: ["cr_member"],
            shortcut: ["i"],
            name: "Add a new team member",
            shortcut_wo_effect: ["i"],
            subtitle: "name@email.com",
            category: "Invites",
            section: "Create",
            color: "gray",
          },
          {
            id: "inviteEmail",
            parent: "invites",
            name: "Send Invite",
            icons: ["cr_member"],
            keywords: "email @",
            perform: (e) => {
              setSendInvite(true);
            },
            isEnterKey: true,
          },
          {
            id: "inviteEmail1",
            parent: "invites",
          },
        ]
      );

      setActions(actions);
      setShouldTriggerReload(true);
    }

    return actions;
  }, [userInfo]);

  function doMostObjectsHaveKey(objects, key) {
    const values = objects.map((object) => object[key] || "");
    const nonEmptyValues = values.filter((value) => value !== "");
    return nonEmptyValues.length / values.length >= 0.5;
  }

  const isChildSelected =
    (currentResults?.every((e) => e?.parent) &&
      doMostObjectsHaveKey(currentResults, "parent")) ||
    currentResults?.[0] === "Choose a tag";
  const childParent = currentResults.find((e) => e?.parent)?.parent;
  const parentName = actions?.find((e) => e?.id === childParent)?.name;
  const parentIcon = actions?.find((e) => e?.id === childParent)?.icons;

  const inputPlaceholder =
    childParent === "canvas" || childParent === "createCanvasInTag"
      ? "Canvas title"
      : childParent === "invites"
      ? "name@email.com"
      : childParent === "createANewTagFromCanvas" ||
        childParent === "tag"
      ? "Tag Title"
      : "Type ";

  useEffect(() => {
    if (projects?.length > 0) {
      const filteredActiveProjects = projects?.filter(
        (e) => e?.status === "active"
      );
      // console.log('FILTERED-PROJECT', filteredActiveProjects);
      const formattedActions = filteredActiveProjects?.map((e) => {
        return {
          id: e?.id,
          parent: "createCanvasInTag",
          name: e?.title ? e?.title : "A Canvas",
          section: "Choose a tag",
          perform: () => {
            navigate(`/tags/${e?.id}/canvases/new`, {
              state: {
                from: window.location.pathname,
              },
            });
          },
          emoji: e?.emoji ? e.emoji : "1f31f",
          isEnterKey: true,
        };
      });

      const newArr = uniqBy(
        insertArrayAtSpecificIndex(
          [...defaultActions, ...actions],
          3,
          formattedActions
        ),
        "id"
      );
      setActions(newArr);
      // setShouldTriggerReload(true)
      reloadSpotLight();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    // debugger;
    if (window.location.pathname.includes("/tags/") && projects?.length) {
      var url = window.location.pathname;
      var projectId = url.split("/")[2];
      const filteredActiveProjects = projects?.filter(
        (e) => e?.id === projectId
      )[0];
      // const tempDefault = [...defaultActions];
      // console.log(tempDefault);
      const updateActions = defaultActions.map((action) => {
        if (action.id === "createCanvas") {
          action.name =
            'Create canvas in "' + filteredActiveProjects?.title + '"';
        }
        return action;
      });
      // console.log('IFFFF_Task Found___', updateActions);
      const newArr = uniqBy(
        insertArrayAtSpecificIndex(
          [...defaultActions, ...actions],
          3,
          updateActions
        ),
        "id"
      );
      setActions(newArr);
      reloadSpotLight();
    } else {
      // console.log('default', defaultActions);
      const updateActions = defaultActions.map((action) => {
        if (action.id === "createCanvas") {
          action.name = "Create Canvas";
        }
        return action;
      });
      // console.log('ELSEEEEEE_Task Found___', updateActions);
      const newArr = uniqBy(
        insertArrayAtSpecificIndex(
          [...defaultActions, ...actions],
          3,
          updateActions
        ),
        "id"
      );
      setActions(newArr);
      reloadSpotLight();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  // Side Effects
  useEffect(() => {
    if (projects?.length === 0) {
      setActions(defaultActions);
      setShouldTriggerReload(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (sendInvite) sendInvitation();
  }, [sendInvite]);

  useEffect(() => {
    if (childParent === "createANewTagFromCanvas") reloadSpotLight();
  }, [childParent, setInputValue]);

  // Event Handlers/Callbacks

  const sendInvitation = async (e) => {
    dispatch(setInputValue(""));
    setSendInvite(false);

    if (workSpaceInfo && Object.keys(workSpaceInfo).length > 0) {
      const { data, error } = await createInvite(
        inputValue,
        "member",
        workSpaceInfo,
        userInfo.id
      );

      if (error) {
        return;
      }

      dispatch(setInvites([...invites, data]));
    } else {
      NotifierHelper.notify("info", "Please create workspace to invite");
    }
  };

  const onChangeInputQuery = (e) => {
    const value = e.target.value;
    dispatch(setInputValue(value));
  };

  if (
    location.pathname.includes("login") ||
    location.pathname.includes("register") ||
    location.pathname.includes("account-verification") ||
    location.pathname.includes("forgot-password") ||
    location.pathname.includes("reset-password")
  ) {
    return children;
  }

  // shouldTriggerReload &&
  return (
    <KBarProvider
      actions={uniqBy(actions, "id")}
      options={{
        toggleShortcut: "$mod+e",
        callbacks: {
          // onOpen: () => getProjects(),
          onClose: () =>
            setTimeout(() => {
              if (projects?.length !== 0) reloadSpotLight();
            }, 100),
        },
      }}
    >
      <KBarPortal>
        <KBarPositioner style={{ zIndex: 10 }}>
          <KBarAnimator style={animatorStyle}>
            {isChildSelected ? (
              <>
                <div style={childLabelStyle} className="child-label">
                  <div className="qtype-tile-square" style={{ opacity: "0.7" }}>
                    <img
                      src={listIcons[parentIcon]}
                      alt="item-icon"
                      width="100%"
                    />
                  </div>
                  <div style={{ marginLeft: "8px", opacity: "0.7" }}>
                    <span>{parentName}</span>
                    {/* <pre>{JSON.stringify(childParent, 0, 2)}</pre> */}
                  </div>
                </div>
                {/* May Need later */}
                <input
                  // id={isChildSelected}
                  type="text"
                  style={searchStyle}
                  value={inputValue}
                  autoFocus={isChildSelected}
                  onChange={onChangeInputQuery}
                  placeholder={inputPlaceholder}
                  autoComplete="off"
                  role="combobox"
                  spellCheck="false"
                  // aria-expanded="true"
                  // aria-controls="kbar-listbox"
                  // aria-activedescendant="kbar-listbox-item-10"
                />

                {/* <KBarSearch style={searchStyle} value={inputValue} autoFocus={isChildSelected}
                                onChange={onChangeInputQuery} placeholder={inputPlaceholder} /> */}

                {/* May Need later */}
              </>
            ) : (
              <KBarSearch style={searchStyle} />
            )}

            <RenderResults actions={actions} />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
      {children}
    </KBarProvider>
  );
};

export default Spotlight;
