import React, { useState, useMemo, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import { reduce } from "lodash";
import { isToday, isYesterday, format as formatDate } from "date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Emoji, EmojiStyle } from "emoji-picker-react";
import Select, { components } from "react-select";

import { ReactComponent as DueDate } from "./../../../assets/images/icons-mlp/calender-due.svg";
import { ReactComponent as Assignee } from "./../../../assets/images/icons-mlp/assignee.svg";
import avatar_placeholder from "./../../../assets/images/icons/logo.svg";
import { ReactComponent as AssetDismiss } from "./../../../assets/images/icons-mlp/x-dismiss.svg";

import "./task-card.scss";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSession } from "../../../store/reducers/sessionSlice";
import { GlobalHotKeys } from "react-hotkeys";
import AnalyticsHelper from "../../../application/common/AnalyticsHelper";

const CustomOption = ({ children, selectProps, ...props }) => {
  return (
    <components.Option
      {...props}
      className={`${
        props.isSelected
          ? "d-flex align-items-center justify-content-between flex-row"
          : ""
      }`}
    >
      {children}
      {props.isSelected && (
        <AssetDismiss
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            selectProps.onChange({ value: null });
          }}
        />
      )}
    </components.Option>
  );
};

const TaskCard = ({
  session,
  index,
  handleSessionFocus,
  projectId,
  isTask,
  handleChange,
  handleDateChange,
  handleSelectChange,
}) => {
  const workspaceMembers = useSelector((state) => state.members.workspace);
  const projects = useSelector((state) => state.project.projects);
  const dispatch = useDispatch();
  const [openDueDate, setOpenDueDate] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const location = useLocation();

  const keyMap = {
    assigneeHandler: "a",
    setDueDate: "d",
  };

  const keyHandlers = {
    setDueDate: (e) => {
      setOpenDueDate(true);
      e.preventDefault();
      return false;
    },

    assigneeHandler: (e) => {
      assigneeRef.current.focus();
      e.preventDefault();
      return false;
    },
  };

  const assigneeRef = useRef(null);

  const taskIntervals = useMemo(() => {
    const intervals = reduce(
      session.tasks,
      (result, value) => {
        return result.concat(value.timeIntervals);
      },
      []
    );
    return intervals;
  }, [session.tasks]);

  const end = useMemo(() => {
    const date = new Date(session.completedAt);

    if (isToday(date)) return "Today";
    if (isYesterday(date)) return "Yesterday";
    return session.completedAt ? formatDate(new Date(date), "d MMM") : "";
  }, [session.completedAt]);

  function formatOptionLabel({ label, avatar }) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          flex: 1,
        }}
      >
        <div
          className="img-icon me-2"
          style={{
            cursor: "pointer",
          }}
        >
          <img
            src={avatar || avatar_placeholder}
            className="user-dp"
            alt="User Profile"
          />
        </div>
        <div className="assignee-label select-label">{label}</div>
      </div>
    );
  }

  const focusField = () => {
    assigneeRef.current.focus();
  };

  return (
    <div className="position-relative ">
      {/* <div className="check-control lined-on-check">
        <input
          type="checkbox"
          className={"styled-checkbox mr-2 "}
          id={"task_" + session.status + "_" + session.id}
          name="isCompleted"
          defaultChecked={session.status === "completed" || false}
          onChange={(e) => handleChange(e, "main-checkbox", session)}
        />
        <label
          htmlFor={"task_" + session.status + "_" + session.id}
          className="task-label fw-bold"
        >
          &nbsp;
        </label>
      </div> */}

      <Link
        to={
          (session.project_id ? "/tags/" + session.project_id : "") +
          (isTask ? "/canvases/" : "/sessions/") +
          session.id
        }
        onClick={() => {
          AnalyticsHelper.trackEvent("Task Clicked", {
            taskId: session.id,
            title: session.title,
          });
          // AnalyticsHelper.smartLookTrackEvent('Task Clicked', {
          //   taskId: session.id,
          //   title: session.title
          // })
          dispatch(setActiveSession(session));
        }}
        state={{ from: location.pathname }}
        className="wrapper-card d-flex justify-content-between align-items-center pointer"
        id={"session_card_" + index}
        onMouseOver={(e) => {
          setIsHover(true);
          handleSessionFocus(e, "hover");
        }}
        onMouseLeave={() => setIsHover(false)}
        onFocus={() => setIsHover(true)}
        onBlur={() => setIsHover(false)}
      >
        <div>
          <h3 className="title fw-semi mb-0 fs-md">
            <span className="line-title-on-check">
              {session.title ? session.title : "A new canvas"}
            </span>
          </h3>
        </div>

        {/* <div className="session-timestamp me-4 pe-2">

            <div className="fs-md text-end">
              {secondsToTimestamp(reduce(taskIntervals, (total, interval) => total + parseInt(interval.duration), 0))}
            </div>

            <div className="session-secondary-dim fs-md">
              {session.lastWorked === session.createdAt ? 'Created' : 'Last worked'}
              : {formatDate(new Date(session.lastWorked), 'h:mm a')}
            </div>
          </div> */}
      </Link>

      <div className="action-controls">
        <div className="project me-4">
          {!projectId && session.project_id && (
            <div className="d-flex align-items-center mt-1">
              {/* <span className="dot dim-gray ms-3 me-2"></span> */}
              <div className="menu-item-icon me-2">
                <Emoji
                  unified={
                    projects?.find(
                      (project) => project.id === session.project_id
                    )?.emoji || "1f31f"
                  }
                  emojiStyle={EmojiStyle.APPLE}
                  size={16}
                />
              </div>
              <span className="session-secondary-dim">
                {
                  projects?.find((project) => project.id === session.project_id)
                    ?.title
                }
              </span>
            </div>
          )}
        </div>

        <div className="date me-1 d-flex justify-content-center">
          {!session.dueDate && (
            <div
              className="due-date-icon"
              onClick={(e) => setOpenDueDate(true)}
            >
              <DueDate />
            </div>
          )}
          <div
            className={
              "mikyal-selector session-due-date mt-1 ms-1 " +
              (!session.dueDate ? "d-none" : "")
            }
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                variant="dialog"
                emptyLabel="+ Set Due Date"
                format="dd MMM"
                className="session-due-date"
                value={session.dueDate || null}
                // defaultValue={null}
                onChange={(date) =>
                  handleDateChange(date, "milestone", session)
                }
                disableToolbar={true}
                cancelLabel=""
                okLabel="Done"
                open={openDueDate}
                onClose={() => setOpenDueDate(false)}
                onOpen={() => setOpenDueDate(true)}
                clearable={true}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <div className="assignee ms-4">
          {/* {session.assignee} */}
          {!session.assignee && (
            <div className="assignee-icon pointer" onClick={focusField}>
              <Assignee />
            </div>
          )}
          <div
            className={
              "mikyal-selector session-due-date mb-1 " +
              (!session.assignee ? "d-none-a" : "")
            }
          >
            <Select
              options={workspaceMembers.map((member) => ({
                label: member.username,
                value: member.user_id,
                avatar: member.avatar,
              }))}
              className="mikyal-select assignee assignee-minimal concise w-100 text-sm"
              classNamePrefix="mikyal-select"
              formatOptionLabel={formatOptionLabel}
              placeholder=""
              onMenuClose={() => {
                assigneeRef.current.blur();
                document
                  .querySelector("#session_card_" + session.index)
                  .focus();
              }}
              onChange={(e) => {
                setIsHover(false);
                if (e.value === session.assignee) {
                  handleSelectChange({ value: null }, "assignee", session);
                  return;
                }
                handleSelectChange(e, "assignee", session);
              }}
              menuPlacement="auto"
              value={
                workspaceMembers
                  ? workspaceMembers
                      ?.filter((member) => member.user_id === session.assignee)
                      .map((member) => ({
                        label: member.username,
                        value: member.user_id,
                        avatar: member.avatar,
                      }))
                  : null
              }
              ref={assigneeRef}
              openMenuOnFocus={true}
              isSearchable={false}
              components={{ Option: CustomOption }}
            />
          </div>
        </div>
      </div>

      {isHover && (
        <GlobalHotKeys
          keyMap={keyMap}
          handlers={keyHandlers}
          allowChanges={true}
        />
      )}
    </div>
  );
};

export default TaskCard;
