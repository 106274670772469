import { getTimeAgo } from "../../../application/common/helper";
import { Mention, MentionsInput } from "react-mentions";
import "./task-view.scss";
// import Replies from "../Replies";

const defaultAvatar = "https://i.ibb.co/JFR6LyW/user.png";

export default function TaskComments({ comments }) {
  // const handleAddReplies = (commentId, replies) => {
  //   const updatedComments = comments.map((comment) => {
  //     if (comment.id === commentId) {
  //       return { ...comment, replies };
  //     }
  //     return comment;
  //   });
  //   updateComment(updatedComments);
  // };

  return (
    <div className="d-flex flex-column gap-3 overflow-auto comment-wrapper">
      {comments?.map((comment) => (
        <div className="comment-card d-flex flex-column gap-2" key={comment.id}>
          <div className="user-and-time d-flex align-items-center gap-2">
            <div className="dp-user">
              <img
                src={comment.user?.avatar || defaultAvatar}
                alt="User Profile"
              />
            </div>
            <span className="user-name text-truncate" title={comment.user.name}>
              {comment.user.name}
            </span>
            <span
              className="time text-truncate"
              title={getTimeAgo(comment.createdAt)}
            >
              {getTimeAgo(comment.createdAt)}
            </span>
          </div>
          <div className="comment-body">
            <MentionsInput className="pill-input" disabled value={comment.body}>
              <Mention
                displayTransform={(_, display) => `@${display}`}
                markup="@[__display__](user:__id__)"
                className="mention"
              />
            </MentionsInput>
          </div>

          {/* {comment?.replies && comment?.replies?.length > 0 && (
            <Replies
              replies={comment?.replies}
              replyCount={comment?.reply_count}
              commentId={comment.id}
              workSpaceId={comment.workspace_id}
              addReplies={handleAddReplies}
            />
          )} */}
        </div>
      ))}
    </div>
  );
}
