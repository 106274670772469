import axios from 'axios';
import Globals from '../../application/common/Globals';

class AuthService {
    register(data) {
        return axios.post(Globals.register, data);
    }

    login(data) {
        return axios.post(Globals.login, data);
    }

    verifyUser(token) {
        return axios.put(Globals.verifyEmail + '/' + token);
    }

    forgotPassword(data) {
        return axios.post(Globals.forgotPassword, data);
    }

    resetPassword(token, data) {
        return axios.put(Globals.resetPassword + '/' + token, data);
    }

    myDetails() {
        return axios.get(Globals.me, Globals.httpOptions);
    }

    updateUserInfo(data) {
        if (data.type === 'password') {
            return axios.post(Globals.updatePassword, data, Globals.httpOptions);
        } else {
            return axios.post(Globals.updateInfo, data, Globals.httpOptions);
        }
    }

    updateWorkspaceInfo(data) {
        return axios.post(Globals.saveWorkspace, data, Globals.httpOptions);
    }

    getUserDetail(params = null) {
        const queryString = '?' + new URLSearchParams(params).toString();
        return axios.get(Globals.userDetail + queryString, Globals.httpOptions);
    }
}

export default new AuthService();