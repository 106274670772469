import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $wrapNodeInElement } from "@lexical/utils";
import {
  $createParagraphNode,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from "lexical";
import { useEffect, useState } from "react";
import * as React from "react";

import { $createPollNode, createPollOption, PollNode } from "../nodes/PollNode";

export const INSERT_POLL_COMMAND = createCommand("INSERT_POLL_COMMAND");

export function InsertPollDialog({ activeEditor, onClose }) {
  const [question, setQuestion] = useState("");

  const onClick = () => {
    activeEditor.dispatchCommand(INSERT_POLL_COMMAND, question);
    onClose();
  };

  return (
    <>
      <input
        placeholder="Enter your question"
        className="form-control mikyal-field"
        onChange={(e) => setQuestion(e.target.value)}
        value={question}
        autoFocus
      />
      <div className="d-flex flex-row justify-content-end gap-2">
        <button
          className="mikyal-btn secondary-button"
          data-test-id="image-modal-file-upload-btn"
          onClick={() => onClose()}
        >
          Cancel
        </button>
        <button
          className="mikyal-btn black-button"
          data-test-id="image-modal-confirm-btn"
          disabled={question.trim() === ""}
          onClick={() => onClick()}
        >
          Confirm
        </button>
      </div>
    </>
  );
}

export default function PollPlugin() {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (!editor.hasNodes([PollNode])) {
      throw new Error("PollPlugin: PollNode not registered on editor");
    }

    return editor.registerCommand(
      INSERT_POLL_COMMAND,
      (payload) => {
        const pollNode = $createPollNode(payload, [
          createPollOption(),
          createPollOption(),
        ]);
        $insertNodes([pollNode]);
        if ($isRootOrShadowRoot(pollNode.getParentOrThrow())) {
          $wrapNodeInElement(pollNode, $createParagraphNode).selectEnd();
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);
  return null;
}
