export const SUPABASE_TABLE_NAME = {
  WORKSPACE: 'workspace',
  PROFILES: 'profiles',
  PROJECT: 'project',
  TASK: 'task',
  INVITES: 'invites',
  WAITLIST: 'waitlist',
  WORKFLOW_GROUPS: 'workflow_groups',
  WORKFLOW_GROUP_STATUS: 'workflow_group_status',
  COMMENTS: 'comments',
  REPLIES: 'replies'
}

export const SUPABASE_BUCKET_NAME = {
  WORKSPACE_LOGO: 'workspace-logo',
  USER_AVATAR: 'user-avatar',
  TASK_IMAGES: 'task-images',
  COMMENT_FILES: 'comment-files'
}

export const SUPABASE_RPC = {
  GET_COMMENTS: 'get_comments',
  GET_REPLIES: 'get_replies',
  GET_COMMENTS_BY_TASK: 'get_comments_by_task'
}