const secondsToTimestamp = (totalSeconds, formatted) => {
  const hours = Math.floor(totalSeconds / 3600).toLocaleString({}, { minimumIntegerDigits: 2 });
  const minutes = Math.floor((totalSeconds % 3600 / 60)).toLocaleString({}, { minimumIntegerDigits: 2 });
  const seconds = (totalSeconds % 60).toLocaleString({}, { minimumIntegerDigits: 2 });

  if (formatted) {
    return `${hours !== '00' ? (hours + 'h ') : ''}${minutes}m ${seconds}s`
  } else {
    return `${hours !== '00' ? (hours + ':') : ''}${minutes}:${seconds}`
  }
}

export default secondsToTimestamp;

// const secondsToTimestamp = (totalSeconds) => {
//   const minutes = Math.floor(totalSeconds / 60).toLocaleString({}, { minimumIntegerDigits: 2 });
//   const seconds = (totalSeconds - minutes * 60).toLocaleString({}, { minimumIntegerDigits: 2 });

//   return `${minutes}:${seconds}`
// }

// export default secondsToTimestamp