import React, { useContext, useEffect, useState } from 'react';
import { GlobalHotKeys } from "react-hotkeys";

import { NavLink, Link, useNavigate } from "react-router-dom";

// import asset_user_placeholder from "./../../../assets/images/dummyDP.jpeg";
import { Emoji, EmojiStyle } from "emoji-picker-react";
import {
    useKBar
} from "kbar";


import { ReactComponent as Workspace } from "./../../../assets/images/icons/logo.svg";
import { ReactComponent as CheckCircle } from "./../../../assets/images/icons-mlp/check-circle.svg";
import { ReactComponent as AllProjects } from "./../../../assets/images/icons-mlp/file-text.svg";
import { ReactComponent as ArrowDown } from "./../../../assets/images/icons-mlp/arrow-down.svg";
import { ReactComponent as Settings } from "./../../../assets/images/icons-mlp/settings.svg";
import { ReactComponent as Logout } from "./../../../assets/images/icons-mlp/log-out.svg";
// import { ReactComponent as PlaceholderGradient } from "./../../../assets/images/icons/logo.svg";
import { ReactComponent as CmdBtn } from "./../../../assets/images/icons-mlp/cmd.svg";
import { ReactComponent as Bell } from "./../../../assets/images/icons-mlp/bell.svg";
import { ReactComponent as OrangeDot } from "./../../../assets/images/icons/orange-dot.svg";


import './sidenav.scss';
import { useDispatch, useSelector } from 'react-redux';
import { resetUserState } from '../../../store/reducers/userSlice';
import { resetSessionState } from '../../../store/reducers/sessionSlice';
import { resetProjectState, setActiveProject } from '../../../store/reducers/projectSlice';
import { setDropDown } from '../../../store/reducers/dropDownSlice';
import WebContext from '../../../application/contexts/WebContext';
import { toInteger } from 'lodash';
import NotifierHelper from '../../../application/common/NotifierHelper';
import supabase from '../../../infrastructure/Supabase';
import { resetMemberState } from '../../../store/reducers/membersSlice';

const SideNav = () => {
    const navigate = useNavigate();
    const { actions} = useContext(WebContext)
    const [focusIndex, setFocusIndex] = useState(-1);
    const [isUnseenComment, setIsUnseenComment] = useState(false)

    const userInfo = useSelector(state => state.user.userInfo);
    const workSpaceInfo = useSelector(state => state.user.workSpaceInfo);
    const projects = useSelector(state => state.project.projects);
    const comments = useSelector(state => state.comment.comments);
    const dispatch = useDispatch();

    // const [projects, setProjects] = useState([]);
    const { query } = useKBar();

    useEffect(() => {
        const filtered = comments.filter((noti) => !noti.seen_user_ids.includes(userInfo.id) && noti.created_by !== userInfo.id);
        setIsUnseenComment(filtered.length > 0);
    }, [comments])

    useEffect(() => {
        query?.registerActions(actions)
    }, [actions])

    const keyMap = {
        projectDown: "ctrl+down",
        projectUp: "ctrl+up"
    };

    const keyHandlers = {
        projectUp: (e) => {
            handleTaskControl(e, 'up');
            e.preventDefault();
            return false;
        },

        projectDown: (e) => {
            handleTaskControl(e, 'down');
            e.preventDefault();
            return false;
        },
    }

    const handleTaskControl = (e, direction) => {
        let filterProject = [];
        if (direction === "down" || direction === "up") {
            filterProject = projects?.filter(proj => proj.status === 'active');
        }
        
        if (direction === "down") {
            if (focusIndex < filterProject.length - 1) {
                document.querySelector("#sidebar_project_" + (focusIndex + 1)).focus();
                setFocusIndex(focusIndex + 1);
            } else {
                setFocusIndex(0);
                document.querySelector("#sidebar_project_0").focus();
            }
        }

        if (direction === "up") {
            if (focusIndex > 0) {
                setFocusIndex(focusIndex - 1);
                document.querySelector("#sidebar_project_" + (focusIndex - 1)).focus();
            } else {
                setFocusIndex(filterProject.length - 1);
                document
                    .querySelector("#sidebar_project_" + (filterProject.length - 1))
                    .focus();
            }
        }

        if (direction === "hover" && e.target.id.includes("sidebar_project_")) {
            var index = e.target.id.lastIndexOf("_");
            var result = e.target.id.substr(index + 1);
            setFocusIndex(toInteger(result));
            document.querySelector("#sidebar_project_" + result).focus();
        }
    }

    // const initialUserinfo = localStorage.getItem('login_user');
    // const [selfDetail, setSelfDetail] = useState(JSON.parse(initialUserinfo));

    const userMenu = [
        { title: 'Updates', link: 'updates', icon: <Bell /> },
        { title: 'Canvases', link: 'home', icon: <CheckCircle /> },
        { title: 'Tags', link: 'tags', icon: <AllProjects /> },
        // { title: 'Home', link: 'home' },
        // { title: 'Projects', link: 'projects' },
    ]

    const handleLogout = async () => {
        const { error } = await supabase.auth.signOut()
        // AnalyticsHelper.smartLookTrackEvent('user_loggedOut', {
        //     userId: userInfo.id,
        //     email: userInfo.email,
        //     type: userInfo.type
        // })

        if(error) {
            NotifierHelper.notify('info', error.message)
            return;
        }
        
        supabase.removeAllChannels()
        dispatch(resetUserState());
        dispatch(resetSessionState())
        dispatch(resetProjectState())
        dispatch(setDropDown({}))
        dispatch(resetMemberState())
        window.analytics.reset()
        NotifierHelper.notify('logout', "Logout successfully!")
        localStorage.removeItem('auth_token');
        navigate('/login');
    }

    return (
        <div className="nav-wrapper">

            <div className="side-bar expanded mikyal-scrollbar d-flex flex-column px-3 py-3 mt-3">
                <div className="side-nav-header mt-2 mx-auto">
                    {/* <Link to="/home" tabIndex="-1">
                        <Logo />
                    </Link> */}
                    <div className="workspace">
                        <div className="info">
                            <div className="logo">
                                {workSpaceInfo?.logo ?
                                    <img src={workSpaceInfo.logo} alt="Workspace Logo" />
                                    : <Workspace />}
                            </div>

                            <div className="ms-2 fs-md">{workSpaceInfo?.title || 'My Workspace'}</div>
                        </div>

                        <div className="profile">
                            <div className="d-flex align-items-center justify-content-center position-relative">
                                <div className="dd-action action-dots dropdown" placement="left">
                                    <div className="action-dots dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="user-avatar text-uppercase">
                                                <div className="dp-user">

                                                    {userInfo?.avatar ?
                                                        <img src={userInfo.avatar || Workspace} alt="User Profile" />
                                                         :<Workspace /> 
                                                    } 
                                                </div>
                                                <span className="ms-1 arrow-down"><ArrowDown /></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="dd-data sidenav-dd dropdown-menu dropstart" aria-labelledby="dropdownMenuButton1">
                                        <Link to="/settings" className="dd-item">
                                            <span className="me-2"><Settings /></span>
                                            Settings
                                        </Link>
                                        <div className="dd-item red" onClick={() => handleLogout()}>
                                            <span className="me-2"><Logout /></span>
                                            Logout
                                        </div>
                                    </div>
                                </div>
                                {/* User Online Status */}
                                {/* <div className="active-status"></div> */}
                            </div>
                        </div>


                    </div>
                </div>

                <div className="quick-actions mt-4">
                    <div className="action-item">
                        <button className="mlp-btn large no-wrap w-100 d-flex align-items-center justify-content-between" onClick={() => query.toggle()}>
                            Quick Actions
                            <span className="mlp-shortcut-tag ml cmd">
                                <CmdBtn />
                                E</span>
                        </button>
                    </div>
                </div>

                <div className="menu-items mt-4">
                    {(userInfo?.type !== 'super') ?
                        <div className="sidebar-menu">
                            {userMenu?.map((item, index) => (
                                <div className="menu-item" key={index}>
                                    <NavLink end to={item.link} className="">
                                        <div className="menu-item-icon me-2">
                                            {item.icon}
                                            {item.title.toLowerCase() === "updates" && isUnseenComment && <OrangeDot className='notification-dot' />}
                                        </div>
                                        <span className="ms-1">{item.title}</span>
                                    </NavLink>
                                </div>
                            ))}
                        </div>
                        : null}
                    
                    {process.env.REACT_APP_ADMIN_EMAILS.includes(userInfo?.email) ?
                        <div className="sidebar-menu">
                            <div className="menu-item">
                                <NavLink end to={'invites'} className="">
                                    <div className="menu-item-icon me-2"> <CheckCircle /></div>
                                    <span className="ms-1">Invites</span>
                                </NavLink>
                            </div>
                        </div>
                        : null}
                </div>

                <div className="menu-section mt-4">
                    <div className="title">Tags</div>

                    <div className="menu-items mt-2">
                        {Array.isArray(projects) ?

                            projects?.some(proj => proj.status === 'active') ?
                                <div className="sidebar-menu">
                                    {projects?.filter(proj => proj.status === 'active').map((project, index) => {
                                        return <div className="menu-item" key={index}>
                                                <NavLink
                                                    to={('/tags/' + project.id)}
                                                    onClick={() => dispatch(setActiveProject(project))}
                                                    className=""
                                                    id={`sidebar_project_${index}`}
                                                    onMouseOver={e => handleTaskControl(e, 'hover')}
                                                    onMouseLeave={() => {
                                                        document.querySelector("#sidebar_project_" + index).blur();
                                                    }}
                                                >
                                                    <div className="menu-item-icon me-2">
                                                        <Emoji
                                                            unified={project.emoji || '1f31f'}
                                                            emojiStyle={EmojiStyle.APPLE}
                                                            size={16}
                                                        />
                                                    </div>
                                                    <span className="ms-1">{project.title ? project.title : 'A Project'}</span>
                                                </NavLink>
                                            </div>
                                    }
                                    )}
                                </div>
                                : null
                            : null}
                    </div>
                </div>


                {/* <div className="pt-5 pb-4">

                    <div className="navbar-profile">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="dd-action action-dots dropdown" placement="left">
                                <div className="action-dots dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="user-avatar text-uppercase">
                                            <img src={userInfo?.dp?.type === 'custom' ? (userInfo.dp.file) : asset_user_placeholder} alt="User Profile" />
                                        </div>
                                    </div>
                                </div>

                                <div className="dd-data sidenav-dd dropdown-menu dropstart" aria-labelledby="dropdownMenuButton1">
                                    <Link to="/profile" className="dd-item">
                                        View Profile
                                    </Link>
                                    <div className="dd-item" onClick={handleLogout}>
                                        Logout
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> */}
            </div>
            <GlobalHotKeys
                keyMap={keyMap}
                handlers={keyHandlers}
                allowChanges={true}
            >
            </GlobalHotKeys>
        </div>
    );
};

export default SideNav;