import React, { useMemo, useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { isEqual, isUndefined, map, reduce, orderBy, toInteger, cloneDeep } from "lodash";
import { addSeconds, format as formatDate } from "date-fns";
import axios from 'axios';
import Select from "react-select";

import { GlobalHotKeys } from "react-hotkeys";
import TextareaAutosize from 'react-textarea-autosize';

import secondsToTimestamp from "../../../application/utils/secondsToTimestamp";
import SessionTask from "./SessionTask";
import CircleProgress from "../CircleProgress";
import SessionsApi from "../../ApiServices/SessionsApi";
import NotifierHelper from "../../../application/common/NotifierHelper";

import asset_enter from './../../../assets/images/icons/enter-icon.svg';

import "./session-focus.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSessions } from "../../../store/reducers/sessionSlice";
import Loader from "../MasterLoader";

const SessionFocus = () => {
  let { sessionId, projectId } = useParams();
  const navigate = useNavigate();

  const sessions = useSelector(state => state.session.sessions);
  const activeSession = useSelector(state => state.session.activeSession);
  const dropDownData = useSelector(state => state.dropdown.dropDownData)
  const dispatch = useDispatch();

  const [milestone, setMilestone] = useState({});
  const [taskBeingTimed, setTaskBeingTimed] = useState()
  const [isWaiting, setIsWaiting] = useState(false);
  const [taskControl, setTaskControl] = useState(-1);
  const [trigger, setTrigger] = useState({ item: -1, count: 0 });
  const [clearDelay, setClearDelay] = useState();

  const milestoneRef = useRef(milestone)
  milestoneRef.current = milestone;

  const keyMap = {
    controlDown: "down",
    controlUp: "up",
    tabControl: "tab",
    combination: "cmd+h",
    checkUncheck: 'c',
    goBack: "esc",
    newTask: "enter",
    enterNotes: "/",
  };

  const keyHandlers = {

    controlUp: (e) => {
      handleTaskControl(e, 'up');
      e.preventDefault();
      return false;
    },

    controlDown: (e) => {
      handleTaskControl(e, 'down');
      e.preventDefault();
      return false;
    },

    tabControl: (e) => {
      handleTaskControl(e, 'down');
      e.preventDefault();
      return false;
    },

    combination: (e) => {
      e.preventDefault();
      if (taskControl > -1) {
        handleTimerShortcut(e, 'timer');
      } else {
        NotifierHelper.notify('info', "Focus on task first");
      }
      return false;
    },


    checkUncheck: (e) => {
      handleCheckUncheck(e);
      e.preventDefault();
      return false;
    },

    goBack: (e) => {
      if (taskBeingTimed) return;

      if (projectId) {
        navigate('/tags/' + projectId);
      } else {
        navigate('/home');
      }
      e.preventDefault();
      return false;
    },

    newTask: (e) => {
      handleTask('add')
      e.preventDefault();
      return false;
    },

    enterNotes: (e) => {
      document.querySelector("#notes").focus();
      // setTaskControl(-1);
      e.preventDefault();
      return false;
    },
  };

  useEffect(() => {
    if (sessionId === 'new') {
      document.querySelector("#ms_input_title").focus();
      newSession();
    } else {
      if (Object.keys(activeSession).length !== 0) {
        setMilestone(activeSession);
        // document.querySelector("#ms_input_title").focus();
      } else {
        // getSession();
        loadSessions();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(milestone).length !== 0) {
      syncSessions();
      clearTimeout(clearDelay);
      setClearDelay(
        setTimeout(() => {
          onSaveSession(milestone);
        }, 1000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestone]);

  const newSession = () => {
    SessionsApi.newSession()
      .then(res => {
        if (res.data.session && window.location.pathname.includes('canvases/new')) {
          let temp = { ...milestoneRef.current };
          Object.assign(res.data.session, temp);
          setMilestone({ ...res.data.session, grouping: formatDate(new Date(res.data.session.lastWorked), 'yyy-MM-dd'), project: (projectId ? projectId : null) });

          if (projectId) {
            navigate(`/tags/${projectId}/sessions/${res.data.session._id}`);
          } else {
            navigate(`/canvases/${res.data.session._id}`);
          }
        }

      })
      .catch(err => {
        // setIsWaiting(false);
        NotifierHelper.notify('info', "Something went wrong, please try again");
      });
  };

  const loadSessions = () => {
    setIsWaiting(true);
    axios.all(SessionsApi.sessionsAlongSession(sessionId))
      .then(axios.spread(function (sessions, session) {
        dispatch(setSessions(sessions.data.result))

        setTimeout(() => {
          setMilestone(session.data.result);
          // document.querySelector("#ms_input_title").focus();
        });
        setIsWaiting(false);
      }))
      .catch(err => {
        setIsWaiting(false);
        if (err.response.status === 404) {
          NotifierHelper.notify('info', "Session not found");
          navigate('/sessions');
        } else {
          NotifierHelper.notify('info', "Something went wrong, please try again");
        }
      });
  }

  const onSaveSession = (newSession) => {
    delete newSession.__v;
    if (!newSession._id) {
      return;
    }

    SessionsApi.saveSession(newSession)
      .then(res => {
        // console.log('SESSION_SAVED...', res);
      })
      .catch(err => {
        // NotifierHelper.notify('info', "Something went wrong with saving, your changings are not sync");
      });
  };

  const syncSessions = () => {
    const temp = cloneDeep(sessions);
    // debugger;
    if (sessions.some(sess => sess._id === milestone._id)) {
      if (!milestone.title && !milestone.tasks.length) {
        temp.splice(temp.findIndex(el => el._id === milestone._id), 1);
      } else {
        temp[temp.findIndex(el => el._id === milestone._id)] = milestone;
      }
      dispatch(setSessions(orderBy(temp, d => new Date(d.lastWorked), 'desc')))
    } else {
      if ((milestone.title || milestone.tasks.length) && milestone._id) {
        const tempSession = { ...milestone, grouping: formatDate(new Date(milestone.lastWorked), 'yyy-MM-dd') };
        temp.unshift(tempSession)
        dispatch(setSessions(temp))
      }
    }

  }

  const handleTaskCompleteChange = (newTask) => {
    const newTasks = map(milestone.tasks, (task) => {
      return isEqual(task._id, newTask.id) ? newTask : task
    }
    );
    setMilestone({
      ...milestone,
      tasks: newTasks,
    });
  };

  const handleTimerStart = (task, tIndex) => {
    setTaskControl(tIndex);
    setTaskBeingTimed(task.id)
  }

  const handleTimerShortcut = (e, action) => {
    let activeTask = milestone.tasks[taskControl];
    activeTask.id = activeTask._id ? activeTask._id : activeTask.identityCode;
    setTrigger({ item: taskControl, count: (trigger.count + 1) });
  }

  const handleCheckUncheck = (e) => {
    if (taskBeingTimed) return;
    if (taskControl < 0) return;
    let temp = { ...milestone };
    temp.tasks[taskControl].isCompleted = !temp.tasks[taskControl].isCompleted;
    calcMilestoneProgress(temp);
    setMilestone(temp);
    const taskCheckbox = document.querySelector("#task_" + (taskControl));
    taskCheckbox.checked = !taskCheckbox.checked;
  }

  const taskIntervals = useMemo(() => {
    const intervals = reduce(milestone.tasks, (result, value) => {
      return result.concat(value.timeIntervals)
    }, []);
    return intervals
  }, [milestone.tasks]);

  const handleChange = (e, item = null, msIndx = null, tIndex = null, stIndex = null) => {
    const { name, value, checked } = e.target;
    if (item === 'milestone') {
      setMilestone({ ...milestone, [name]: value });
    }
    else if (item === 'task-checkbox') {
      if (taskBeingTimed) return;
      let temp = { ...milestone };
      temp.tasks[tIndex].isCompleted = checked;
      calcMilestoneProgress(temp);
      // temp.milestones.forEach(ms => { ms.current = calcMilestoneProgress(ms) });
      setMilestone(temp);
    }
    else if (item === 'task') {
      let temp = { ...milestone };
      temp.tasks[tIndex][name] = value;
      // calcMilestoneProgress(temp);
      // temp.milestones.forEach(ms => { ms.current = calcMilestoneProgress(ms) });
      setMilestone(temp);
    }
    // else if (item === 'sub-task') {
    //     let temp = { ...project };
    //     temp.milestones[msIndx].tasks[tIndex].subTasks[stIndex][name] = value;
    //     setProject(temp);
    // } else if (item === 'subtask-checkbox') {
    //     let temp = { ...project };
    //     temp.milestones[msIndx].tasks[tIndex].subTasks[stIndex].isCompleted = checked;
    //     temp.milestones.forEach(ms => { ms.current = calcMilestoneProgress(ms) });
    //     setProject(temp);
    // } 
    else {
      setMilestone({ ...milestone, [name]: value });
    }

  }

  const handleSelect = (data, type) => {
    console.log(type, data);

    const projectDetail = [{ title: data.label }]
    setMilestone({ ...milestone, project: data.value, projectDetail });
  }

  const handleKeyPress = (e, item = null, msIndx = null, tIndex = null, stIndex = null, taskData = null) => {
    if (e.key === 'h' && e.metaKey) {
      e.preventDefault();
      if (taskControl > -1) {
        handleTimerShortcut(e, 'timer');
      } else {
        NotifierHelper.notify('info', "Focus on task first");
      }
      return false;
    }
    if (item === 'milestone' && e.key === 'Enter') {
      if (milestone.tasks.length && !milestone.tasks[milestone.tasks.length - 1].title) {
        document.querySelector("#t_input_" + (milestone.tasks.length - 1)).focus();
      } else {
        handleTask('add');
      }
    }

    if (item === 'task' && e.key === 'Enter') {
      handleTask('add');
      setTaskControl(milestone.tasks.length - 1);
    }

    if (e.key === 'Backspace' && !e.target.value) {
      e.preventDefault();
      if (item === 'milestone') {
        // handleMilestone('remove', msIndx);
        // const previousMilestone = "#ms_input_" + (msIndx-1);
        // document.querySelector(previousMilestone).focus();
      } else if (item === 'task') {
        handleTask('remove', tIndex);
        if (!milestone.tasks.length) {
          // document.querySelector("#ms-accordion-" + msIndx).classList.remove('show');
          // document.querySelector("#heading-ms-" + msIndx + " .collapse-arrow").classList.add('collapsed');
          document.querySelector("#ms_input_title").focus();
        }
      }
      // else {
      //     handleSubTask('remove', msIndx, tIndex, stIndex);
      //     handleTask(msIndx, 'add', 0, e.target.value);
      //     // if (!project.sessions[msIndx].tasks[tIndex].subTasks.length) {
      //     //     document.querySelector("#t_input_" + msIndx + "_" + tIndex).focus();
      //     // }
      // }
    }

    if (item === 'task' && e.key === 'Tab') {

      // if (project.sessions[msIndx].tasks.length) {
      //     handleSubTask('add', msIndx, (tIndex-1), 0, e.target.value);
      //     handleTask(msIndx, 'remove', tIndex);
      // }
      if (tIndex >= (milestone.tasks.length - 1)) {
        handleTaskControl(e, 'down');
        // return false;
      }
      setTaskControl(tIndex);
      document.querySelector("#t_input_" + (tIndex)).blur();
      // document.querySelector("#t_input_" + (tIndex + 1)).focus();
      e.preventDefault();
      return false;
    }

    if (item === 'milestone' && e.key === 'ArrowDown') {
      document.querySelector("#ms_input_title").blur();
      setTaskControl(0);
      e.preventDefault();
      return false;
    }

    if (item === 'milestone' && e.key === 'Tab') {
      // document.querySelector("#ms_input_title").blur();
      // setTaskControl(0);
      document.querySelector("#t_input_0").focus();
      e.preventDefault();
      return false;
    }

    if (item === 'task' && e.key === 'Escape') {
      if (taskBeingTimed) return;
      if (tIndex >= (milestone.tasks.length - 1)) {
        handleTaskControl(e, 'down');
      }
      setTaskControl(tIndex);
      document.querySelector("#t_input_" + (tIndex)).blur();
      e.preventDefault();
      return false;
    }

    if (item === 'milestone' && e.key === 'Escape') {
      document.querySelector("#ms_input_title").blur();
      setTaskControl(0);
      e.preventDefault();
      return false;
    }

    if (item === 'notes' && e.key === 'Escape') {
      document.querySelector("#notes").blur();
      // setTaskControl(0);
      e.preventDefault();
      return false;
    }

    if (item === 'title-focus') {
      setTaskControl(-1);
    }
  }

  const handleTaskControl = (e, action) => {
    if (taskBeingTimed) return;

    if (action === 'down') {
      console.log('DOWN-CONDITION...', taskControl < (milestone?.tasks?.length - 1));
      if (taskControl < (milestone?.tasks?.length - 1)) {
        setTaskControl(taskControl + 1);
      } else {
        setTaskControl(0);
      }
    }

    if (action === 'up') {
      if (taskControl > 0) {
        setTaskControl(taskControl - 1);
      } else {
        setTaskControl(milestone?.tasks?.length - 1);
      }
    }
    if (action === 'hover' && e.target.id.includes('task_control_')) {
      var index = e.target.id.lastIndexOf("_");
      var result = e.target.id.substr(index + 1);
      if (taskControl !== toInteger(result)) {
        const elements = document.querySelectorAll('[id^="t_input"]');
        elements.forEach(el => {
          el.blur();
        })
      }
      setTaskControl(toInteger(result));
      document.querySelector("#ms_input_title").blur();
    }
  }

  const deactiveControl = () => {
    if (taskBeingTimed) return;
    setTaskControl(-1);
  }

  const handleTask = (action, tIndex = null, tValue = null) => {
    if (taskBeingTimed) return;
    let temp = { ...milestone };
    if (action === 'add') {
      temp.tasks.push({
        title: tValue ? tValue : '',
        description: '',
        dueDate: null,
        identityCode: Math.random().toString(36).replace(/[^a-z]+/g, ''),
        isCompleted: false,
        focus: true,
        subTasks: [],
        timeIntervals: []
      });
      setTaskControl(temp.tasks.length - 1);
    }
    if (action === 'remove') {
      temp.tasks.splice(tIndex, 1);
      if (temp.tasks.length) {
        const previousTask = "#t_input_" + (tIndex - 1);
        setTaskControl(temp.tasks.length - 1);
        document.querySelector(previousTask).focus();
      }
    }
    calcMilestoneProgress(temp);
    setMilestone(temp);
  }

  const calcMilestoneProgress = (milestone) => {
    if (!milestone?.tasks?.length) return 0;
    const completed = milestone.tasks.filter(item => item.isCompleted).length;
    return completed;
  }

  const handleAddTimeInterval = (newTask, startTime, duration) => {
    setTaskBeingTimed(undefined)
    const interval = {
      start: new Date(startTime).toISOString(),
      end: new Date(addSeconds(startTime, duration)).toISOString(),
      duration
    }
    const newTasks = map(milestone.tasks, (task) => {
      const taskId = task._id ? task._id : task.identityCode;
      return isEqual(taskId, newTask.id) ? { ...newTask, timeIntervals: newTask.timeIntervals.concat(interval) } : task
    }
    )
    setMilestone({
      ...milestone,
      lastWorked: new Date(startTime).toISOString(),
      tasks: newTasks,
    });
  }

  return (
    <div className="position-relative min-vh-100">
      {isWaiting &&
        <Loader />
      }
      <div className="row">
        <div className="col-12">
          {taskControl > -1 ?
            <div className="deactivate-control" onClick={deactiveControl}></div>
            : null}
          <div className="session-focus">
            <div className="session-content justify-content-between">
              <div>
                <div className="mikyal-selector project-selection d-flex mb-4">
                  <span className="text-muted">Project:</span>
                  <Select
                    options={dropDownData?.projects ? dropDownData.projects : []}
                    className="mikyal-select concise w-100"
                    classNamePrefix="mikyal-select"
                    placeholder="+ Choose Project"
                    onChange={e => handleSelect(e, 'project')}
                    value={(dropDownData?.projects ? dropDownData.projects.filter(opt => opt.value === milestone.project) : null)}
                  />
                </div>

                <div className="meta">
                  <div className="circle-progress position-relative">
                    <CircleProgress current={milestone.current ? milestone.current : calcMilestoneProgress(milestone)} total={milestone?.tasks?.length} color={'orange'} stroke="15" />
                  </div>

                  <div className="heading h5 mb-0 w-100 d-flex">
                    <input
                      id={'ms_input_title'}
                      type="text"
                      className="mikyal-borderless-field px-0 ms-input session-title"
                      name="title"
                      placeholder="What are you working on?"
                      value={milestone.title || ''}
                      onChange={(e) => handleChange(e, 'milestone')}
                      onKeyDown={(e) => handleKeyPress(e, 'milestone')}
                      onFocus={(e) => handleKeyPress(e, 'title-focus')}
                    />

                    <div className="ms-timer d-flex align-items-center no-wrap">
                      <div className="timer">
                        {secondsToTimestamp(reduce(taskIntervals, (total, interval) => total + parseInt(interval.duration), 0))}
                      </div>
                      <div className={`invisible toggle-timer very-light-gray-text px-1 d-flex align-items-center`}>
                        <div>{1 ? 'Stop Timer' : 'Start Timer'}</div>
                        <div className="shortcut-tag">T</div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className={"focus-tasks my-3 " + (taskBeingTimed ? 'no-hover' : '')}>
                  {milestone?.tasks?.length ? milestone?.tasks.map((task, tIndex) => (
                    <SessionTask
                      {...task}
                      id={task._id ? task._id : task.identityCode}
                      key={task._id ? task._id : task.identityCode}
                      tIndex={tIndex}
                      onTaskComplete={handleTaskCompleteChange}
                      onTimerStop={handleAddTimeInterval}
                      onTimerStart={handleTimerStart}
                      disableTimeTracking={!isUndefined(taskBeingTimed) && (task._id ? task._id : task.identityCode) !== taskBeingTimed}
                      handleChange={handleChange}
                      handleKeyPress={handleKeyPress}
                      handleTaskControl={handleTaskControl}
                      taskControl={taskControl}
                      trigger={trigger}
                    />
                  )) : null}
                  <div className={"pointer new-task mt-2 fit-content d-flex" + (taskBeingTimed ? ' disabled' : '')} onClick={() => handleTask('add')} disabled={taskBeingTimed}>
                    + Add a subtask...
                    <div className="shortcut-indicator ms-2 fw-norm slash"><img src={asset_enter} alt="shortcut enter" /></div>
                  </div>
                </div>

                <div className="session-notes">
                  <div className="notes-title d-flex align-items-center">
                    Notes
                    <div className="shortcut-indicator ms-2 fw-norm slash">/</div>
                  </div>
                  <TextareaAutosize
                    id="notes"
                    className="mikyal-borderless-field px-0 notes-content mt-1"
                    cols="80"
                    rows="5"
                    placeholder="Jot down your private notes here, or press /"
                    name="description"
                    value={milestone.description}
                    onChange={handleChange}
                    onKeyDown={(e) => handleKeyPress(e, 'notes')}
                  />
                </div>
              </div>

              <div className="session-actions py-5 d-flex justify-content-center">
                <div className={"shortcut-tag-container mx-2" + (taskBeingTimed ? ' disabled' : '')} onClick={() => taskBeingTimed ? false : navigate(projectId ? ('/tags/' + projectId) : '/home')} disabled={taskBeingTimed}>
                  Go back
                  <div className="shortcut-tag">esc</div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <GlobalHotKeys
          keyMap={keyMap}
          handlers={keyHandlers}
          allowChanges={true}
        >
        </GlobalHotKeys>
      </div>

    </div>
  );
};

export default SessionFocus;
