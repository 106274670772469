import asset_loader from "../../../assets/images/icons/loader.svg";
import asset_logo_lata from "../../../assets/images/icons/logo-lata.svg";

export default function Loader({ style }) {
  return (
    <div className="master-loader" style={style}>
      <img src={asset_loader} alt="Loading" className="mt-3" />
      <br />
      <img src={asset_logo_lata} alt="logo" />
    </div>
  );
}
