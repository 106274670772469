import { useDispatch, useSelector } from "react-redux";
import supabase from "../Supabase";
import { SUPABASE_TABLE_NAME } from "../Supabase/constants";
import {
  addNewComment,
  addNewReplyToComment,
  deleteComment,
} from "../../store/reducers/commentSlice";

export default function CommentListeners() {
  const workSpaceInfo = useSelector((state) => state.user.workSpaceInfo);
  const comments = useSelector((state) => state.comment.comments);
  const tasks = useSelector((state) => state.project.tasks);
  const members = useSelector((state) => state.members.workspace);
  const dispatch = useDispatch();

  if (Object.keys(workSpaceInfo).length) {
    // Listen on comments
    supabase
      .channel("comments-all-channel")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: SUPABASE_TABLE_NAME.COMMENTS,
          filter: `workspace_id=eq.${workSpaceInfo.id}`,
        },
        (payload) => {
          if (payload.eventType === "INSERT") {
            const newComment = payload.new;
            // Check if comment already there.
            if (comments.find((comment) => comment.id === newComment.id)) {
              return;
            }
            const task = tasks.find((t) => t.id === newComment.task_id);
            const commentBy = members.find(
              (member) => member.user_id === newComment.created_by
            );
            const commentObj = {
              ...newComment,
              user: {
                id: commentBy.user_id,
                name: commentBy.username,
                avatar: commentBy.avatar,
              },
              task: {
                id: task.id,
                name: task.title,
                status: task.status,
                project_id: task.project_id,
              },
              createdAt: newComment.created_at,
              replies: [],
              reply_count: 0,
            };
            dispatch(addNewComment(commentObj));
          }

          // if (payload.eventType === "UPDATE") {
          //   const newComment = payload.new;
          //   const prevComment = comments.find((comment) => comment.id === newComment.id);
          //   const commentObj = {
          //     ...prevComment,
          //     ...newComment,
          //   };

          //   dispatch(updateComment(commentObj));
          // }

          if (payload.eventType === "DELETE") {
            dispatch(deleteComment(payload.old));
          }
        }
      )
      .subscribe();

    // Listen on replies
    supabase
      .channel("replies-all-channel")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: SUPABASE_TABLE_NAME.REPLIES,
          filter: `workspace_id=eq.${workSpaceInfo.id}`,
        },
        async (payload) => {
          if (payload.eventType === "INSERT") {
            const newReply = payload.new;
            const comment = comments.find(
              (comment) => comment.id === newReply.comment_id
            );
            // Check if comment not present in array
            if (!comment) {
              return;
            }

            const commentBy = members.find(
              (member) => member.user_id === newReply.created_by
            );
            const replyObj = {
              ...newReply,
              user: {
                id: commentBy.user_id,
                name: commentBy.username,
                avatar: commentBy.avatar,
              },
              createdAt: newReply.created_at,
            };
            dispatch(addNewReplyToComment(replyObj));
            await supabase.from(SUPABASE_TABLE_NAME.COMMENTS).update({seen_user_ids: [newReply.created_by]}).eq('id', comment.id);
          }
        }
      )
      .subscribe();
  }

  return null;
}
