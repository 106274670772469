import { useNavigate } from "react-router-dom";
import Modal from ".";

export default function CreateWorkspaceModal({ isOpen, onClose }) {
  const navigate = useNavigate();

  const handleSubmit =  () => {
    onClose()
    navigate('/workspace-setup')
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} submitText="Create" onSubmit={() => handleSubmit()}>
      <div className="px-2 pb-2 mb-0 fs-6">
        <div>You cannot perform this action 😔</div>
        <div className="mt-2">Seems like you're not part of any workspace.</div>
        <div className="mt-2">Are you want to create your own?</div>
      </div>
    </Modal>
  );
}
