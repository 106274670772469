import { configureStore } from "@reduxjs/toolkit";
import dropDownReducer from "./reducers/dropDownSlice";
import projectReducer from "./reducers/projectSlice";
import sessionReducer from "./reducers/sessionSlice";
import memberReducer from "./reducers/membersSlice";
import commentReducer from "./reducers/commentSlice";

import userReducer from "./reducers/userSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    session: sessionReducer,
    project: projectReducer,
    dropdown: dropDownReducer,
    members: memberReducer,
    comment: commentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
