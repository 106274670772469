import axios from 'axios';
import Globals from '../../application/common/Globals';

class SessionService {
    newSession(params = null, data) {
        const queryString = '?' + new URLSearchParams(params).toString();
        return axios.post(Globals.newSession + queryString, data, Globals.httpOptions);
    }

    // getSessions(param = null) {
    //     return axios.get(Globals.sessions + (param ? param : ''), Globals.httpOptions);
    // }

    getSessions(params = null) {
        const queryString = '?' + new URLSearchParams(params).toString();
        return axios.get(Globals.sessions + queryString, Globals.httpOptions);
    }

    getSessionsList() {
        return axios.get(Globals.sessions, Globals.httpOptions);
    }

    getSession(sessionId) {
        return axios.get(Globals.sessions + `/${sessionId}`, Globals.httpOptions);
    }

    sessionsAlongSession(sessionId) {
        return [
            axios.get(Globals.sessions, Globals.httpOptions),
            axios.get(Globals.sessions + `/${sessionId}`, Globals.httpOptions),
        ];
    }

    projectAlongSession(projectId, sessionId) {
        return [
            axios.get(Globals.projects + `/${projectId}`, Globals.httpOptions),
            axios.get(Globals.sessions + `/${sessionId}`, Globals.httpOptions),
        ];
    }

    saveSession(data) {
        return axios.post(Globals.saveSession + `/${data._id}`, data, Globals.httpOptions);
    }

    completeSession(sessionId) {
        return axios.post(Globals.completeSession + `/${sessionId}`, {}, Globals.httpOptions);
    }

    deleteSession(dataId) {
        return axios.delete(Globals.sessions + '/' + dataId, Globals.httpOptions);
    }
}

export default new SessionService();